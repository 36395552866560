import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest, all, AllEffect } from 'redux-saga/effects';
import { actions } from 'src/reducers/home/home';
import { getNews as getNewsApi } from 'src/services/news';
import { ListNewsDetailsType } from 'src/types/newsList';
import { getCalendar as getCalendarApi } from 'src/services/calendar';
import { CalendarDateType, CalendarRequestType } from 'src/types/home';
import { PayloadAction } from '@reduxjs/toolkit';
import { getSettings as getSettingsApi } from 'src/services/settings';
import { SettingsApiResponseType } from 'src/types/settings';

function* getNews(): Generator<
  CallEffect<AxiosResponse<ListNewsDetailsType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ListNewsDetailsType[]>
> {
  try {
    const { status, data } = yield call(getNewsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getNewsSuccess(data));
    } else {
      yield put(actions.getNewsError());
    }
  } catch (e) {
    yield put(actions.getNewsError());
  }
}

function* getCalendar({
  payload,
}: PayloadAction<CalendarRequestType>): Generator<
  CallEffect<AxiosResponse<CalendarDateType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CalendarDateType[]>
> {
  try {
    const { status, data } = yield call(getCalendarApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getCalendarSuccess(data));
    } else {
      yield put(actions.getCalendarError());
    }
  } catch (e) {
    yield put(actions.getCalendarError());
  }
}

function* getHome({
  payload,
}: PayloadAction<CalendarRequestType>): Generator<
  | AllEffect<
      | CallEffect<AxiosResponse<CalendarDateType[]>>
      | CallEffect<AxiosResponse<ListNewsDetailsType[]>>
      | CallEffect<AxiosResponse<SettingsApiResponseType>>
    >
  | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CalendarDateType[]>
> {
  try {
    const [calendar, news, settings]: any = yield all([
      call(getCalendarApi, payload),
      call(getNewsApi),
      call(getSettingsApi),
    ]);
    if (calendar.status >= 200 && calendar.status < 300) {
      yield put(actions.getCalendarSuccess(calendar.data));
    } else {
      yield put(actions.getCalendarError());
    }

    if (news.status >= 200 && news.status < 300) {
      yield put(actions.getNewsSuccess(news.data));
    } else {
      yield put(actions.getNewsError());
    }

    if (settings.status >= 200 && settings.status < 300) {
      yield put(actions.getSettingsSuccess(settings.data.videos));
    } else {
      yield put(actions.getSettingsError());
    }
  } catch (e) {
    yield put(actions.getCalendarError());
    yield put(actions.getNewsError());
    yield put(actions.getSettingsError());
  }
}

const homeSaga: ForkEffect<never>[] = [
  takeLatest(actions.getCalendarRequest, getCalendar),
  takeLatest(actions.getNewsRequest, getNews),
  takeLatest(actions.getHomeRequest, getHome),
];

export default homeSaga;
