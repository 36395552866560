import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
  DragOverEvent,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates';

import { Item } from './components/Item/Item';
import { Container } from './components/Container/Container';
import { MultipleContainerSortableProps } from './types';
import DroppableContainer from './components/DroppableContainer/DroppableContainer';
import { Wrapper } from './styles';
import SortableItem from './components/SortableItem/SortableItem';
import { SortableItem as SortableItemType } from './components/SortableItem/types';

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const PLACEHOLDER_ID = 'placeholder';

export default function MultipleContainers<T extends SortableItemType>({
  adjustScale = false,
  cancelDrop,
  columns,
  handle = false,
  items: initialItems,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  strategy = verticalListSortingStrategy,
  vertical = false,
  collapsable,
  scrollable,
  onEdit,
  onRemove,
  onEditContainer,
  onRemoveContainer,
  overrideHeader,
  overrideItem,
  renderFooter,
  onDragEndContainer,
  onDragEndItem,
}: MultipleContainerSortableProps<T>) {
  const [items, setItems] = useState<Record<string, T[]>>(() => initialItems || {});
  const [containers, setContainers] = useState(Object.keys(items) as string[]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId.toString()) : false;
  const [clonedItems, setClonedItems] = useState<Record<string, T[]> | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );
  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.find(c => c.id === container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );

  const findContainer = (id: UniqueIdentifier, copyItems: Record<string, T[]> = items) => {
    if (id in copyItems) {
      return id;
    }

    return Object.keys(copyItems).find(key => copyItems[key].find(i => i.id === id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].findIndex(i => i.id === id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setClonedItems({ ...items });
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const overId = over?.id;

    if (overId == null || active.id in items) {
      return;
    }

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);
    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setItems(items => {
        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.findIndex(i => i.id === overId);
        const activeIndex = activeItems.findIndex(i => i.id === active.id);

        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        recentlyMovedToNewContainer.current = true;

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter(item => item.id !== active.id),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        };
      });
    }
  };

  const onDragEnd = ({ over }: DragEndEvent) => {
    if (!activeId) return;

    const overId = over?.id;
    if (activeId in items && overId) {
      setContainers(containers => {
        const activeIndex = containers.indexOf(activeId.toString());
        const overIndex = containers.indexOf(overId?.toString());

        if (onDragEndContainer) onDragEndContainer(activeId.toString(), overIndex);

        return arrayMove(containers, activeIndex, overIndex);
      });
    } else {
      const activeContainer = findContainer(activeId, clonedItems || {});

      if (!activeContainer || overId == null) {
        setActiveId(null);
        return;
      }

      const overContainer = findContainer(overId);

      if (overContainer) {
        const activeIndex = items[activeContainer].findIndex(i => i.id === activeId) || 0;
        const overIndex = items[overContainer].findIndex(i => i.id === overId);

        if (activeIndex !== overIndex || activeContainer !== overContainer) {
          if (onDragEndItem) onDragEndItem(overContainer.toString(), +activeId, overIndex);
          setItems(items => ({
            ...items,
            [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
          }));
        }
      }
    }

    setActiveId(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  useEffect(() => {
    if (initialItems) {
      setItems(() => initialItems || {});
      setContainers(Object.keys(initialItems) as string[]);
    }
  }, [initialItems]);

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    return (
      <Item
        value={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as string,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({ index: 0 })}
        item={Object.values(items)
          .flat(1)
          .find(i => i.id === id)}
        overrideItem={overrideItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    return (
      <Container
        label={`Column ${containerId}`}
        columns={columns}
        shadow
        unstyled={false}
        item={containerId}
        overrideHeader={overrideHeader}>
        {items[containerId].map((item, index) => (
          <Item
            key={item?.id || ''}
            value={item.title}
            handle={handle}
            style={getItemStyles({
              containerId: containerId.toString(),
              overIndex: -1,
              index: getIndex(item?.id || ''),
              value: item.title,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false,
            })}
            item={item}
            overrideItem={overrideItem}
            wrapperStyle={wrapperStyle({ index })}
          />
        ))}
      </Container>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}>
      <Wrapper vertical={vertical}>
        <SortableContext
          items={[...containers, PLACEHOLDER_ID]}
          strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}>
          {containers.map((containerId, index) => (
            <DroppableContainer
              key={containerId}
              id={containerId}
              label={minimal ? undefined : `Column ${containerId}`}
              columns={columns}
              item={containerId}
              items={items[containerId]}
              scrollable={scrollable}
              collapsable={collapsable}
              style={containerStyle}
              unstyled={minimal}
              overrideHeader={containerId => overrideHeader && overrideHeader(containerId, index)}
              onEdit={onEditContainer}
              renderFooter={renderFooter}
              onRemove={onRemoveContainer}>
              <SortableContext items={items[containerId]} id={containerId} strategy={strategy}>
                {items[containerId].map((item, index) => {
                  return (
                    <SortableItem
                      disabled={isSortingContainer}
                      key={item?.id || ''}
                      id={item?.id || ''}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      containerId={containerId}
                      getIndex={getIndex}
                      item={item}
                      overrideItem={overrideItem}
                      onEdit={onEdit}
                      onRemove={onRemove}
                    />
                  );
                })}
              </SortableContext>
            </DroppableContainer>
          ))}
        </SortableContext>
      </Wrapper>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId.toString())
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
}
