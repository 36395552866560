import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'search',
  {
    placeholder: 'Buscar...',
  },
  true,
  false,
);

export default i18n;
