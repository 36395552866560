import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import global from './global/global';
import auth from './auth/auth';
import contentsList from './contentsList/contentsList';
import contentDetail from './contentDetail/contentDetail';
import contentEditor from './contentEditor/contentEditor';
import eventList from './eventList/eventList';
import profile from './profile/profile';
import settings from './settings/settings';
import newsList from './newsList/newsList';
import clientList from './clientList/clientList';
import clientDetail from './clientDetail/clientDetail';
import home from './home/home';
import courseList from './courseList/courseList';
import courseDetail from './courseDetail/courseDetail';
import courseEditor from './courseEditor/courseEditor';
import courseModuleDetail from './courseModuleDetail/courseModuleDetail';
import documentList from './documentList/documentList';
import documentDetail from './documentDetail/documentDetail';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

const reducer = {
  global,
  auth,
  contentsList,
  contentDetail,
  contentEditor,
  eventList,
  profile,
  settings,
  newsList,
  clientList,
  clientDetail,
  home,
  courseList,
  courseDetail,
  courseEditor,
  courseModuleDetail,
  documentList,
  documentDetail,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
