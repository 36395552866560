import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest, all, AllEffect } from 'redux-saga/effects';
import { actions } from 'src/reducers/courseEditor/courseEditor';
import {
  getCourse as getCourseApi,
  createCourse as createCourseApi,
  updateCourse as updateCourseApi,
  createCourseModule as createCourseModuleApi,
  updateCourseModule as updateCourseModuleApi,
  deleteCourseModule as deleteCourseModuleApi,
  updateCourseModuleOrder as updateCourseModuleOrderApi,
  createModuleComponent as createModuleComponentApi,
  updateModuleComponent as updateModuleComponentApi,
  deleteModuleComponent as deleteModuleComponentApi,
  updateModuleComponentOrder as updateModuleComponentOrderApi,
  getCourseCategories,
} from 'src/services/courses';
import {
  CourseDetailType,
  CourseModuleType,
  CreateModuleComponentRequest,
  CreateCourseModuleRequest,
  DeleteModuleComponentRequest,
  DeleteCourseModuleRequest,
  ModuleComponentType,
  UpdateModuleComponentRequest,
  UpdateCourseModuleOrderRequest,
  UpdateCourseModuleRequest,
  UpdateModuleComponentOrderRequest,
  CreateCourseRequest,
  CourseType,
  UpdateCourseRequest,
  CourseCategoryType,
} from 'src/types/courses';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';
import i18next from 'i18next';
import './i18n';

function* getCategories(): Generator<
  CallEffect<AxiosResponse<CourseCategoryType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseCategoryType[]>
> {
  try {
    const { status, data } = yield call(getCourseCategories);
    if (status >= 200 && status < 300) {
      yield put(actions.getCategoriesSuccess(data));
    } else {
      yield put(actions.getCategoriesError());
    }
  } catch (e) {
    yield put(actions.getCategoriesError());
  }
}

function* getCourse({
  payload,
}: PayloadAction<number>): Generator<
  | AllEffect<CallEffect<AxiosResponse<CourseDetailType>> | CallEffect<AxiosResponse<CourseCategoryType[]>>>
  | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseDetailType>
> {
  try {
    const [course, categories]: any = yield all([call(getCourseApi, payload), call(getCourseCategories)]);
    if (course.status >= 200 && course.status < 300) {
      yield put(actions.getCourseSuccess(course.data));
    } else {
      yield put(actions.getCourseError());
    }

    if (categories.status >= 200 && categories.status < 300) {
      yield put(actions.setCategories(categories.data));
    } else {
      yield put(actions.setCategories([]));
    }
  } catch (e) {
    yield put(actions.getCourseError());
    yield put(actions.setCategories([]));
  }
}

function* createCourse({
  payload,
}: PayloadAction<CreateCourseRequest>): Generator<
  CallEffect<AxiosResponse<CourseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<CourseType>
> {
  try {
    const { status, data } = yield call(createCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createCourseSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:createSuccessfully')));
    } else {
      yield put(actions.createCourseError());
      yield errorController(i18next.t('courseEditorSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createCourseError());
    yield errorController(i18next.t('courseEditorSaga:createError'), e);
  }
}

function* updateCourse({
  payload,
}: PayloadAction<UpdateCourseRequest>): Generator<
  CallEffect<AxiosResponse<CourseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<CourseType>
> {
  try {
    const { status, data } = yield call(updateCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateCourseSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateCourseError());
      yield errorController(i18next.t('courseEditorSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateCourseError());
    yield errorController(i18next.t('courseEditorSaga:updateError'), e);
  }
}

function* createCourseModule({
  payload,
}: PayloadAction<CreateCourseModuleRequest>): Generator<
  CallEffect<AxiosResponse<CourseModuleType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<CourseModuleType>
> {
  try {
    const { status, data } = yield call(createCourseModuleApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createCourseModuleSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:createModuleSuccessfully')));
    } else {
      yield put(actions.createCourseModuleError());
      yield errorController(i18next.t('courseEditorSaga:createModuleError'), data);
    }
  } catch (e) {
    yield put(actions.createCourseModuleError());
    yield errorController(i18next.t('courseEditorSaga:createModuleError'), e);
  }
}

function* updateCourseModule({
  payload,
}: PayloadAction<UpdateCourseModuleRequest>): Generator<
  CallEffect<AxiosResponse<CourseModuleType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<CourseModuleType>
> {
  try {
    const { status, data } = yield call(updateCourseModuleApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateCourseModuleSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:updateModuleSuccessfully')));
    } else {
      yield put(actions.updateCourseModuleError());
      yield errorController(i18next.t('courseEditorSaga:updateModuleError'), data);
    }
  } catch (e) {
    yield put(actions.updateCourseModuleError());
    yield errorController(i18next.t('courseEditorSaga:updateModuleError'), e);
  }
}

function* deleteCourseModule({
  payload,
}: PayloadAction<DeleteCourseModuleRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteCourseModuleApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteCourseModuleSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:deleteModuleSuccessfully')));
    } else {
      yield put(actions.deleteCourseModuleError());
      yield errorController(i18next.t('courseEditorSaga:deleteModuleError'), data);
    }
  } catch (e) {
    yield put(actions.deleteCourseModuleError());
    yield errorController(i18next.t('courseEditorSaga:deleteModuleError'), e);
  }
}

function* updateCourseModuleOrder({
  payload,
}: PayloadAction<UpdateCourseModuleOrderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateCourseModuleOrderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateCourseModuleOrderSuccess());
      // yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateCourseModuleOrderError());
      yield errorController(i18next.t('courseEditorSaga:updateHeaderOrderError'), data);
    }
  } catch (e) {
    yield put(actions.updateCourseModuleOrderError());
    yield errorController(i18next.t('courseEditorSaga:updateHeaderOrderError'), e);
  }
}

function* createModuleComponent({
  payload,
}: PayloadAction<CreateModuleComponentRequest>): Generator<
  CallEffect<AxiosResponse<ModuleComponentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ModuleComponentType>
> {
  try {
    const { status, data } = yield call(createModuleComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createModuleComponentSuccess({ ...data, moduleId: payload.moduleId }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:createComponentSuccessfully')));
    } else {
      yield put(actions.createModuleComponentError());
      yield errorController(i18next.t('courseEditorSaga:createComponentError'), data);
    }
  } catch (e) {
    yield put(actions.createModuleComponentError());
    yield errorController(i18next.t('courseEditorSaga:createComponentError'), e);
  }
}

function* updateModuleComponent({
  payload,
}: PayloadAction<UpdateModuleComponentRequest>): Generator<
  CallEffect<AxiosResponse<ModuleComponentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ModuleComponentType>
> {
  try {
    const { status, data } = yield call(updateModuleComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateModuleComponentSuccess({ ...data, moduleId: payload.moduleId }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:updateComponentSuccessfully')));
    } else {
      yield put(actions.updateModuleComponentError());
      yield errorController(i18next.t('courseEditorSaga:updateComponentError'), data);
    }
  } catch (e) {
    yield put(actions.updateModuleComponentError());
    yield errorController(i18next.t('courseEditorSaga:updateComponentError'), e);
  }
}

function* deleteModuleComponent({
  payload,
}: PayloadAction<DeleteModuleComponentRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteModuleComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteModuleComponentSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('courseEditorSaga:deleteComponentSuccessfully')));
    } else {
      yield put(actions.deleteModuleComponentError());
      yield errorController(i18next.t('courseEditorSaga:deleteComponentError'), data);
    }
  } catch (e) {
    yield put(actions.deleteModuleComponentError());
    yield errorController(i18next.t('courseEditorSaga:deleteComponentError'), e);
  }
}

function* updateModuleComponentOrder({
  payload,
}: PayloadAction<UpdateModuleComponentOrderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateModuleComponentOrderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateModuleComponentOrderSuccess());
    } else {
      yield put(actions.updateModuleComponentOrderError());
      yield errorController(i18next.t('courseEditorSaga:updateComponentOrderError'), data);
    }
  } catch (e) {
    yield put(actions.updateModuleComponentOrderError());
    yield errorController(i18next.t('courseEditorSaga:updateComponentOrderError'), e);
  }
}

const courseEditorSaga: ForkEffect<never>[] = [
  takeLatest(actions.getCategoriesRequest, getCategories),
  takeLatest(actions.getCourseRequest, getCourse),
  takeLatest(actions.createCourseRequest, createCourse),
  takeLatest(actions.updateCourseRequest, updateCourse),
  takeLatest(actions.createCourseModuleRequest, createCourseModule),
  takeLatest(actions.updateCourseModuleRequest, updateCourseModule),
  takeLatest(actions.deleteCourseModuleRequest, deleteCourseModule),
  takeLatest(actions.updateCourseModuleOrder, updateCourseModuleOrder),
  takeLatest(actions.createModuleComponentRequest, createModuleComponent),
  takeLatest(actions.updateModuleComponentRequest, updateModuleComponent),
  takeLatest(actions.deleteModuleComponentRequest, deleteModuleComponent),
  takeLatest(actions.updateModuleComponentOrder, updateModuleComponentOrder),
];

export default courseEditorSaga;
