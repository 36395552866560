import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemType } from './types';
import { actions, authStore } from 'src/reducers/auth/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeItem } from 'src/utils/localStorage';
import { globalStore } from 'src/reducers/global/global';
import { ContentType } from 'src/types/contents';
import ROUTES from 'src/routes/routes';
import { formatParams } from 'src/utils/router';
import { useCurrentPath } from 'src/hooks/useCurrentPath';

export const useNavbar = (onClose: () => void) => {
  const { user } = useSelector(authStore);
  const { myContents } = useSelector(globalStore);
  const currentPath = useCurrentPath();
  const location = useLocation();
  const [optionsExpandedById, setOptionsExpandedById] = useState<{ [k: number]: boolean }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isModuleActive = (content: ContentType) => {
    const detailPage = formatParams(ROUTES.CONTENT_DETAIL, { id: content.id.toString() });
    const editorPage = formatParams(ROUTES.CONTENTS, { id: content.id.toString() });
    return detailPage === location.pathname || editorPage === location.pathname;
  };

  const isOptionActive = (path: string) => {
    const homePaths = [ROUTES.ADMIN, ROUTES.INDEX];
    if (homePaths.includes(currentPath || '') && homePaths.includes(path)) return true;
    else if (path === ROUTES.CONTENT_MANAGER && path === currentPath) return true;
    else if (path !== ROUTES.CONTENT_MANAGER && currentPath?.includes(path)) return true;
    return false;
  };

  const redirecTo = (item: MenuItemType) => () => {
    const { path, beforeRedirect } = item;
    const route = beforeRedirect ? beforeRedirect(path) : path;
    navigate(route);
    onClose();
  };

  const redirectToModule = (content: ContentType) => () => {
    if (content.id) navigate(formatParams(ROUTES.CONTENT_DETAIL, { id: content.id.toString() }));
    onClose();
  };

  const toggleCollapse = (contentId: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOptionsExpandedById(prev => ({
      ...prev,
      [contentId]: prev[contentId] ? false : true,
    }));
  };

  const resetCollapse = () => setOptionsExpandedById({});

  const logout = () => {
    dispatch(actions.logout());
    removeItem('token');
  };

  return {
    user,
    myContents,
    optionsExpandedById,
    currentPath,
    resetCollapse,
    redirecTo,
    isModuleActive,
    redirectToModule,
    logout,
    toggleCollapse,
    isOptionActive,
  };
};
