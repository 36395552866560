import { lazy, useMemo, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import TokenController from './layouts/TokenController/TokenController';
import store from './reducers/store';
import { AppContainer } from './styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext, ThemeProvider, MenuProvider } from './contexts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { darkThemeOptions, lightThemeOptions } from './theme/theme';
import './i18n/i18n';
import PublicLayout from './layouts/PublicLayout/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout/PrivateLayout';
import ROUTES from './routes/routes';
import { LazyLoader, Menu } from './components';
import { RoleEnum } from './types/auth';
import RoleController from './layouts/RoleController/RoleController';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import Snackbar from './components/Snackbar/Snackbar';
import dayjs from 'dayjs';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

dayjs.locale('es');

const Login = LazyLoader(lazy(() => import('./pages/Login/Login')));
const Register = LazyLoader(lazy(() => import('./pages/Register/Register')));
const ForgotPassword = LazyLoader(lazy(() => import('./pages/ForgotPassword/ForgotPassword')));
const ChangePassword = LazyLoader(lazy(() => import('./pages/ChangePassword/ChangePassword')));

const Home = LazyLoader(lazy(() => import('./pages/Home/Home')));
const ContentsList = LazyLoader(lazy(() => import('./pages/ContentsList/ContentsList')));
const ContentDetail = LazyLoader(lazy(() => import('./pages/ContentDetail/ContentDetail')));
const ContentEditor = LazyLoader(lazy(() => import('./pages/ContentEditor/ContentEditor')));
const EventList = LazyLoader(lazy(() => import('./pages/EventList/EventList')));
const Profile = LazyLoader(lazy(() => import('./pages/Profile/Profile')));
const Settings = LazyLoader(lazy(() => import('./pages/Settings/Settings')));
const NewsList = LazyLoader(lazy(() => import('./pages/NewsList/NewsList')));
const ClientList = LazyLoader(lazy(() => import('./pages/ClientList/ClientList')));
const ClientDetail = LazyLoader(lazy(() => import('./pages/ClientDetail/ClientDetail')));
const CourseList = LazyLoader(lazy(() => import('./pages/CourseList/CourseList')));
const CourseEditor = LazyLoader(lazy(() => import('./pages/CourseEditor/CourseEditor')));
const CourseDetail = LazyLoader(lazy(() => import('./pages/CourseDetail/CourseDetail')));
const CourseModuleDetail = LazyLoader(lazy(() => import('./pages/CourseModuleDetail/CourseModuleDetail')));
const DocumentList = LazyLoader(lazy(() => import('./pages/DocumentList/DocumentList')));
const DocumentDetail = LazyLoader(lazy(() => import('./pages/DocumentDetail/DocumentDetail')));
const NotFound = LazyLoader(lazy(() => import('./pages/NotFound/NotFound')));

const renderPage = (element: JSX.Element, role: RoleEnum) => {
  return (
    <RoleController requiredRole={role} redirect>
      {element}
    </RoleController>
  );
};

const App = () => {
  const { theme: customTheme } = useContext(ThemeContext);
  const theme = useMemo(() => {
    const theme = createTheme(customTheme === 'dark' ? darkThemeOptions : lightThemeOptions);
    return responsiveFontSizes(theme);
  }, [customTheme]);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <MenuProvider>
            <MaterialThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <AppContainer>
                    <CssBaseline />
                    <TokenController>
                      <Routes>
                        <Route element={<PrivateLayout />}>
                          <Route path={ROUTES.INDEX} element={renderPage(<Home />, RoleEnum.ASSOCIATED)} />
                          <Route path={ROUTES.ADMIN} element={<Home />} />
                          <Route
                            path={ROUTES.CONTENT_DETAIL}
                            element={renderPage(<ContentDetail />, RoleEnum.ASSOCIATED)}
                          />
                          <Route path={ROUTES.CONTENTS} element={renderPage(<ContentEditor />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.CONTENT_MANAGER} element={renderPage(<ContentsList />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.EVENTS} element={renderPage(<EventList />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.NEWS} element={renderPage(<NewsList />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.SETTINGS} element={renderPage(<Settings />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.CLIENTS} element={renderPage(<ClientList />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.CLIENT_DETAIL} element={renderPage(<ClientDetail />, RoleEnum.ADMIN)} />
                          <Route path={ROUTES.PROFILE} element={renderPage(<Profile />, RoleEnum.ASSOCIATED)} />
                          <Route path={ROUTES.COURSES} element={renderPage(<CourseList />, RoleEnum.ASSOCIATED)} />
                          <Route path={ROUTES.COURSE_EDITOR} element={renderPage(<CourseEditor />, RoleEnum.ADMIN)} />
                          <Route
                            path={ROUTES.COURSE_DETAIL}
                            element={renderPage(<CourseDetail />, RoleEnum.ASSOCIATED)}
                          />
                          <Route
                            path={ROUTES.COURSE_MODULE_DETAIL}
                            element={renderPage(<CourseModuleDetail />, RoleEnum.ASSOCIATED)}
                          />
                          <Route path={ROUTES.DOCUMENTS} element={renderPage(<DocumentList />, RoleEnum.ASSOCIATED)} />
                          <Route
                            path={ROUTES.DOCUMENT_DETAIL}
                            element={renderPage(<DocumentDetail />, RoleEnum.ASSOCIATED)}
                          />
                        </Route>
                        <Route element={<PublicLayout />}>
                          <Route path={ROUTES.LOGIN} element={<Login />} />
                          <Route path={ROUTES.REGISTER} element={<Register />} />
                          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                          <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
                          <Route path={ROUTES.INDEX} element={<Login />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </TokenController>
                    <Snackbar />
                  </AppContainer>
                  <Menu />
                </LocalizationProvider>
              </StyledThemeProvider>
            </MaterialThemeProvider>
          </MenuProvider>
        </Router>
      </Provider>
    </Elements>
  );
};

const OneClikApp = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
};

export default OneClikApp;
