import { Search } from 'src/components';
import { ActionsContainer, Container, Title, IconButton } from './styles';
import { TableHeaderProps } from './types';
import { AddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

const TableHeader = ({
  title,
  searchValue,
  showAddButton = true,
  showFilter = true,
  addButtonText,
  onAdd,
  onChange,
}: TableHeaderProps) => {
  const handleChange = (value: string, action: 'change' | 'clear') => {
    if (onChange) onChange(value, action);
  };

  return (
    <Container includeFilter={showFilter}>
      <Title variant="h4">{title}</Title>
      {(showFilter || showAddButton) && (
        <ActionsContainer minWidth={300}>
          {showFilter && <Search value={searchValue || ''} onChange={handleChange} />}
          {showAddButton && !addButtonText && (
            <IconButton size="large" onClick={onAdd} variant="contained">
              <AddOutlined />
            </IconButton>
          )}
          {showAddButton && addButtonText && (
            <Button onClick={onAdd} variant="contained" sx={{ gap: 1 }}>
              <AddOutlined />
              {addButtonText}
            </Button>
          )}
        </ActionsContainer>
      )}
    </Container>
  );
};

export default TableHeader;
