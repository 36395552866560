import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';

const HoverStyles = css`
  border: 4px dashed ${({ theme }) => theme.palette.divider};
`;

export const DropZoneContainer = styled.div<{ hover: boolean; error: boolean }>`
  width: 100%;
  height: 230px;
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ theme, error }) => (error ? theme.palette.error.main : theme.palette.divider)};
  color: ${({ theme }) => theme.palette.grey[500]};
  will-change: border;
  transition: border 0.3s;
  margin-bottom: 4px;

  & h6 {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: 500;
  }

  & h6,
  & p {
    max-width: 300px;
    word-break: break-all;
    text-align: center;
  }

  & span {
    font-weight: 500;
  }

  ${({ hover }) => hover && HoverStyles};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const IconContainer = styled.div`
  margin-bottom: 8px;
  & > span {
    font-size: 40px;
  }
`;

export const Link = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: 8px;

  & > button {
    padding: 0 !important;
    font-size: 1rem;
  }
`;

export const ErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const FileName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary} !important;
  font-weight: 600;
`;

export const PreviewImage = styled.img`
  width: 200px;
  height: 120px;
  border-radius: 16px;
  object-fit: cover;
`;
