import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'courseEditorSaga', {
  createSuccessfully: 'El curso fue creado exitosamente',
  updateSuccessfully: 'El curso fue editado exitosamente',
  createError: 'Ocurrió un error al crear el curso. Revise los parámetros y vuelva a intentarlo',
  updateError: 'Ocurrió un error al editar el curso. Revise los parámetros y vuelva a intentarlo',

  createModuleSuccessfully: 'El módulo fue creado exitosamente',
  updateModuleSuccessfully: 'El módulo fue editado exitosamente',
  deleteModuleSuccessfully: 'El módulo fue eliminado exitosamente',
  createModuleError: 'Ocurrió un error al crear el módulo. Revise los parámetros y vuelva a intentarlo',
  updateModuleError: 'Ocurrió un error al editar el módulo. Revise los parámetros y vuelva a intentarlo',
  deleteModuleError: 'Ocurrió un error al eliminar el módulo. Vuelva a intentarlo en unos minutos',
  updateModuleOrderError: 'Ocurrió un error al ordenar los módulos. Vuelva a intentarlo en unos minutos',

  createComponentSuccessfully: 'El componente fue creado exitosamente',
  updateComponentSuccessfully: 'El componente fue editado exitosamente',
  deleteComponentSuccessfully: 'El componente fue eliminado exitosamente',
  createComponentError: 'Ocurrió un error al crear el componente. Revise los parámetros y vuelva a intentarlo',
  updateComponentError: 'Ocurrió un error al editar el componente. Revise los parámetros y vuelva a intentarlo',
  deleteComponentError: 'Ocurrió un error al eliminar el componente. Vuelva a intentarlo en unos minutos',
  updateComponentOrderError: 'Ocurrió un error al ordenar los componentes. Vuelva a intentarlo en unos minutos',
});

export default i18n;
