import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CourseModuleDetailStoreType, CourseModuleType, GetCourseModuleRequest } from 'src/types/courses';

const initialState: CourseModuleDetailStoreType = {
  module: null,
  modulesProgress: [],
};

const sliceName = 'courseModuleDetail';

const courseModuleDetailSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCourseModuleRequest: (state: CourseModuleDetailStoreType, action: PayloadAction<GetCourseModuleRequest>) => {},
    getCourseModuleSuccess: (state: CourseModuleDetailStoreType, action: PayloadAction<CourseModuleType>) => {
      state.module = action.payload;
    },
    getCourseModuleError: (state: CourseModuleDetailStoreType) => {
      state.module = null;
    },
    getCourseModulesProgressRequest: (state: CourseModuleDetailStoreType, action: PayloadAction<number>) => {},
    getCourseModulesProgressSuccess: (
      state: CourseModuleDetailStoreType,
      action: PayloadAction<CourseModuleType[]>,
    ) => {
      state.modulesProgress = action.payload;
    },
    getCourseModulesProgressError: (state: CourseModuleDetailStoreType) => {
      state.modulesProgress = [];
    },
    markModuleAsCompletedRequest: (
      state: CourseModuleDetailStoreType,
      action: PayloadAction<GetCourseModuleRequest>,
    ) => {},
    markModuleAsCompletedSuccess: (state: CourseModuleDetailStoreType, action: PayloadAction<number>) => {
      if (state.module) {
        state.module.completed = true;
        const moduleIndex = state.modulesProgress.findIndex(m => m.id === action.payload);
        if (moduleIndex >= 0) state.modulesProgress[moduleIndex].completed = true;
      }
    },
    markModuleAsCompletedError: (state: CourseModuleDetailStoreType) => {},
  },
});

export const actions = courseModuleDetailSlice.actions;

export const courseModuleDetailStore = (state: RootState) => state.courseModuleDetail;

export default courseModuleDetailSlice.reducer;

export const selectIsLastModule = createSelector(
  (state: RootState) => state.courseModuleDetail,
  ({ module, modulesProgress }) => {
    if (!module || !modulesProgress?.length) return { index: -1, isLast: false };
    const moduleIdx = modulesProgress.findIndex(m => m.id === module.id);
    return {
      index: moduleIdx,
      isLast: moduleIdx === modulesProgress.length - 1,
    };
  },
);
