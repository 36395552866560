import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  DocumentByCategoryType,
  DocumentCategoryType,
  DocumentDetailType,
  DocumentListStoreType,
  DocumentType,
  UploadDocumentRequestType,
} from 'src/types/documents';

const initialState: DocumentListStoreType = {
  documents: [],
  categories: [],
  isUploadDocumentModalVisible: false,
};

const sliceName = 'documentList';

const documentListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getDocumentsRequest: (state: DocumentListStoreType) => {},
    getDocumentsSuccess: (state: DocumentListStoreType, action: PayloadAction<DocumentByCategoryType[]>) => {
      state.documents = action.payload;
    },
    getDocumentsError: (state: DocumentListStoreType) => {
      state.documents = [];
    },
    getDocumentCategoriesRequest: (state: DocumentListStoreType) => {},
    getDocumentCategoriesSuccess: (state: DocumentListStoreType, action: PayloadAction<DocumentCategoryType[]>) => {
      state.categories = action.payload;
    },
    getDocumentCategoriesError: (state: DocumentListStoreType) => {
      state.categories = [];
    },
    uploadDocumentRequest: (state: DocumentListStoreType, action: PayloadAction<UploadDocumentRequestType>) => {},
    uploadDocumentSuccess: (state: DocumentListStoreType, action: PayloadAction<DocumentDetailType>) => {
      if (state.documents) {
        const categoryIdx = state.documents.findIndex(doc => doc.category === action.payload.category);
        if (categoryIdx >= 0) {
          state.documents[categoryIdx]?.documents?.push(action.payload);
        } else {
          state.documents = [...state.documents, { category: action.payload.category, documents: [action.payload] }];
        }
      }
      state.isUploadDocumentModalVisible = false;
    },
    uploadDocumentError: (state: DocumentListStoreType) => {},
    toggleUploadModalVisibility: (state: DocumentListStoreType, action: PayloadAction<boolean>) => {
      state.isUploadDocumentModalVisible = action.payload;
    },
  },
});

export const actions = documentListSlice.actions;

export const documentListStore = (state: RootState) => state.documentList;

export default documentListSlice.reducer;

export const selectDocuments = createSelector(
  (state: RootState) => state.documentList,
  ({ documents }) => {
    return {
      asList: documents.slice().sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase())) || [],
      byId: documents.reduce<{ [k: number]: DocumentType }>((acc, curr) => {
        const documentsById = curr.documents.reduce(
          (a, c) => ({ ...a, [c.id]: { ...c, category: curr.category } }),
          {},
        );
        return { ...acc, ...documentsById };
      }, {}),
    };
  },
);
