import React, { forwardRef } from 'react';
import { CollapseIconButton, Container, Item, Text, Count, DragIconButton } from './styles';
import { TreeItemProps } from './types';
import { IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragHandleOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRightOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

const TreeItem = <T extends object>() =>
  forwardRef<HTMLDivElement, TreeItemProps<T>>(
    (
      {
        childCount,
        clone,
        depth,
        disableSelection,
        disableInteraction,
        ghost,
        handleProps,
        indentationWidth,
        indicator,
        collapsed,
        style,
        item,
        hasChildren,
        isChildren,
        onCollapse,
        onRemove,
        onEdit,
        onOpenDetail,
        displayValue,
        overrideDisplayElement,
        wrapperRef,
        ...props
      },
      ref,
    ) => {
      const value = displayValue ? displayValue(item) : item.id;
      return (
        <Container
          clone={clone}
          ghost={ghost}
          indicator={indicator}
          disableSelection={disableSelection}
          disableInteraction={disableInteraction}
          identation={indentationWidth * depth}
          ref={wrapperRef}
          {...props}>
          <Item ref={ref} style={style} hasChildren={hasChildren} isChildren={isChildren} collapsed={collapsed}>
            <DragIconButton {...handleProps}>
              <DragIndicatorIcon />
            </DragIconButton>
            {onCollapse && (
              <CollapseIconButton onClick={onCollapse} isOpen={!collapsed}>
                <ChevronRightIcon />
              </CollapseIconButton>
            )}
            {overrideDisplayElement ? (
              overrideDisplayElement(item)
            ) : (
              <Text disableSelection={disableSelection || clone}>{value}</Text>
            )}
            {!clone && onEdit && (
              <IconButton size="small" onClick={() => onEdit(item)}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {!clone && onRemove && (
              <IconButton size="small" onClick={() => onRemove(item)}>
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {!clone && onOpenDetail && (
              <IconButton size="small" onClick={() => onOpenDetail(item)}>
                <ChevronRightOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {clone && childCount && childCount > 1 ? (
              <Count disableSelection={disableSelection || clone}>{childCount}</Count>
            ) : null}
          </Item>
        </Container>
      );
    },
  );

export default TreeItem;
