import { useState, useCallback, ChangeEvent, useRef } from 'react';
import _debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { globalActions, globalStore } from 'src/reducers/global/global';
import { actions as homeActions } from 'src/reducers/home/home';
import { useDispatch } from 'react-redux';
import { Resource } from 'src/types/global';
import { useNavigate } from 'react-router-dom';
import { formatParams } from 'src/utils/router';
import ROUTES from 'src/routes/routes';
import { getNewsById } from 'src/services/news';

export const useSearch = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const { finder } = useSelector(globalStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDebounceFn = (inputValue: string) => {
    setLocalLoading(false);
    dispatch(globalActions.findResource(inputValue));
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setLocalLoading(true);
    setValue(value);
    debounceFn(value);
  };

  const handleResource = async (resource: Resource) => {
    switch (resource.resourceType) {
      case 'content':
        navigate(formatParams(ROUTES.CONTENT_DETAIL, { id: resource.id.toString() }));
        break;
      case 'course':
        navigate(formatParams(ROUTES.COURSE_DETAIL, { id: resource.id.toString() }));
        break;
      case 'document':
        navigate(formatParams(ROUTES.DOCUMENT_DETAIL, { id: resource.id.toString(), mode: 'editor' }));
        break;
      case 'news': {
        const {
          data: { link },
        } = await getNewsById(resource.id);
        if (link) window.open(link, '_blank');
        break;
      }
      case 'events':
        dispatch(homeActions.setOpenEventModal(true));
        navigate(ROUTES.INDEX, { state: { eventId: resource.id } });
        break;
      default:
        break;
    }
    setOpen(false);
    setValue('');
    inputRef.current?.blur();
    dispatch(globalActions.setResources([]));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  return {
    inputRef,
    finder,
    open,
    value,
    localLoading,
    setOpen,
    handleChange,
    handleResource,
  };
};
