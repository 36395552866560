import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const DragIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const Item = styled.div<{
  hasChildren?: boolean;
  isChildren?: boolean;
  collapsed?: boolean;
}>`
  --vertical-padding: 8px;

  // border-radius: ${({ hasChildren, isChildren }) => (isChildren ? '0' : hasChildren ? '4px 4px 0 0' : '4px')};
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--vertical-padding) 10px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.typography.body1.color};
  box-sizing: border-box;
`;

export const CollapseIconButton = styled(IconButton)<{ isOpen?: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const Count = styled.span<{ disableSelection?: boolean }>`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  ${({ disableSelection }) =>
    disableSelection &&
    css`
      user-select: none;
      -webkit-user-select: none;
    `}
`;

export const Text = styled.span<{ disableSelection?: boolean }>`
  flex-grow: 1;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ disableSelection }) =>
    disableSelection &&
    css`
      user-select: none;
      -webkit-user-select: none;
    `}
`;

const CloneStyles = css`
  display: inline-block;
  pointer-events: none;
  padding: 0;
  padding-left: 10px;
  padding-top: 5px;

  ${Item} {
    --vertical-padding: 5px;

    padding-right: 24px;
    border-radius: 4px;
    box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);

    padding-left: 10px;
    transform: none;
    width: 100%;
  }
`;

const IndicatorStyles = css`
  opacity: 1;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;

  ${Item} {
    position: relative;
    padding: 0;
    height: 4px;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.palette.secondary.dark};
    background-color: ${({ theme }) => theme.palette.secondary.main};

    > * {
      /* Items are hidden using height and opacity to retain focus */
      opacity: 0;
      height: 0;
    }
  }
`;

const GhostStyles = css`
  ${Item} > * {
    box-shadow: none;
    background-color: transparent;
  }
`;

export const Container = styled.li<{
  identation: number;
  clone?: boolean;
  ghost?: boolean;
  indicator?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
}>`
  list-style: none;
  box-sizing: border-box;
  margin-bottom: -1px;
  margin-top: 8px;
  padding-left: ${({ identation }) => identation}px;

  ${({ clone }) => clone && CloneStyles};
  ${({ ghost }) => ghost && GhostStyles};
  ${({ ghost, indicator }) => ghost && (indicator ? IndicatorStyles : { opacity: 0.5 })};
  ${({ disableInteraction }) => disableInteraction && { pointerEvents: 'none' }}
  ${({ disableInteraction, clone }) => disableInteraction && clone && { pointerEvents: 'none' }};

  /* & > div {
    padding-left: ${({ identation }) => identation + 10}px;
    transform: ${({ identation }) => `translateX(${-1 * identation}px)`};
    width: ${({ identation }) => `calc(100% + ${identation}px)`};
  } */
`;
