import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientListStoreType, ClientApiResponseType } from 'src/types/clients';
import { RootState } from '../store';
import { filterBySearch } from 'src/utils/clients';

const initialState: ClientListStoreType = {
  clientList: [],
  searchValue: '',
};

export const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {
    getClientsRequest: (state: ClientListStoreType) => {},
    getClientsSuccess: (state: ClientListStoreType, action: PayloadAction<ClientApiResponseType[]>) => {
      state.clientList = action.payload;
    },
    getClientsError: (state: ClientListStoreType) => {
      state.clientList = [];
    },
    onSearch: (state: ClientListStoreType, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const selectFilteredClients = createSelector(
  (state: RootState) => state.clientList,
  clientListState => filterBySearch(clientListState.clientList, clientListState.searchValue),
);

export const actions = clientListSlice.actions;

export const clientListStore = (state: RootState) => state.clientList;

export default clientListSlice.reducer;
