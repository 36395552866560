import {
  ClientApiResponseType,
  ClientContentApiResponseType,
  ClientContentUpdateAccess,
  ClientCourseApiResponseType,
  ClientCourseUpdateAccess,
  ClientDocumentApiResponseType,
  ClientDocumentUpdateAccess,
} from 'src/types/clients';
import { get, patch } from './api';

export const getClients = () => {
  return get<ClientApiResponseType[]>('/clients');
};

export const getClient = (id: ClientApiResponseType['id']) => {
  return get<ClientApiResponseType>(`/clients/${id}`);
};

export const getClientDocuments = (id: ClientApiResponseType['id']) => {
  return get<ClientDocumentApiResponseType[]>(`/clients/${id}/documents`);
};

export const getClientContents = (id: ClientApiResponseType['id']) => {
  return get<ClientContentApiResponseType[]>(`/clients/${id}/contents`);
};

export const getClientCourses = (id: ClientApiResponseType['id']) => {
  return get<ClientCourseApiResponseType[]>(`/clients/${id}/courses`);
};

export const updateClientDocument = ({ clientId, documentId, hasAccess }: ClientDocumentUpdateAccess) => {
  return patch<ClientDocumentApiResponseType>(`/clients/${clientId}/documents/${documentId}`, { hasAccess });
};

export const updateClientContent = ({ clientId, contentId, hasAccess }: ClientContentUpdateAccess) => {
  return patch<ClientContentApiResponseType>(`/clients/${clientId}/contents/${contentId}`, { hasAccess });
};

export const updateClientCourse = ({ clientId, courseId, hasAccess }: ClientCourseUpdateAccess) => {
  return patch<ClientCourseApiResponseType>(`/clients/${clientId}/courses/${courseId}`, { hasAccess });
};
