import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/contentEditor/contentEditor';
import {
  getContent as getContentApi,
  createContentHeader as createContentHeaderApi,
  updateContentHeader as updateContentHeaderApi,
  deleteContentHeader as deleteContentHeaderApi,
  updateContentHeaderOrder as updateContentHeaderOrderApi,
  createHeaderComponent as createHeaderComponentApi,
  updateHeaderComponent as updateHeaderComponentApi,
  deleteHeaderComponent as deleteHeaderComponentApi,
  updateHeaderComponentOrder as updateHeaderComponentOrderApi,
} from 'src/services/contents';
import {
  ContentDetailType,
  ContentHeaderType,
  CreateComponentRequest,
  CreateContentHeaderRequest,
  DeleteComponentRequest,
  DeleteContentHeaderRequest,
  HeaderComponentType,
  UpdateComponentRequest,
  UpdateContentHeaderOrderRequest,
  UpdateContentHeaderRequest,
  UpdateHeaderComponentOrderRequest,
} from 'src/types/contents';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';
import i18next from 'i18next';
import './i18n';

function* getContent({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<ContentDetailType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ContentDetailType>
> {
  try {
    const { status, data } = yield call(getContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getContentSuccess(data));
    } else {
      yield put(actions.getContentError());
    }
  } catch (e) {
    yield put(actions.getContentError());
  }
}

function* createContentHeader({
  payload,
}: PayloadAction<CreateContentHeaderRequest>): Generator<
  CallEffect<AxiosResponse<ContentHeaderType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ContentHeaderType>
> {
  try {
    const { status, data } = yield call(createContentHeaderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createContentHeaderSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:createHeaderSuccessfully')));
    } else {
      yield put(actions.createContentHeaderError());
      yield errorController(i18next.t('contentEditorSaga:createHeaderError'), data);
    }
  } catch (e) {
    yield put(actions.createContentHeaderError());
    yield errorController(i18next.t('contentEditorSaga:createHeaderError'), e);
  }
}

function* updateContentHeader({
  payload,
}: PayloadAction<UpdateContentHeaderRequest>): Generator<
  CallEffect<AxiosResponse<ContentHeaderType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ContentHeaderType>
> {
  try {
    const { status, data } = yield call(updateContentHeaderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateContentHeaderSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:updateHeaderSuccessfully')));
    } else {
      yield put(actions.updateContentHeaderError());
      yield errorController(i18next.t('contentEditorSaga:updateHeaderError'), data);
    }
  } catch (e) {
    yield put(actions.updateContentHeaderError());
    yield errorController(i18next.t('contentEditorSaga:updateHeaderError'), e);
  }
}

function* deleteContentHeader({
  payload,
}: PayloadAction<DeleteContentHeaderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteContentHeaderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteContentHeaderSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:deleteHeaderSuccessfully')));
    } else {
      yield put(actions.deleteContentHeaderError());
      yield errorController(i18next.t('contentEditorSaga:deleteHeaderError'), data);
    }
  } catch (e) {
    yield put(actions.deleteContentHeaderError());
    yield errorController(i18next.t('contentEditorSaga:deleteHeaderError'), e);
  }
}

function* updateContentHeaderOrder({
  payload,
}: PayloadAction<UpdateContentHeaderOrderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateContentHeaderOrderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateContentHeaderOrderSuccess());
      // yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateContentHeaderOrderError());
      yield errorController(i18next.t('contentEditorSaga:updateHeaderOrderError'), data);
    }
  } catch (e) {
    yield put(actions.updateContentHeaderOrderError());
    yield errorController(i18next.t('contentEditorSaga:updateHeaderOrderError'), e);
  }
}

function* createHeaderComponent({
  payload,
}: PayloadAction<CreateComponentRequest>): Generator<
  CallEffect<AxiosResponse<HeaderComponentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<HeaderComponentType>
> {
  try {
    const { status, data } = yield call(createHeaderComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createComponentSuccess({ ...data, headerId: payload.headerId }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:createComponentSuccessfully')));
    } else {
      yield put(actions.createComponentError());
      yield errorController(i18next.t('contentEditorSaga:createComponentError'), data);
    }
  } catch (e) {
    yield put(actions.createComponentError());
    yield errorController(i18next.t('contentEditorSaga:createComponentError'), e);
  }
}

function* updateHeaderComponent({
  payload,
}: PayloadAction<UpdateComponentRequest>): Generator<
  CallEffect<AxiosResponse<HeaderComponentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<HeaderComponentType>
> {
  try {
    const { status, data } = yield call(updateHeaderComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateComponentSuccess({ ...data, headerId: payload.headerId }));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:updateComponentSuccessfully')));
    } else {
      yield put(actions.updateComponentError());
      yield errorController(i18next.t('contentEditorSaga:updateComponentError'), data);
    }
  } catch (e) {
    yield put(actions.updateComponentError());
    yield errorController(i18next.t('contentEditorSaga:updateComponentError'), e);
  }
}

function* deleteHeaderComponent({
  payload,
}: PayloadAction<DeleteComponentRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteHeaderComponentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteComponentSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentEditorSaga:deleteComponentSuccessfully')));
    } else {
      yield put(actions.deleteComponentError());
      yield errorController(i18next.t('contentEditorSaga:deleteComponentError'), data);
    }
  } catch (e) {
    yield put(actions.deleteComponentError());
    yield errorController(i18next.t('contentEditorSaga:deleteComponentError'), e);
  }
}

function* updateHeaderComponentOrder({
  payload,
}: PayloadAction<UpdateHeaderComponentOrderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateHeaderComponentOrderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateHeaderComponentOrderSuccess());
    } else {
      yield put(actions.updateHeaderComponentOrderError());
      yield errorController(i18next.t('contentEditorSaga:updateComponentOrderError'), data);
    }
  } catch (e) {
    yield put(actions.updateHeaderComponentOrderError());
    yield errorController(i18next.t('contentEditorSaga:updateComponentOrderError'), e);
  }
}

const contentEditorSaga: ForkEffect<never>[] = [
  takeLatest(actions.getContentRequest, getContent),
  takeLatest(actions.createContentHeaderRequest, createContentHeader),
  takeLatest(actions.updateContentHeaderRequest, updateContentHeader),
  takeLatest(actions.deleteContentHeaderRequest, deleteContentHeader),
  takeLatest(actions.updateContentHeaderOrder, updateContentHeaderOrder),
  takeLatest(actions.createComponentRequest, createHeaderComponent),
  takeLatest(actions.updateComponentRequest, updateHeaderComponent),
  takeLatest(actions.deleteComponentRequest, deleteHeaderComponent),
  takeLatest(actions.updateHeaderComponentOrder, updateHeaderComponentOrder),
];

export default contentEditorSaga;
