import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventDetailsType, EventListStoreType, ListEventDetailsType } from 'src/types/eventList';
import { RootState } from '../store';
import dayjs from 'dayjs';
import { filterBySearch } from 'src/utils/events';

const initialState: EventListStoreType = {
  eventDetails: {
    title: '',
    description: '',
    link: '',
    image: null,
    datetime: dayjs(),
  },
  eventList: [],
  isOpen: false,
  searchValue: '',
  filteredEventList: [],
};

export const eventListSlice = createSlice({
  name: 'eventList',
  initialState,
  reducers: {
    getEventsRequest: (state: EventListStoreType) => {},
    getEventsSuccess: (state: EventListStoreType, action: PayloadAction<ListEventDetailsType[]>) => {
      state.eventList = action.payload;
    },
    getEventsError: (state: EventListStoreType) => {
      state.eventList = [];
    },
    createEventRequest: (state: EventListStoreType, action: PayloadAction<EventDetailsType>) => {},
    createEventSuccess: (state: EventListStoreType, action: PayloadAction<ListEventDetailsType>) => {
      state.eventList = [...state.eventList, action.payload];
    },
    createEventError: (state: EventListStoreType) => {},
    updateEventRequest: (state: EventListStoreType, action: PayloadAction<EventDetailsType>) => {},
    updateEventSuccess: (state: EventListStoreType, action: PayloadAction<ListEventDetailsType>) => {
      const eventIdx = state.eventList.findIndex(({ id }) => id === action.payload.id);
      if (eventIdx >= 0) state.eventList[eventIdx] = action.payload;
    },
    updateEventError: (state: EventListStoreType) => {},
    deleteEventRequest: (state: EventListStoreType, action: PayloadAction<EventDetailsType['id']>) => {},
    deleteEventSuccess: (state: EventListStoreType, action: PayloadAction<EventDetailsType['id']>) => {
      state.eventList = state.eventList.filter(({ id }) => id !== action.payload);
    },
    deleteEventError: (state: EventListStoreType) => {},
    setEventDetails: (state: EventListStoreType, action: PayloadAction<EventDetailsType>) => {
      state.eventDetails = action.payload;
    },
    setOpen: (state: EventListStoreType, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    onSearch: (state: EventListStoreType, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const selectFilteredEvents = createSelector(
  (state: RootState) => state.eventList,
  eventListState => filterBySearch(eventListState.eventList, eventListState.searchValue),
);

export const actions = eventListSlice.actions;

export const eventListStore = (state: RootState) => state.eventList;

export default eventListSlice.reducer;
