import { ListItem, IconButton, useMediaQuery, Collapse } from '@mui/material';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import MENU from './menu';
import { NavbarProps } from './types';
import './i18n';
import { ExpandLessOutlined, ExpandMoreOutlined, MenuOpen } from '@mui/icons-material';
import {
  Drawer,
  DrawerHeader,
  ListDivider,
  ListItemOption,
  MobileDrawer,
  Text,
  CollapseIcon,
  ItemIcon,
  NavList,
} from './styles';
import { useNavbar } from './useNavbar';
import { useTheme } from 'styled-components';
import { ContentType } from 'src/types/contents';
import React from 'react';

const Navbar = ({ open, onClose }: NavbarProps) => {
  const { t } = useTranslation('menu');
  const {
    user,
    myContents,
    optionsExpandedById,
    isModuleActive,
    isOptionActive,
    toggleCollapse,
    redirecTo,
    redirectToModule,
    logout,
    resetCollapse,
  } = useNavbar(onClose);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    resetCollapse();
    onClose();
  };

  const optionStyles = isSmDown
    ? {}
    : {
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      };

  const iconStyles = isSmDown
    ? {}
    : {
        minWidth: 0,
        mr: open ? 2 : 'auto',
        justifyContent: 'center',
      };

  const renderItems = () => {
    return MENU.filter(item => user && item.roles.includes(user?.role)).map(item => (
      <ListItem key={item.key} disablePadding>
        <ListItemOption onClick={redirecTo(item)} sx={optionStyles} active={isOptionActive(item.path)}>
          <ItemIcon sx={iconStyles}>
            <Icon baseClassName="material-icons-outlined">{item.icon}</Icon>
          </ItemIcon>
          <Text primary={t(`${item.key}`)} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemOption>
      </ListItem>
    ));
  };

  const renderModules = (contents: ContentType[], depth?: number) => {
    return contents.map(content => (
      <React.Fragment key={content.id}>
        <ListItemOption
          depth={depth}
          onClick={redirectToModule(content)}
          active={isModuleActive(content)}
          sx={optionStyles}>
          <ItemIcon sx={iconStyles}>
            <Icon baseClassName="material-icons-outlined">arrow_drop_down_circle</Icon>
          </ItemIcon>
          <Text primary={content.title} sx={{ opacity: open ? 1 : 0 }} />
          {content.children && content.children.length > 0 && open && (
            <CollapseIcon onClick={toggleCollapse(content.id)}>
              {optionsExpandedById[content.id] ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </CollapseIcon>
          )}
        </ListItemOption>
        {content.children && content.children.length > 0 && open && (
          <Collapse in={optionsExpandedById[content.id] && open} timeout="auto" unmountOnExit>
            <NavList component="div" disablePadding>
              {renderModules(content.children, (depth || 0) + 1)}
            </NavList>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  const renderDrawerContent = () => (
    <>
      <DrawerHeader>
        <IconButton onClick={handleClose} size="large">
          <MenuOpen />
        </IconButton>
      </DrawerHeader>
      <NavList component="nav">
        {renderItems()}
        {renderModules(myContents)}
      </NavList>
      <ListDivider />
      <ListItem key="logout" disablePadding>
        <ListItemOption onClick={logout} sx={optionStyles}>
          <ItemIcon sx={iconStyles}>
            <Icon baseClassName="material-icons-outlined">logout</Icon>
          </ItemIcon>
          <Text primary={t('logout')} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemOption>
      </ListItem>
    </>
  );

  if (isSmDown) {
    return (
      <MobileDrawer variant="temporary" open={open}>
        {renderDrawerContent()}
      </MobileDrawer>
    );
  }
  return (
    <Drawer variant="permanent" open={open}>
      {renderDrawerContent()}
    </Drawer>
  );
};

export default Navbar;
