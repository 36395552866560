import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ProfileApiResponseType, ProfileImageType, ProfileStore, ProfileType } from 'src/types/profile';

const initialState: ProfileStore = {
  profile: {
    id: 0,
    image: '',
    firstName: '',
    lastName: '',
    dni: '',
    phone: '',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    email: '',
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileRequest: (state: ProfileStore) => {},
    getProfileSuccess: (state: ProfileStore, action: PayloadAction<ProfileApiResponseType>) => {
      state.profile = action.payload;
    },
    getProfileError: (state: ProfileStore) => {
      state.profile = initialState.profile;
    },
    updateProfileRequest: (state: ProfileStore, action: PayloadAction<ProfileType>) => {},
    updateProfileSuccess: (state: ProfileStore, action: PayloadAction<ProfileApiResponseType>) => {
      state.profile = action.payload;
    },
    updateProfileError: (state: ProfileStore) => {},
    updateProfileImageRequest: (state: ProfileStore, action: PayloadAction<ProfileImageType>) => {},
    updateProfileImageSuccess: (state: ProfileStore, action: PayloadAction<ProfileApiResponseType>) => {
      state.profile.image = action.payload.image;
    },
    updateProfileImageError: (state: ProfileStore) => {},
  },
});

export const actions = profileSlice.actions;

export const profileStore = (state: RootState) => state.profile;

export default profileSlice.reducer;
