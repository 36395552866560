import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'menu',
  {
    home: 'Panel',
    'content-manager': 'Gestión información',
    events: 'Eventos',
    news: 'Noticias',
    school: 'Cursos',
    documents: 'Documentos',
    clients: 'Clientes',
    logout: 'Salir',
  },
  true,
  false,
);

export default i18n;
