import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow: hidden;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  height: 120px;
  width: 200px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;

  & p {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const DateContainer = styled.div`
  margin: 8px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 140px;
  align-self: flex-end;

  & > button {
    width: 140px;
  }
`;

export const ProgressIndicator = styled.div<{ percentage: number }>`
  width: 100%;
  max-width: 280px;
  height: 6px;
  border-radius: 5px;
  margin-top: 6px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 5px;
    width: ${({ percentage }) => percentage}%;
    background-color: ${({ theme, percentage }) =>
      percentage < 100 ? theme.palette.primary.main : theme.palette.success.main};
  }
`;

export const Hint = styled(({ children, ...rest }: PropsWithChildren) => (
  <Typography variant="body2" {...rest}>
    {children}
  </Typography>
))`
  font-size: 10px;
  &&& {
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;
