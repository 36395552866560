/* eslint-disable @typescript-eslint/no-explicit-any */
import { put } from 'redux-saga/effects';
import { globalActions } from 'src/reducers/global/global';

export function* errorController(defaultMessage: string, error?: any) {
  try {
    const message = (error?.message as string) || defaultMessage;
    yield put(globalActions.showErrorSnackbar(message));
  } catch {
    yield put(globalActions.showErrorSnackbar(defaultMessage));
  }
}
