import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  CourseCategoryType,
  CourseLevelsType,
  CourseListFilter,
  CourseListStoreType,
  CourseType,
  MyCourseType,
} from 'src/types/courses';
import { filterCoursesByLevelAndCategory } from 'src/utils/courses';

const initialState: CourseListStoreType = {
  courses: [],
  myCourses: [],
  filter: CourseListFilter.All,
  selectedCategory: null,
  selectedLevels: [],
  categories: [],
};

const sliceName = 'courseList';

const courseList = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCoursesRequest: (state: CourseListStoreType) => {
      state.filter = CourseListFilter.All;
    },
    getCoursesSuccess: (state: CourseListStoreType, action: PayloadAction<CourseType[]>) => {
      state.courses = action.payload;
    },
    getCoursesError: (state: CourseListStoreType) => {
      state.courses = [];
    },
    getMyCoursesRequest: (state: CourseListStoreType) => {},
    getMyCoursesSuccess: (state: CourseListStoreType, action: PayloadAction<MyCourseType[]>) => {
      state.myCourses = action.payload;
    },
    getMyCoursesError: (state: CourseListStoreType) => {
      state.myCourses = [];
    },
    enrollInCourseRequest: (state: CourseListStoreType, action: PayloadAction<number>) => {},
    enrollInCourseSuccess: (state: CourseListStoreType, action: PayloadAction<number>) => {
      const course = state.courses.find(c => c.id === action.payload);
      if (course)
        state.myCourses = [
          ...state.myCourses,
          { ...course, completed: false, percentageCompleted: 0, totalModules: 0, totalModulesCompleted: 0 },
        ];
    },
    enrollInCourseError: (state: CourseListStoreType) => {},
    setLevel: (state: CourseListStoreType, action: PayloadAction<CourseLevelsType>) => {
      const lvl = action.payload;
      if (state.selectedLevels.includes(lvl)) state.selectedLevels = state.selectedLevels.filter(l => l !== lvl);
      else state.selectedLevels = [...state.selectedLevels, lvl];
    },
    setLevels: (state: CourseListStoreType, action: PayloadAction<CourseLevelsType[]>) => {
      state.selectedLevels = action.payload;
    },
    setCategory: (state: CourseListStoreType, action: PayloadAction<CourseCategoryType | null>) => {
      if (state.selectedCategory === action.payload) state.selectedCategory = null;
      else state.selectedCategory = action.payload;
    },
    setCategories: (state: CourseListStoreType, action: PayloadAction<CourseCategoryType[]>) => {
      state.categories = action.payload;
    },
    setFilter: (state: CourseListStoreType, action: PayloadAction<CourseListFilter>) => {
      state.filter = action.payload;
    },
    setMyCourses: (state: CourseListStoreType, action: PayloadAction<MyCourseType[]>) => {
      state.myCourses = action.payload;
    },
  },
});

export const actions = courseList.actions;

export const courseListStore = (state: RootState) => state.courseList;

export default courseList.reducer;

export const selectCourses = createSelector(
  (state: RootState) => state.courseList,
  ({ courses, selectedCategory, selectedLevels }) => {
    return filterCoursesByLevelAndCategory(courses, selectedLevels, selectedCategory);
  },
);

export const selectMyCourses = createSelector(
  (state: RootState) => state.courseList,
  ({ myCourses, selectedCategory, selectedLevels }) =>
    filterCoursesByLevelAndCategory(myCourses, selectedLevels, selectedCategory) as MyCourseType[],
);
