import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import './i18n';
import { SearchProps } from './types';

const Search = ({ value, disableUnderline, onChange }: SearchProps) => {
  const { t } = useTranslation('search');

  return (
    <TextField
      variant="standard"
      value={value}
      placeholder={t('placeholder') || ''}
      name="search"
      onChange={e => onChange(e.target.value, 'change')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value && <ClearIcon onClick={() => onChange('', 'clear')} sx={{ cursor: 'pointer' }} />}
          </InputAdornment>
        ),
        disableUnderline,
      }}
    />
  );
};

export default Search;
