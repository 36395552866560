import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'contentEditorSaga', {
  createHeaderSuccessfully: 'La cabecera fue creada exitosamente',
  updateHeaderSuccessfully: 'La cabecera fue editada exitosamente',
  deleteHeaderSuccessfully: 'La cabecera fue eliminada exitosamente',
  createHeaderError: 'Ocurrió un error al crear la cabecera. Revise los parámetros y vuelva a intentarlo',
  updateHeaderError: 'Ocurrió un error al editar la cabecera. Revise los parámetros y vuelva a intentarlo',
  deleteHeaderError: 'Ocurrió un error al eliminar la cabecera. Vuelva a intentarlo en unos minutos',
  updateHeaderOrderError: 'Ocurrió un error al ordenar las cabeceras. Vuelva a intentarlo en unos minutos',

  createComponentSuccessfully: 'El componente fue creado exitosamente',
  updateComponentSuccessfully: 'El componente fue editado exitosamente',
  deleteComponentSuccessfully: 'El componente fue eliminado exitosamente',
  createComponentError: 'Ocurrió un error al crear el componente. Revise los parámetros y vuelva a intentarlo',
  updateComponentError: 'Ocurrió un error al editar el componente. Revise los parámetros y vuelva a intentarlo',
  deleteComponentError: 'Ocurrió un error al eliminar el componente. Vuelva a intentarlo en unos minutos',
  updateComponentOrderError: 'Ocurrió un error al ordenar los componentes. Vuelva a intentarlo en unos minutos',
});

export default i18n;
