import { StripeProduct, StripeSubscriptionRequest, StripeSubscritionType } from 'src/types/auth';
import { get, post } from './api';

export const getStripeProducts = () => {
  return get<StripeProduct[]>('/stripe/products');
};

export const subscribeToProduct = (params: StripeSubscriptionRequest) => {
  return post<StripeSubscritionType>('/stripe/payments', params);
};
