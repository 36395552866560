import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, GlobalStoreType, Resource } from 'src/types/global';
import { RootState } from '../store';
import { ContentType } from 'src/types/contents';

const initialState: GlobalStoreType = {
  loading: false,
  snackbar: null,
  myContents: [],
  alert: {
    show: false,
    message: '',
    title: '',
    onCancel: () => {},
    onConfirm: () => {},
  },
  finder: {
    loading: false,
    resources: [],
  },
};

function isRequestAction(action: AnyAction) {
  return action.type.endsWith('Request');
}

function isResponseAction(action: AnyAction) {
  return action.type.endsWith('Error') || action.type.endsWith('Success');
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getMyContents: (state: GlobalStoreType) => {},
    getMyContentsSuccess: (state: GlobalStoreType, action: PayloadAction<ContentType[]>) => {
      state.myContents = action.payload;
    },
    getMyContentsError: (state: GlobalStoreType) => {
      state.myContents = [];
    },
    showSuccessSnackbar: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'success',
      };
    },
    showErrorSnackbar: (state: GlobalStoreType, action: PayloadAction<string>) => {
      state.snackbar = {
        show: true,
        message: action.payload,
        status: 'error',
      };
    },
    closeSnackbar: (state: GlobalStoreType) => {
      state.snackbar = {
        show: false,
        message: '',
        status: 'info',
      };
    },
    setAlert: (state: GlobalStoreType, action: PayloadAction<AlertType>) => {
      state.alert = action.payload;
    },
    closeAlert: (state: GlobalStoreType) => {
      state.alert.show = false;
    },
    findResource: (state: GlobalStoreType, action: PayloadAction<string>) => {
      if (action.payload) state.finder.loading = true;
      else state.finder.resources = [];
    },
    findResourceSuccess: (state: GlobalStoreType, action: PayloadAction<Resource[]>) => {
      state.finder.loading = false;
      state.finder.resources = action.payload;
    },
    findResourceError: (state: GlobalStoreType) => {
      state.finder.loading = false;
    },
    setResources: (state: GlobalStoreType, action: PayloadAction<Resource[]>) => {
      state.finder.loading = false;
      state.finder.resources = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isRequestAction, (state: GlobalStoreType) => {
        state.loading = true;
      })
      .addMatcher(isResponseAction, (state: GlobalStoreType) => {
        state.loading = false;
      });
  },
});

export const globalActions = globalSlice.actions;

export const globalStore = (state: RootState) => state.global;

export default globalSlice.reducer;
