import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getComponentById } from 'src/utils/courses';
import {
  CourseCategoryType,
  CourseDetailType,
  CourseEditorStep,
  CourseEditorStoreType,
  CourseModuleType,
  CourseType,
  CreateCourseModuleRequest,
  CreateCourseRequest,
  CreateModuleComponentRequest,
  DeleteCourseModuleRequest,
  DeleteModuleComponentRequest,
  ModuleComponentType,
  UpdateCourseModuleOrderRequest,
  UpdateCourseModuleRequest,
  UpdateCourseRequest,
  UpdateModuleComponentOrderRequest,
  UpdateModuleComponentRequest,
} from 'src/types/courses';

const initialState: CourseEditorStoreType = {
  step: CourseEditorStep.Course,
  course: null,
  modulesBackup: [],
  currentModule: null,
  isDeleteModuleDialogVisible: false,
  isModuleModalVisible: false,
  currentComponent: null,
  isDeleteComponentDialogVisible: false,
  isComponentModalVisible: false,
  categories: [],
};

const sliceName = 'courseEditor';

const courseEditorSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCourseRequest: (state: CourseEditorStoreType, action: PayloadAction<number>) => {},
    getCourseSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseDetailType>) => {
      state.course = action.payload;
    },
    getCourseError: (state: CourseEditorStoreType) => {
      state.course = null;
    },
    getCategoriesRequest: (state: CourseEditorStoreType) => {},
    getCategoriesSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseCategoryType[]>) => {
      state.categories = action.payload;
    },
    getCategoriesError: (state: CourseEditorStoreType) => {
      state.categories = [];
    },
    createCourseRequest: (state: CourseEditorStoreType, action: PayloadAction<CreateCourseRequest>) => {},
    createCourseSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseType>) => {
      state.course = {
        ...action.payload,
        modules: [],
        students: 0,
      };
      state.step = CourseEditorStep.Modules;
    },
    createCourseError: (state: CourseEditorStoreType) => {
      state.course = null;
    },
    updateCourseRequest: (state: CourseEditorStoreType, action: PayloadAction<UpdateCourseRequest>) => {},
    updateCourseSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseType>) => {
      if (state.course) {
        state.course = { ...state.course, ...action.payload };
        state.step = CourseEditorStep.Modules;
      }
    },
    updateCourseError: (state: CourseEditorStoreType) => {
      state.course = null;
    },
    createCourseModuleRequest: (state: CourseEditorStoreType, action: PayloadAction<CreateCourseModuleRequest>) => {},
    createCourseModuleSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseModuleType>) => {
      if (state.course) state.course.modules = [...(state.course.modules || []), action.payload];
      state.isModuleModalVisible = false;
    },
    createCourseModuleError: (state: CourseEditorStoreType) => {},
    updateCourseModuleRequest: (state: CourseEditorStoreType, action: PayloadAction<UpdateCourseModuleRequest>) => {},
    updateCourseModuleSuccess: (state: CourseEditorStoreType, action: PayloadAction<CourseModuleType>) => {
      if (state.course) {
        const moduleIdx = state.course.modules.findIndex(module => module.id === action.payload.id);
        if (moduleIdx >= 0) state.course.modules[moduleIdx] = action.payload;
      }
      state.isModuleModalVisible = false;
      state.currentModule = null;
    },
    updateCourseModuleError: (state: CourseEditorStoreType) => {},
    deleteCourseModuleRequest: (state: CourseEditorStoreType, action: PayloadAction<DeleteCourseModuleRequest>) => {},
    deleteCourseModuleSuccess: (state: CourseEditorStoreType, action: PayloadAction<DeleteCourseModuleRequest>) => {
      if (state.course) {
        state.course.modules = state.course.modules.filter(module => module.id !== action.payload.id);
      }
      state.isDeleteModuleDialogVisible = false;
      state.currentModule = null;
    },
    deleteCourseModuleError: (state: CourseEditorStoreType) => {},
    updateCourseModuleOrder: (
      state: CourseEditorStoreType,
      action: PayloadAction<UpdateCourseModuleOrderRequest>,
    ) => {},
    updateCourseModuleOrderSuccess: (state: CourseEditorStoreType) => {},
    updateCourseModuleOrderError: (state: CourseEditorStoreType) => {},
    createModuleComponentRequest: (
      state: CourseEditorStoreType,
      action: PayloadAction<CreateModuleComponentRequest>,
    ) => {},
    createModuleComponentSuccess: (state: CourseEditorStoreType, action: PayloadAction<ModuleComponentType>) => {
      if (state.course && action.payload.moduleId) {
        const moduleIdx = state.course.modules.findIndex(module => module.id === action.payload.moduleId);
        if (moduleIdx >= 0)
          state.course.modules[moduleIdx].components = [
            ...(state.course.modules[moduleIdx].components || []),
            action.payload,
          ];
      }
      state.isComponentModalVisible = false;
    },
    createModuleComponentError: (state: CourseEditorStoreType) => {},
    updateModuleComponentRequest: (
      state: CourseEditorStoreType,
      action: PayloadAction<UpdateModuleComponentRequest>,
    ) => {},
    updateModuleComponentSuccess: (state: CourseEditorStoreType, action: PayloadAction<ModuleComponentType>) => {
      if (state.course && action.payload.moduleId) {
        const moduleIdx = state.course.modules.findIndex(module => module.id === action.payload.moduleId);
        if (moduleIdx >= 0) {
          const componentIdx = state.course.modules[moduleIdx].components.findIndex(
            component => component.id === action.payload.id,
          );
          if (componentIdx >= 0) {
            state.course.modules[moduleIdx].components[componentIdx] = action.payload;
          }
        }
      }
      state.isComponentModalVisible = false;
      state.currentComponent = null;
      state.currentModule = null;
    },
    updateModuleComponentError: (state: CourseEditorStoreType) => {},
    deleteModuleComponentRequest: (
      state: CourseEditorStoreType,
      action: PayloadAction<DeleteModuleComponentRequest>,
    ) => {},
    deleteModuleComponentSuccess: (
      state: CourseEditorStoreType,
      action: PayloadAction<DeleteModuleComponentRequest>,
    ) => {
      if (state.course && action.payload.moduleId) {
        const moduleIdx = state.course.modules.findIndex(module => module.id === action.payload.moduleId);
        if (moduleIdx >= 0) {
          state.course.modules[moduleIdx].components = state.course.modules[moduleIdx].components.filter(
            component => component.id !== action.payload.id,
          );
        }
      }
      state.isDeleteComponentDialogVisible = false;
      state.currentComponent = null;
      state.currentModule = null;
    },
    deleteModuleComponentError: (state: CourseEditorStoreType) => {},
    updateModuleComponentOrder: (
      state: CourseEditorStoreType,
      action: PayloadAction<UpdateModuleComponentOrderRequest>,
    ) => {
      const { moduleId, id, order } = action.payload;
      const component = getComponentById(state.course, id);
      if (component && state.course) {
        state.modulesBackup = JSON.parse(JSON.stringify(state.course.modules));

        const modules = [...state.course.modules].reduce<CourseModuleType[]>((acc, curr) => {
          const components = curr.components;
          const componentIdx = curr.components.findIndex(c => c.id === id);
          if (componentIdx >= 0) components.splice(componentIdx, 1);
          if (curr.id === moduleId) components.splice(order, 0, component);
          return [...acc, { ...curr, components }];
        }, []);

        state.course.modules = modules;
      }
    },
    updateModuleComponentOrderSuccess: (state: CourseEditorStoreType) => {
      state.modulesBackup = [];
    },
    updateModuleComponentOrderError: (state: CourseEditorStoreType) => {
      if (state.course) state.course.modules = state.modulesBackup;
      state.modulesBackup = [];
    },
    toggleDeleteModuleDialogVisibility: (state: CourseEditorStoreType, action: PayloadAction<boolean>) => {
      state.isDeleteModuleDialogVisible = action.payload;
    },
    toggleDeleteComponentDialogVisibility: (state: CourseEditorStoreType, action: PayloadAction<boolean>) => {
      state.isDeleteComponentDialogVisible = action.payload;
    },
    toggleModuleModalVisibility: (state: CourseEditorStoreType, action: PayloadAction<boolean>) => {
      state.isModuleModalVisible = action.payload;
    },
    toggleComponentModalVisibility: (state: CourseEditorStoreType, action: PayloadAction<boolean>) => {
      state.isComponentModalVisible = action.payload;
    },
    setCurrentModule: (state: CourseEditorStoreType, action: PayloadAction<CourseModuleType | null>) => {
      state.currentModule = action.payload;
    },
    setCurrentComponent: (state: CourseEditorStoreType, action: PayloadAction<ModuleComponentType | null>) => {
      state.currentComponent = action.payload;
    },
    setCourse: (state: CourseEditorStoreType, action: PayloadAction<CourseDetailType | null>) => {
      state.course = action.payload;
    },
    setStep: (state: CourseEditorStoreType, action: PayloadAction<CourseEditorStep>) => {
      state.step = action.payload;
    },
    setCategories: (state: CourseEditorStoreType, action: PayloadAction<CourseCategoryType[]>) => {
      state.categories = action.payload;
    },
  },
});

export const actions = courseEditorSlice.actions;

export const courseEditorStore = (state: RootState) => state.courseEditor;

export default courseEditorSlice.reducer;

export const selectComponentsByModuleId = createSelector(
  (state: RootState) => state.courseEditor,
  ({ course }) =>
    course?.modules?.reduce<{ [k: string]: ModuleComponentType[] }>(
      (acc, curr) => ({
        ...acc,
        [`module-${curr.id}`]: curr.components?.map(c => ({ ...c, moduleId: curr.id })) || [],
      }),
      {},
    ),
);

export const selectModulesById = createSelector(
  (state: RootState) => state.courseEditor,
  ({ course }) =>
    course?.modules?.reduce<{ [k: string]: CourseModuleType }>(
      (acc, curr) => ({ ...acc, [`module-${curr.id}`]: curr }),
      {},
    ),
);
