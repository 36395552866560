/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeOptions } from '@mui/material';

const primary = '#E63721';
const secondary = '#29343D';
const success = '#5f9d5c';

const light = {
  main: '#f6f8fc',
  lighter: '#fff',
  border: '#e8e8e8',
  lightText: '#a2a2a2',
  text: '#222',
  textDark: '#141414',
};

export const lightThemeOptions: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: light.text,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
    },
    body2: {
      fontSize: 12,
      color: '#a2a2a2',
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    background: {
      default: light.main,
      paper: light.lighter,
    },
    text: {
      primary: light.text,
    },
    success: {
      main: success,
    },
    divider: light.border,
    grey: {
      '200': '#ededed',
      '300': '#d5d5d5',
      '500': '#a2a2a2',
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          border: 'none',
          '& > tr': {
            backgroundColor: light.lighter,
            height: 64,
          },
          '& > tr > th': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: light.main,
          height: '72px',
          '&:last-child > td': {
            border: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
          fontSize: 15,
          padding: '12px 16px',
          borderBottomColor: light.border,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: light.lighter,
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '10px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '1rem',
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '1rem',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '1.15rem',
          },
          '&.MuiButton-text': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '&.MuiButton-outlined': {
            borderWidth: 2,
          },
          '&.MuiButton-text:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& > div': {
            backgroundColor: light.lighter,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'linear-gradient(rgba(255,255,255,0.1),rgba(255,255,255,0.05))',
          borderRight: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: light.main,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label, & label.Mui-focused': {
            color: light.lightText,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label, & label.Mui-focused': {
            color: light.lightText,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: light.text,
          '&:before': {
            borderBottom: `1px solid ${light.border}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${light.border}`,
          },
          '&:after': {
            borderBottom: `1px solid ${light.border}`,
          },
          '&.Mui-focused:after': {
            borderBottom: `1px solid ${light.border}`,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& > span': {
            fontSize: '1.15rem',
          },
        },
      },
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...lightThemeOptions,
};
