import { EventInput } from '@fullcalendar/core';
import dayjs from 'dayjs';
import { CourseDetailType } from 'src/types/courses';
import { EventDetailsType } from 'src/types/eventList';

export const transformEventToCalendarEvent = (event: EventDetailsType): EventInput => {
  return {
    id: `event-${event?.id?.toString()}`,
    title: event.title,
    date: event.datetime as unknown as string,
    start: event.datetime as unknown as string,
    type: 'event',
    link: event.link,
    description: event.description,
    image: event.image,
  };
};

export const transformCourseToCalendarEvent = (course: CourseDetailType): EventInput => {
  return {
    id: `course-${course?.id?.toString()}`,
    title: course.title,
    allDay: true,
    date: course.startDate as unknown as string,
    start: course.startDate as unknown as string,
    end: course.endDate ? dayjs(course.endDate).add(1, 'day').format('YYYY-MM-DD') : '',
    type: 'course',
  };
};
