import {
  ChangePasswordRequestType,
  ChangePasswordResponseType,
  ForgotPasswordRequestType,
  ForgotPasswordResponseType,
  LoginRequestType,
  LoginResponseType,
  RegisterResponseType,
} from 'src/types/auth';
import { post } from './api';

export const login = (params: LoginRequestType) => {
  return post<LoginResponseType>('/auth/login', params);
};

export const register = (params: LoginRequestType) => {
  return post<RegisterResponseType>('/auth/register', params);
};

export const isValidEmail = (params: LoginRequestType) => {
  return post('/auth/validate-register', params);
};
export const forgotPassword = (params: ForgotPasswordRequestType) => {
  return post<ForgotPasswordResponseType>('/auth/recover-password', params);
};

export const changePassword = ({ key, ...params }: ChangePasswordRequestType) => {
  return post<ChangePasswordResponseType>(`/auth/change-password?key=${key}`, params);
};
