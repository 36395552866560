import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SettingsApiResponseType, SettingsStore, SettingsType } from 'src/types/settings';

const initialState: SettingsStore = {
  settings: {
    commercialName: '',
    name: '',
    phone: '',
    document: '',
    email: '',
    adminEmail: '',
    notificationEmail: '',
    country: '',
    province: '',
    city: '',
    street: '',
    postalCode: '',
    instagram: '',
    twitter: '',
    facebook: '',
    videos: [
      { title: '', link: '' },
      { title: '', link: '' },
      { title: '', link: '' },
    ],
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettingsRequest: (state: SettingsStore) => {},
    getSettingsSuccess: (state: SettingsStore, action: PayloadAction<SettingsApiResponseType>) => {
      state.settings = action.payload;
      const emptyVideos = [];
      for (let i = 0; 3 - action.payload.videos.length - i > 0; i++) {
        emptyVideos.push({ title: '', link: '' });
      }
      state.settings.videos = [...action.payload.videos, ...emptyVideos];
    },
    getSettingsError: (state: SettingsStore) => {
      state.settings = initialState.settings;
    },
    updateSettingsRequest: (state: SettingsStore, action: PayloadAction<SettingsType>) => {},
    updateSettingsSuccess: (state: SettingsStore, action: PayloadAction<SettingsApiResponseType>) => {
      state.settings = action.payload;
    },
    updateSettingsError: (state: SettingsStore) => {},
  },
});

export const actions = settingsSlice.actions;

export const settingsStore = (state: RootState) => state.settings;

export default settingsSlice.reducer;
