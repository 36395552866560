import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'clientDetailSaga',
  {
    updateSuccessfully: 'Acceso actualizado exitosamente.',
    updateError: 'Ocurrió un error al actualizar el acceso. Vuelva a intentarlo más tarde.',
  },
  true,
  false,
);

export default i18n;
