import styled, { css } from 'styled-components';

export const AppContainer = styled.div.attrs({ id: 'app-container ' })`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  overflow: hidden;

  --header-height: 100px;

  ${({ theme }) => css`
    --fc-border-color: ${theme.palette.divider};
    --fc-today-bg-color: ${theme.palette.background.paper};
    --fc-button-bg-color: 'transparent';
    --fc-button-hover-bg-color: ${theme.palette.background.default};
    --fc-button-active-bg-color: ${theme.palette.background.default};
    --fc-button-hover-border-color: ${theme.palette.background.default};
    --fc-button-border-color: 'transparent';
    --fc-button-active-border-color: ${theme.palette.background.default};
    --fc-event-border-color: ${theme.palette.primary.main};

    .fc .fc-button-primary:not(:disabled):active:focus {
      box-shadow: none;
    }

    .fc .fc-button {
      padding: 4px 6px;
    }

    .fc-prev-button > .fc-icon,
    .fc-next-button > .fc-icon {
      font-size: 28px;
      color: ${({ theme }) => theme.palette.text.primary};
    }

    .fc-day-other {
      background-color: ${theme.palette.grey[200]};
      color: ${theme.palette.grey[500]};

      & .fc-daygrid-day-top {
        opacity: 1;
      }
    }

    .fc-daygrid-day-top {
      padding-right: 8px;
      padding-top: 8px;
      color: ${theme.palette.grey[500]};
      font-weight: 500;
      font-size: 0.9rem;
    }

    .fc-col-header-cell-cushion {
      color: ${theme.palette.grey[500]};
      font-weight: 600;
      font-size: 0.9rem;
      text-transform: uppercase;
    }

    .fc-event-title.fc-sticky::before {
      display: none;
    }

    .fc-daygrid-block-event.fc-h-event {
      background-color: ${theme.palette.primary.main};
      cursor: pointer;

      &:hover {
        background-color: ${theme.palette.primary.dark};
      }
    }
  `};

  .fc-scrollgrid.fc-scrollgrid-liquid {
    border-top: 0;
  }

  .fc-scroller table thead tr th {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 4px 0;
  }

  .fc-event-time {
    font-weight: 700;
  }

  .fc-event-title {
    position: relative;
    font-weight: 500;
    padding-left: 8px;

    &::before {
      position: absolute;
      content: '-';
      left: 1px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .fc-daygrid-dot-event:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .fc .fc-button:focus,
  .fc .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc-today-button {
    display: none;
  }

  .fc-h-event {
    border: none;
    background-color: transparent;
  }

  .fc-daygrid-event {
    white-space: normal;
  }

  .MuiToolbar-root {
    height: var(--header-height, 100);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    --header-height: 64px;
  }
`;
