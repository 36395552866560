import i18n from 'src/i18n/i18n';

i18n.addResources('es', 'dropzone', {
  file: 'Arrastra aquí el fichero',
  img: 'Arrastra aquí la imagen',
  vid: 'Arrastra aquí el video',
  doc: 'Arrastra aquí el documento',
  fileExt: '.doc, .xlsx, .ppt, .pdf',
  docExt: '.doc, .docx',
  imgExt: '.jpg, .png, .webp',
  vidExt: '.mp4, .mov',
  select: 'o seleccionado ',
  here: 'aquí',
  drop: 'Deja los archivos aqui',
  replace: 'Reemplazar',
  download: 'Descargar',
  maximumSize: 'Peso máx: {{ size }}Mb',
  formats: 'Formatos: ',
  exceedsMaximumSize: 'El archivo excede el peso máximo soportado',
  invalidFormat: 'El archivo no cumple con los formatos soportados',
});

export default i18n;
