import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'profileSaga',
  {
    updateSuccessfully: 'El perfil ha sido actualizado exitosamente',
    updateImageSuccessfully: 'La imagen de perfil ha sido actualizada exitosamente',
    updateError: 'Ocurrió un error al actualizar los datos del perfil. Revise los parámetros y vuelva a intentarlo',
    updateImageError: 'Ocurrió un error al actualizar la imagen de perfil. Revise los parámetros y vuelva a intentarlo',
  },
  true,
  false,
);

export default i18n;
