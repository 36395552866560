import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'authSaga',
  {
    loginError: 'Usuario o contraseña inválida',
    registerError: 'Usuario o contraseña inválida',
    invalidEmail: 'El email ya se encuentra en uso. Puede intentar con otro correo o recuperar la contraseña.',
    subscribeError: 'Ocurrió un error al suscribirse al plan. Vuelva a intentarlo en unos minutos',
    forgotPasswordSuccess: 'Se ha enviado el correo de recuperación de contraseña',
    forgotPasswordError: 'Ocurrió un error al enviar el correo. Vuelva a intentarlo en unos minutos',
    changePasswordSuccess: 'La contraseña fue actualizada exitosamente',
    changePasswordKeyError: 'La clave generada expiró o es inválida. Reintente desde el principio',
    changePasswordError: 'Ocurrió un error al cambiar la contraseña. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
