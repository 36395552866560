import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/courseDetail/courseDetail';
import {
  getCourse as getCourseApi,
  enrollInCourse as enrollInCourseApi,
  getCourseModulesProgress as getCourseModulesProgressApi,
} from 'src/services/courses';
import { CourseDetailType, CourseModuleType, MyCourseType } from 'src/types/courses';
import { errorController } from '../utils/errorController';
import i18next from 'i18next';
import './i18n';

function* getCourse({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<CourseDetailType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseDetailType>
> {
  try {
    const { status, data } = yield call(getCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getCourseSuccess(data));
    } else {
      yield put(actions.getCourseError());
    }
  } catch (e) {
    yield put(actions.getCourseError());
  }
}

function* enrollInCourse({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<MyCourseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<MyCourseType>
> {
  try {
    const { status, data } = yield call(enrollInCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.enrollInCourseSuccess(data));
    } else {
      yield put(actions.enrollInCourseError());
      yield errorController(i18next.t('courseDetailSaga:enrollError'), data);
    }
  } catch (e) {
    yield put(actions.enrollInCourseError());
    yield errorController(i18next.t('courseDetailSaga:enrollError'), e);
  }
}

function* getCourseModulesProgress({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<CourseModuleType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseModuleType[]>
> {
  try {
    const { status, data } = yield call(getCourseModulesProgressApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getCourseModulesProgressSuccess(data));
    } else {
      yield put(actions.getCourseModulesProgressError());
    }
  } catch (e) {
    yield put(actions.getCourseModulesProgressError());
  }
}

const courseDetailSaga: ForkEffect<never>[] = [
  takeLatest(actions.getCourseRequest, getCourse),
  takeLatest(actions.enrollInCourseRequest, enrollInCourse),
  takeLatest(actions.getCourseModulesProgressRequest, getCourseModulesProgress),
];

export default courseDetailSaga;
