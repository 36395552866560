import { SettingsType, SettingsApiResponseType } from 'src/types/settings';
import { get, put } from './api';

export const getSettings = () => {
  return get<SettingsApiResponseType>('/settings');
};

export const updateSettings = (settings: SettingsType) => {
  return put<SettingsApiResponseType>('/settings', settings, {
    headers: { 'content-type': 'application/json' },
  });
};
