import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/contentsList/contentsList';
import { globalActions } from 'src/reducers/global/global';
import {
  getContents as getContentsApi,
  createContent as createContentApi,
  updateContent as updateContentApi,
  deleteContent as deleteContentApi,
  updateContentOrder as updateContentOrderApi,
} from 'src/services/contents';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { ContentType, CreateContentRequest, UpdateContentOrderRequest, UpdateContentRequest } from 'src/types/contents';

function* getContents(): Generator<
  CallEffect<AxiosResponse<ContentType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ContentType[]>
> {
  try {
    const { status, data } = yield call(getContentsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getContentsSuccess(data));
    } else {
      yield put(actions.getContentsError());
    }
  } catch (e) {
    yield put(actions.getContentsError());
  }
}

function* createContent({
  payload,
}: PayloadAction<CreateContentRequest>): Generator<
  CallEffect<AxiosResponse<ContentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ContentType>
> {
  try {
    const { status, data } = yield call(createContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createContentSuccess(data));
      yield put(globalActions.getMyContents());
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:createSuccessfully')));
    } else {
      yield put(actions.createContentError());
      yield errorController(i18next.t('contentsSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createContentError());
    yield errorController(i18next.t('contentsSaga:createError'), e);
  }
}

function* updateContent({
  payload,
}: PayloadAction<UpdateContentRequest>): Generator<
  CallEffect<AxiosResponse<ContentType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ContentType>
> {
  try {
    const { status, data } = yield call(updateContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateContentSuccess(data));
      yield put(globalActions.getMyContents());
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateContentError());
      yield errorController(i18next.t('contentsSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateContentError());
    yield errorController(i18next.t('contentsSaga:updateError'), e);
  }
}

function* updateContentOrder({
  payload,
}: PayloadAction<UpdateContentOrderRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateContentOrderApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateContentOrderSuccess());
      yield put(globalActions.getMyContents());
      // yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateContentError());
      yield errorController(i18next.t('contentsSaga:updateOrderError'), data);
    }
  } catch (e) {
    yield put(actions.updateContentError());
    yield errorController(i18next.t('contentsSaga:updateOrderError'), e);
  }
}

function* deleteContent({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteContentSuccess(payload));
      yield put(globalActions.getMyContents());
      yield put(globalActions.showSuccessSnackbar(i18next.t('contentsSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteContentError());
      yield errorController(i18next.t('contentsSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteContentError());
    yield errorController(i18next.t('contentsSaga:deleteError'), e);
  }
}

const contentsListSaga: ForkEffect<never>[] = [
  takeLatest(actions.getContentsRequest, getContents),
  takeLatest(actions.createContentRequest, createContent),
  takeLatest(actions.updateContentRequest, updateContent),
  takeLatest(actions.deleteContentRequest, deleteContent),
  takeLatest(actions.updateContentOrder, updateContentOrder),
];

export default contentsListSaga;
