import { Button, Typography } from '@mui/material';
import { Card, CourseDescription, Image, ButtonsContainer, CardContent, ImageContainer } from './styles';
import { CourseCardProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';
import { capitalizeFirstLetter, removeTags } from 'src/utils/strings';

const CourseCard = ({ isJoined, isAdmin, course, onViewDetails, onJoin }: CourseCardProps) => {
  const { t } = useTranslation('courseCard');

  const handleViewDetails = () => onViewDetails && onViewDetails(course);
  const handleJoin = () => onJoin && onJoin(course);

  return (
    <Card>
      <ImageContainer>
        <Image src={course.image} alt={course.title} />
      </ImageContainer>
      <CardContent>
        <Typography variant="body2">{capitalizeFirstLetter(course.category)}</Typography>
        <Typography variant="h6">{course.title}</Typography>
        <CourseDescription variant="body2">{removeTags(course.description)}</CourseDescription>
        <ButtonsContainer>
          {isAdmin && (
            <Button variant="contained" onClick={handleViewDetails}>
              {t('viewMore')}
            </Button>
          )}
          {!isAdmin && (
            <>
              {!isJoined && (
                <Button variant="contained" onClick={handleJoin}>
                  {t('join')}
                </Button>
              )}
              <Button
                variant={isJoined ? 'contained' : 'text'}
                color={isJoined ? 'primary' : 'secondary'}
                onClick={handleViewDetails}>
                {t('viewMore')}
              </Button>
            </>
          )}
        </ButtonsContainer>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
