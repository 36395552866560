import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListNewsDetailsType } from 'src/types/newsList';
import { RootState } from '../store';
import { HomeStoreType, CalendarDateType, CalendarRequestType } from 'src/types/home';
import { EventInput } from '@fullcalendar/core';
import { transformCourseToCalendarEvent, transformEventToCalendarEvent } from 'src/utils/home';
import { SettingsApiResponseType } from 'src/types/settings';

const initialState: HomeStoreType = {
  newsList: [],
  dates: [],
  startDate: '',
  endDate: '',
  isEventModalOpen: false,
  videos: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    getNewsRequest: (state: HomeStoreType) => {},
    getNewsSuccess: (state: HomeStoreType, action: PayloadAction<ListNewsDetailsType[]>) => {
      state.newsList = action.payload;
    },
    getNewsError: (state: HomeStoreType) => {
      state.newsList = [];
    },
    getSettingsRequest: (state: HomeStoreType) => {},
    getSettingsSuccess: (state: HomeStoreType, action: PayloadAction<SettingsApiResponseType['videos']>) => {
      state.videos = action.payload;
    },
    getSettingsError: (state: HomeStoreType) => {
      state.videos = [];
    },
    getCalendarRequest: (state: HomeStoreType, action: PayloadAction<CalendarRequestType>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    getCalendarSuccess: (state: HomeStoreType, action: PayloadAction<CalendarDateType[]>) => {
      state.dates = action.payload;
    },
    getCalendarError: (state: HomeStoreType) => {
      state.dates = [];
    },
    setOpenEventModal: (state: HomeStoreType, action: PayloadAction<boolean>) => {
      state.isEventModalOpen = action.payload;
    },
    getHomeRequest: (state: HomeStoreType, action: PayloadAction<CalendarRequestType>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const actions = homeSlice.actions;

export const homeStore = (state: RootState) => state.home;

export default homeSlice.reducer;

export const selectCalendarByDate = createSelector(
  (state: RootState) => state.home,
  home =>
    home.dates.reduce<EventInput[]>(
      (acc, date) => [
        ...acc,
        ...date.events.map(transformEventToCalendarEvent),
        ...date.courses.map(transformCourseToCalendarEvent),
      ],
      [],
    ),
);
