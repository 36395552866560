import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'settingsSaga',
  {
    updateSuccessfully: 'La configuración ha sido actualizada exitosamente.',
    updateError: 'Ocurrió un error al actualizar la configuración. Revise los parámetros y vuelva a intentarlo',
  },
  true,
  false,
);

export default i18n;
