import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import './i18n';
import i18next from 'i18next';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/clientDetail/clientDetail';
import { globalActions } from 'src/reducers/global/global';
import {
  getClient as getClientDetailApi,
  getClientDocuments as getDocumentsApi,
  getClientCourses as getCoursesApi,
  getClientContents as getContentsApi,
  updateClientDocument as updateDocumentApi,
  updateClientCourse as updateCourseApi,
  updateClientContent as updateContentApi,
} from 'src/services/clients';
import {
  ClientApiResponseType,
  ClientContentApiResponseType,
  ClientContentUpdateAccess,
  ClientCourseApiResponseType,
  ClientCourseUpdateAccess,
  ClientDocumentApiResponseType,
  ClientDocumentUpdateAccess,
} from 'src/types/clients';
import { errorController } from '../utils/errorController';

function* getClient({
  payload,
}: PayloadAction<ClientApiResponseType['id']>): Generator<
  CallEffect<AxiosResponse<ClientApiResponseType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ClientApiResponseType>
> {
  try {
    const { status, data } = yield call(getClientDetailApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientDetailSuccess(data));
    } else {
      yield put(actions.getClientDetailError());
    }
  } catch (e) {
    yield put(actions.getClientDetailError());
  }
}

function* getClientDocuments({
  payload,
}: PayloadAction<ClientApiResponseType['id']>): Generator<
  CallEffect<AxiosResponse<ClientDocumentApiResponseType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ClientDocumentApiResponseType[]>
> {
  try {
    const { status, data } = yield call(getDocumentsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientDocumentsSuccess(data));
    } else {
      yield put(actions.getClientDocumentsError());
    }
  } catch (e) {
    yield put(actions.getClientDocumentsError());
  }
}

function* getClientContents({
  payload,
}: PayloadAction<ClientApiResponseType['id']>): Generator<
  CallEffect<AxiosResponse<ClientContentApiResponseType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ClientContentApiResponseType[]>
> {
  try {
    const { status, data } = yield call(getContentsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientContentsSuccess(data));
    } else {
      yield put(actions.getClientContentsError());
    }
  } catch (e) {
    yield put(actions.getClientContentsError());
  }
}

function* getClientCourses({
  payload,
}: PayloadAction<ClientApiResponseType['id']>): Generator<
  CallEffect<AxiosResponse<ClientCourseApiResponseType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ClientCourseApiResponseType[]>
> {
  try {
    const { status, data } = yield call(getCoursesApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientCoursesSuccess(data));
    } else {
      yield put(actions.getClientCoursesError());
    }
  } catch (e) {
    yield put(actions.getClientCoursesError());
  }
}

function* updateClientDocument({
  payload,
}: PayloadAction<ClientDocumentUpdateAccess>): Generator<
  | CallEffect<AxiosResponse<ClientDocumentApiResponseType>>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ClientDocumentApiResponseType>
> {
  try {
    const { status, data } = yield call(updateDocumentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.patchClientDocumentSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientDetailSaga:updateSuccessfully')));
    } else {
      yield put(actions.patchClientDocumentError());
      yield errorController(i18next.t('clientDetailSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.patchClientDocumentError());
    yield errorController(i18next.t('clientDetailSaga:updateError'), e);
  }
}

function* updateClientContent({
  payload,
}: PayloadAction<ClientContentUpdateAccess>): Generator<
  | CallEffect<AxiosResponse<ClientContentApiResponseType>>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ClientContentApiResponseType>
> {
  try {
    const { status, data } = yield call(updateContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.patchClientContentSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientDetailSaga:updateSuccessfully')));
    } else {
      yield put(actions.patchClientContentError());
      yield errorController(i18next.t('clientDetailSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.patchClientContentError());
    yield errorController(i18next.t('clientDetailSaga:updateError'), e);
  }
}

function* updateClientCourse({
  payload,
}: PayloadAction<ClientCourseUpdateAccess>): Generator<
  | CallEffect<AxiosResponse<ClientCourseApiResponseType>>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ClientCourseApiResponseType>
> {
  try {
    const { status, data } = yield call(updateCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.patchClientCourseSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('clientDetailSaga:updateSuccessfully')));
    } else {
      yield put(actions.patchClientCourseError());
      yield errorController(i18next.t('clientDetailSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.patchClientCourseError());
    yield errorController(i18next.t('clientDetailSaga:updateError'), e);
  }
}

const clientDetailSaga: ForkEffect<never>[] = [
  takeLatest(actions.getClientDetailRequest, getClient),
  takeLatest(actions.getClientDocumentsRequest, getClientDocuments),
  takeLatest(actions.getClientContentsRequest, getClientContents),
  takeLatest(actions.getClientCoursesRequest, getClientCourses),
  takeLatest(actions.patchClientDocumentRequest, updateClientDocument),
  takeLatest(actions.patchClientContentRequest, updateClientContent),
  takeLatest(actions.patchClientCourseRequest, updateClientCourse),
];

export default clientDetailSaga;
