import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsDetailsType, NewsListStoreType, ListNewsDetailsType } from 'src/types/newsList';
import { RootState } from '../store';
import { filterBySearch } from 'src/utils/news';

const initialState: NewsListStoreType = {
  newsDetails: {
    title: '',
    link: '',
    image: null,
  },
  newsList: [],
  isOpen: false,
  searchValue: '',
  filteredNewsList: [],
};

export const newsListSlice = createSlice({
  name: 'newsList',
  initialState,
  reducers: {
    getNewsRequest: (state: NewsListStoreType) => {},
    getNewsSuccess: (state: NewsListStoreType, action: PayloadAction<ListNewsDetailsType[]>) => {
      state.newsList = action.payload;
    },
    getNewsError: (state: NewsListStoreType) => {
      state.newsList = [];
    },
    createNewsRequest: (state: NewsListStoreType, action: PayloadAction<NewsDetailsType>) => {},
    createNewsSuccess: (state: NewsListStoreType, action: PayloadAction<ListNewsDetailsType>) => {
      state.newsList = [...state.newsList, action.payload];
    },
    createNewsError: (state: NewsListStoreType) => {},
    updateNewsRequest: (state: NewsListStoreType, action: PayloadAction<NewsDetailsType>) => {},
    updateNewsSuccess: (state: NewsListStoreType, action: PayloadAction<ListNewsDetailsType>) => {
      const newsIdx = state.newsList.findIndex(({ id }) => id === action.payload.id);
      if (newsIdx >= 0) state.newsList[newsIdx] = action.payload;
    },
    updateNewsError: (state: NewsListStoreType) => {},
    deleteNewsRequest: (state: NewsListStoreType, action: PayloadAction<NewsDetailsType['id']>) => {},
    deleteNewsSuccess: (state: NewsListStoreType, action: PayloadAction<NewsDetailsType['id']>) => {
      state.newsList = state.newsList.filter(({ id }) => id !== action.payload);
    },
    deleteNewsError: (state: NewsListStoreType) => {},
    setNewsDetails: (state: NewsListStoreType, action: PayloadAction<NewsDetailsType>) => {
      state.newsDetails = action.payload;
    },
    setOpen: (state: NewsListStoreType, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    onSearch: (state: NewsListStoreType, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const selectFilteredNews = createSelector(
  (state: RootState) => state.newsList,
  newsListState => filterBySearch(newsListState.newsList, newsListState.searchValue),
);

export const actions = newsListSlice.actions;

export const newsListStore = (state: RootState) => state.newsList;

export default newsListSlice.reducer;
