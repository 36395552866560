import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/auth/auth';
import { setAuthToken } from 'src/services/api';
import {
  login as loginApi,
  register as registerApi,
  forgotPassword as forgotPasswordApi,
  changePassword as changePasswordApi,
  isValidEmail,
} from 'src/services/auth';
import { getStripeProducts as getStripeProductsApi } from 'src/services/stripe';
import { saveValue } from 'src/utils/localStorage';
import {
  ChangePasswordRequestType,
  ChangePasswordResponseType,
  ForgotPasswordRequestType,
  ForgotPasswordResponseType,
  LoginRequestType,
  LoginResponseType,
  RegisterResponseType,
  StripeProduct,
} from 'src/types/auth';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';

function* login({
  payload,
}: PayloadAction<LoginRequestType>): Generator<
  CallEffect<AxiosResponse<LoginResponseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<LoginResponseType>
> {
  try {
    const { status, data } = yield call(loginApi, payload);
    if (status >= 200 && status < 300) {
      saveValue('token', data.accessToken);
      setAuthToken(data.accessToken);
      yield put(actions.loginSuccess(data));
    } else {
      yield put(actions.loginError());
      if (status === 409) {
        yield put(actions.togglePaymentModalVisibility(true));
      } else {
        yield errorController(i18next.t('authSaga:loginError'));
      }
    }
  } catch (e) {
    yield put(actions.loginError());
    yield errorController(i18next.t('authSaga:loginError'));
  }
}

function* validateEmail({
  payload,
}: PayloadAction<LoginRequestType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status } = yield call(isValidEmail, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getStripeProductsRequest());
      yield put(actions.isValidEmailSuccess(payload));
    } else {
      yield put(actions.isValidEmailError());
      yield errorController(i18next.t('authSaga:invalidEmail'));
    }
  } catch (e) {
    yield put(actions.isValidEmailError());
    yield errorController(i18next.t('authSaga:invalidEmail'));
  }
}

function* getStripeProducts(): Generator<
  CallEffect<AxiosResponse<StripeProduct[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<StripeProduct[]>
> {
  try {
    const { status, data } = yield call(getStripeProductsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getStripeProductsSuccess(data));
    } else {
      yield put(actions.getStripeProductsError());
    }
  } catch (e) {
    yield put(actions.getStripeProductsError());
  }
}

function* register({
  payload,
}: PayloadAction<LoginRequestType>): Generator<
  CallEffect<AxiosResponse<RegisterResponseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<RegisterResponseType>
> {
  try {
    const { status, data } = yield call(registerApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.registerSuccess(data));
    } else {
      yield put(actions.registerError());
      yield errorController(i18next.t('authSaga:registerError'));
    }
  } catch (e) {
    yield put(actions.registerError());
    yield errorController(i18next.t('authSaga:registerError'));
  }
}

function* forgotPassword({
  payload,
}: PayloadAction<ForgotPasswordRequestType>): Generator<
  | CallEffect<AxiosResponse<ForgotPasswordResponseType>>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ForgotPasswordResponseType>
> {
  try {
    const { status, data } = yield call(forgotPasswordApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.forgotPasswordSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('authSaga:forgotPasswordSuccess')));
    } else {
      yield put(actions.forgotPasswordError());
      yield errorController(i18next.t('authSaga:forgotPasswordError'));
    }
  } catch (e) {
    yield put(actions.forgotPasswordError());
    yield errorController(i18next.t('authSaga:forgotPasswordError'));
  }
}

function* changePassword({
  payload,
}: PayloadAction<ChangePasswordRequestType>): Generator<
  | CallEffect<AxiosResponse<ChangePasswordResponseType>>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<ChangePasswordResponseType>
> {
  try {
    const { status, data } = yield call(changePasswordApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.changePasswordSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('authSaga:changePasswordSuccess')));
    } else {
      yield put(actions.changePasswordError());
      yield errorController(
        i18next.t(status === 403 ? 'authSaga:changePasswordKeyError' : 'authSaga:changePasswordError'), //TODO: Para poder brindar más precisiones necesitamos el código del error y mapearlo en front con el i18n
      );
    }
  } catch (e) {
    yield put(actions.changePasswordError());
    yield errorController(i18next.t('authSaga:changePasswordError'));
  }
}

const authSaga: ForkEffect<never>[] = [
  takeLatest(actions.loginRequest, login),
  takeLatest(actions.registerRequest, register),
  takeLatest(actions.isValidEmailRequest, validateEmail),
  takeLatest(actions.getStripeProductsRequest, getStripeProducts),
  takeLatest(actions.forgotPasswordRequest, forgotPassword),
  takeLatest(actions.changePasswordRequest, changePassword),
];

export default authSaga;
