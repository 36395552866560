import styled from 'styled-components';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled as muiStyled } from '@mui/material/styles';
import { DRAWER_WIDTH } from 'src/utils/constants';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const SearchContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: 0;
  }
`;

export const AppBarMobile = muiStyled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
}));

export const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
