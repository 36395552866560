/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, spawn, put } from 'redux-saga/effects';
import { Effect } from './types';
import { actions } from 'src/reducers/auth/auth';
import { removeItem } from 'src/utils/localStorage';
import authSaga from './auth/auth';
import globalSaga from './global/global';
import contentsListSaga from './contentsList/contentsList';
import contentDetailSaga from './contentDetail/contentDetail';
import contentEditorSaga from './contentEditor/contentEditor';
import eventsSaga from './eventList/eventList';
import newsSaga from './newsList/newsList';
import clientListSaga from './clientList/clientList';
import clientDetailSaga from './clientDetail/clientDetail';
import homeSaga from './home/home';
import profileSaga from './profile/profile';
import settingsSaga from './settings/settings';
import courseListSaga from './courseList/courseList';
import courseDetailSaga from './courseDetail/courseDetail';
import courseEditorSaga from './courseEditor/courseEditor';
import courseModuleDetailSaga from './courseModuleDetail/courseModuleDetail';
import documentListSaga from './documentList/documentList';
import documentDetailSaga from './documentDetail/documentDetail';

const sagas = [
  ...authSaga,
  ...globalSaga,
  ...contentsListSaga,
  ...contentDetailSaga,
  ...contentEditorSaga,
  ...eventsSaga,
  ...newsSaga,
  ...clientListSaga,
  ...clientDetailSaga,
  ...homeSaga,
  ...profileSaga,
  ...settingsSaga,
  ...courseListSaga,
  ...courseDetailSaga,
  ...courseEditorSaga,
  ...courseModuleDetailSaga,
  ...documentListSaga,
  ...documentDetailSaga,
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga: Effect) =>
      spawn(function* listenErrors() {
        let isSyncError = false;
        const resetSyncError = () => {
          isSyncError = false;
        };
        let httpError = false;
        while (true) {
          httpError = false;
          isSyncError = true;

          try {
            setTimeout(resetSyncError);

            yield call(function* execSaga() {
              yield saga;
            });

            console.error(
              'Unexpected root saga termination. ' +
                'The root sagas are supposed to be sagas that live during the wole app lifetime!',
              saga,
            );
          } catch (error: any) {
            httpError = typeof error.httpError !== 'undefined';
            if (!httpError && isSyncError) {
              throw new Error(`${saga.name} was terminated because it threw an exception on startup.`);
            }
            yield call(handleError, error);
          }

          if (!httpError) {
            // Para evitar que fallas infinatas bloqueen al browser
            console.error(saga.name, ' will be restarted after 1 second');
          }
        }
      }),
    ),
  );
}

export function* handleError(error: any) {
  if (error?.response?.status === 401) {
    removeItem('token');
    yield put(actions.logout());
  }
}
