import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'courseCard',
  {
    join: '¡Apuntarme!',
    viewMore: 'Ver detalle',
  },
  true,
  false,
);

export default i18n;
