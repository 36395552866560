import { useNavigate } from 'react-router-dom';
import { useIsAdmin } from 'src/hooks/useIsAdmin';
import ROUTES from 'src/routes/routes';

export const useHeader = () => {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate(ROUTES.SETTINGS);
  };

  return {
    isAdmin,
    handleSettingsClick,
  };
};
