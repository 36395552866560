import React, { useEffect } from 'react';
import { ItemProps } from './types';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutlineOutlined, DragHandleOutlined, EditOutlined } from '@mui/icons-material';
import { Actions, Content, DragIconButton, Wrapper, ItemContent } from './styles';

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, ItemProps>(
    (
      {
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        item,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        onRemove,
        onEdit,
        overrideItem,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) return;
        document.body.style.cursor = 'grabbing';
        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const handleClick = (func: (item: any) => void) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        func(item);
      };

      return (
        <Wrapper
          fadeIn={fadeIn}
          dragOverlay={dragOverlay}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
              '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
              '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
              '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
              '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
              '--index': index,
            } as React.CSSProperties
          }
          ref={ref}>
          <Content
            dragging={dragging}
            withHandle={handle}
            dragOverlay={dragOverlay}
            disabled={disabled}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}>
            <ItemContent>{overrideItem ? overrideItem(item) : <Typography>{value || ''}</Typography>}</ItemContent>
            <Actions>
              {onEdit ? (
                <IconButton color="default" onClick={handleClick(onEdit)}>
                  {<EditOutlined fontSize="small" />}
                </IconButton>
              ) : undefined}
              {onRemove ? (
                <IconButton color="error" onClick={handleClick(onRemove)}>
                  {<DeleteOutlineOutlined fontSize="small" />}
                </IconButton>
              ) : undefined}
            </Actions>
            {handle ? (
              <DragIconButton {...handleProps} {...listeners} color="default">
                <DragHandleOutlined />
              </DragIconButton>
            ) : null}
          </Content>
        </Wrapper>
      );
    },
  ),
);
