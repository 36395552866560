import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DocumentDetailStoreType, DocumentDetailType, ExportDocumentRequestType } from 'src/types/documents';

const initialState: DocumentDetailStoreType = {
  document: null,
};

const sliceName = 'documentDetail';

const documentDetailSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getDocumentRequest: (state: DocumentDetailStoreType, action: PayloadAction<number>) => {},
    getDocumentSuccess: (state: DocumentDetailStoreType, action: PayloadAction<DocumentDetailType>) => {
      state.document = action.payload;
    },
    getDocumentError: (state: DocumentDetailStoreType) => {
      state.document = null;
    },
    exportDocumentRequest: (state: DocumentDetailStoreType, action: PayloadAction<ExportDocumentRequestType>) => {},
    exportDocumentSuccess: (state: DocumentDetailStoreType) => {},
    exportDocumentError: (state: DocumentDetailStoreType) => {},
  },
});

export const actions = documentDetailSlice.actions;

export const documentDetailStore = (state: RootState) => state.documentDetail;

export default documentDetailSlice.reducer;
