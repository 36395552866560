import { transformHeaderComponentToFormData } from 'src/utils/contents';
import { get, remove, post, put, patch } from './api';
import {
  ContentDetailType,
  ContentHeaderType,
  ContentType,
  CreateComponentRequest,
  CreateContentHeaderRequest,
  CreateContentRequest,
  DeleteComponentRequest,
  DeleteContentHeaderRequest,
  HeaderComponentType,
  UpdateContentOrderRequest,
  UpdateComponentRequest,
  UpdateContentHeaderRequest,
  UpdateContentRequest,
  UpdateContentHeaderOrderRequest,
  UpdateHeaderComponentOrderRequest,
} from 'src/types/contents';

export const getContent = (id: number) => {
  return get<ContentDetailType>(`/contents/${id}`);
};

export const getContents = () => {
  return get<ContentType[]>('/contents');
};

export const getMyContents = () => {
  return get<ContentType[]>('/contents/me');
};

export const createContent = (content: CreateContentRequest) => {
  return post<ContentType>('/contents', content);
};

export const updateContent = (content: UpdateContentRequest) => {
  const { id, ...params } = content;
  return put<ContentType>(`/contents/${id}`, params);
};

export const deleteContent = (contentId: number) => {
  return remove(`/contents/${contentId}`);
};

export const updateContentOrder = (content: UpdateContentOrderRequest) => {
  const { id, ...params } = content;
  return patch(`/contents/${id}`, params);
};

export const createContentHeader = (request: CreateContentHeaderRequest) => {
  const { contentId, title } = request;
  return post<ContentHeaderType>(`/contents/${contentId}/headers`, { title });
};

export const updateContentHeader = (request: UpdateContentHeaderRequest) => {
  const { contentId, id, title } = request;
  return put<ContentHeaderType>(`/contents/${contentId}/headers/${id}`, { title });
};

export const deleteContentHeader = (request: DeleteContentHeaderRequest) => {
  const { contentId, id } = request;
  return remove(`/contents/${contentId}/headers/${id}`);
};

export const updateContentHeaderOrder = (content: UpdateContentHeaderOrderRequest) => {
  const { id, contentId, ...params } = content;
  return patch(`/contents/${contentId}/headers/${id}`, params);
};

export const createHeaderComponent = (request: CreateComponentRequest) => {
  const { contentId, headerId } = request;
  const formData: FormData = transformHeaderComponentToFormData(request);
  return post<HeaderComponentType>(`/contents/${contentId}/headers/${headerId}/components`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const updateHeaderComponent = (request: UpdateComponentRequest) => {
  const { contentId, headerId, id } = request;
  const formData: FormData = transformHeaderComponentToFormData(request);
  return put<HeaderComponentType>(`/contents/${contentId}/headers/${headerId}/components/${id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteHeaderComponent = (request: DeleteComponentRequest) => {
  const { contentId, headerId, id } = request;
  return remove(`/contents/${contentId}/headers/${headerId}/components/${id}`);
};

export const updateHeaderComponentOrder = (content: UpdateHeaderComponentOrderRequest) => {
  const { id, contentId, ...params } = content;
  return patch(`/contents/${contentId}/headers/${params.headerId}/components/${id}`, params);
};
