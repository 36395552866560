import styled from 'styled-components';

export const AccountContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 40px;
    height: 40px;
  }
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
