import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'myCourseCard',
  {
    continue: 'Continuar',
    finished: 'Terminado',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    completed: '¡Completado!',
    progress: 'Llevas un {{percentage}}% completado',
    motivation: '¿Aún no has empezado? ¡No te olvides!',
    start: 'Empezar',
  },
  true,
  false,
);

export default i18n;
