import { useContext } from 'react';
import { Menu as MuiMenu, MenuItem } from '@mui/material';
import { MenuContext } from 'src/contexts/MenuContext/MenuContext';
import { IconContainer } from './styles';

const Menu = () => {
  const { isOpen, target, options, setTarget } = useContext(MenuContext);

  return (
    <MuiMenu
      id="menu"
      anchorEl={target}
      keepMounted
      open={isOpen}
      onClose={() => setTarget(null)}
      PaperProps={{ style: { width: 180, right: 0 } }}
      MenuListProps={{ style: { padding: 0 } }}>
      {options.map(option => {
        const Icon = option.icon || null;
        return (
          <MenuItem
            disabled={option.condition ? !option.condition() : false}
            key={option.text}
            sx={{ padding: '11px 16px 10px 16px' }}
            onClick={option.onClick}>
            {Icon && (
              <IconContainer>
                <Icon />
              </IconContainer>
            )}
            {option.text}
          </MenuItem>
        );
      })}
    </MuiMenu>
  );
};

export default Menu;
