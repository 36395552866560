import React, { useRef, useState } from 'react';
import {
  DropZoneContainer,
  ErrorMessage,
  HiddenInput,
  IconContainer,
  Link,
  LinksContainer,
  FileName,
  PreviewImage,
} from './styles';
import { Button, CircularProgress, Icon, Typography } from '@mui/material';
import { IconByType } from 'src/utils/contents';
import { FileDropZoneProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';
import { downloadFile } from 'src/utils/file';

const fileExtTypes = {
  img: '.jpeg,.jpg,.png,.webp',
  vid: '.mp4,.mov',
  file: '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf',
  doc: '.doc,.docx',
};

const FileDropZone = ({
  files,
  error,
  filesTypes = 'img',
  multiple = false,
  maxMbSize,
  onChange,
}: FileDropZoneProps) => {
  const { t } = useTranslation('dropzone');
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const convertMegaBytesToBytes = (megaBytes: number) => megaBytes * 1024 * 1024;
  const getFileExtension = (file: File) => file.name.split('.').pop() || '';

  const fileListToArray = (list: FileList): File[] => {
    const result: File[] = [];
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i);
      if (file) result.push(file);
    }
    return result;
  };

  const onChangeFiles = (files: File[]) => {
    setLoading(true);
    const extensions = fileExtTypes[filesTypes].split(',').map(ext => ext.substring(1));
    const invalidFormat = files.some(file => !extensions.includes(getFileExtension(file)));

    if (invalidFormat) {
      setLocalError(t('invalidFormat') || '');
      setLoading(false);
      return;
    }

    const exceedsMaximumSize = maxMbSize && files.some(file => file.size > convertMegaBytesToBytes(maxMbSize));
    if (exceedsMaximumSize) {
      setLocalError(t('exceedsMaximumSize', { size: 2 }) || '');
      setLoading(false);
      return;
    }

    setLocalError('');
    onChange(files);
    setLoading(false);
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) onChangeFiles(fileListToArray(files));
  };

  const downloadFiles = () => {
    if (!files || files.length === 0) return;

    if (files.length === 1) downloadFile(files[0]);
    // TODO: Si hay mas de un archivo, zip
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setHover(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    setHover(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    stopEvent(event);
    const { files } = event.dataTransfer;
    setHover(false);
    if (files) onChangeFiles(fileListToArray(files));
  };

  const stopEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <DropZoneContainer
        error={!!(localError || error)}
        hover={hover}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}>
        {loading && <CircularProgress />}
        {!loading && hover && <Typography variant="subtitle1">{t('drop')}</Typography>}
        {!loading && !hover && (
          <>
            {files && files.length > 0 ? (
              <>
                {files.map(file => {
                  //TODO: Si es más de uno, armar un grid o similar
                  return file.type.includes('image') ? (
                    <PreviewImage src={URL.createObjectURL(file)} alt={file.name} />
                  ) : (
                    <FileName variant="subtitle1">{file.name}</FileName>
                  );
                })}
                <LinksContainer>
                  <Button variant="text" size="small" onClick={openFileDialog}>
                    {t('replace')}
                  </Button>
                  <Button variant="text" size="small" onClick={downloadFiles}>
                    {t('download')}
                  </Button>
                </LinksContainer>
              </>
            ) : (
              <>
                <IconContainer>
                  <Icon color="inherit" baseClassName="material-icons-outlined" fontSize="large">
                    {filesTypes === 'doc' ? IconByType['file'] : IconByType[filesTypes]}
                  </Icon>
                </IconContainer>
                <Typography variant="subtitle1">{t(filesTypes)}</Typography>
                <Typography variant="subtitle1">
                  {t('select')}
                  <Link onClick={openFileDialog}>{t('here')}</Link>
                </Typography>
              </>
            )}
            <br />
            {maxMbSize && <Typography variant="body2">{t('maximumSize', { size: maxMbSize })}</Typography>}
            <Typography variant="body2">
              {t('formats')}
              {t(`${filesTypes}Ext`)}
            </Typography>
          </>
        )}
        <HiddenInput
          ref={fileInputRef}
          accept={fileExtTypes[filesTypes]}
          type="file"
          multiple={multiple}
          onChange={onFilesAdded}
        />
      </DropZoneContainer>
      {(error || localError) && (
        <ErrorMessage variant="body2" color="red">
          {localError || error}
        </ErrorMessage>
      )}
    </>
  );
};

export default FileDropZone;
