import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoginRequestType,
  LoginResponseType,
  RegisterResponseType,
  AuthStoreType,
  JWTPayloadType,
  RoleEnum,
  UserType,
  RegisterRequestType,
  StripeProduct,
  ForgotPasswordRequestType,
  ForgotPasswordResponseType,
  ChangePasswordRequestType,
  ChangePasswordResponseType,
} from 'src/types/auth';
import jwtDecode from 'jwt-decode';
import { removeItem } from 'src/utils/localStorage';
import { RootState } from '../store';

const initialState: AuthStoreType = {
  user: null,
  isRegisterModalVisible: false,
  isPaymentModalVisible: false,
  credentials: null,
  token: null,
  products: [],
};

const getSessionInformation = (token: string): UserType | null => {
  try {
    const payload = jwtDecode(token) as JWTPayloadType;
    return {
      ...payload,
      role: payload.role === 'admin' ? RoleEnum.ADMIN : RoleEnum.ASSOCIATED,
    };
  } catch (err) {
    removeItem('token');
    return null;
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state: AuthStoreType, action: PayloadAction<LoginRequestType>) => {
      state.credentials = action.payload;
    },
    loginSuccess: (state: AuthStoreType, action: PayloadAction<LoginResponseType>) => {
      const { payload } = action;
      state.credentials = null;
      state.isPaymentModalVisible = false;
      state.user = getSessionInformation(payload.accessToken);
    },
    loginError: (state: AuthStoreType) => {},
    isValidEmailRequest: (state: AuthStoreType, action: PayloadAction<LoginRequestType>) => {},
    isValidEmailSuccess: (state: AuthStoreType, action: PayloadAction<LoginRequestType>) => {
      state.credentials = action.payload;
      state.isRegisterModalVisible = true;
    },
    isValidEmailError: (state: AuthStoreType) => {
      state.credentials = null;
      state.isRegisterModalVisible = false;
    },
    getStripeProductsRequest: (state: AuthStoreType) => {},
    getStripeProductsSuccess: (state: AuthStoreType, action: PayloadAction<StripeProduct[]>) => {
      state.products = action.payload;
    },
    getStripeProductsError: (state: AuthStoreType) => {
      state.products = [];
    },
    registerRequest: (state: AuthStoreType, action: PayloadAction<RegisterRequestType>) => {},
    registerSuccess: (state: AuthStoreType, action: PayloadAction<RegisterResponseType>) => {
      state.isRegisterModalVisible = false;
      state.isPaymentModalVisible = true;
    },
    registerError: (state: AuthStoreType) => {},
    forgotPasswordRequest: (state: AuthStoreType, action: PayloadAction<ForgotPasswordRequestType>) => {},
    forgotPasswordSuccess: (state: AuthStoreType, action: PayloadAction<ForgotPasswordResponseType>) => {},
    forgotPasswordError: (state: AuthStoreType) => {},
    changePasswordRequest: (state: AuthStoreType, action: PayloadAction<ChangePasswordRequestType>) => {},
    changePasswordSuccess: (state: AuthStoreType, action: PayloadAction<ChangePasswordResponseType>) => {},
    changePasswordError: (state: AuthStoreType) => {},
    setUserInformation: (state: AuthStoreType, action: PayloadAction<string>) => {
      const { payload } = action;
      state.token = payload;
      state.user = getSessionInformation(payload);
    },
    logout: (state: AuthStoreType) => {
      state.user = null;
      state.token = null;
    },
    toggleRegisterModalVisibility: (state: AuthStoreType, action: PayloadAction<boolean>) => {
      state.isRegisterModalVisible = action.payload;
    },
    togglePaymentModalVisibility: (state: AuthStoreType, action: PayloadAction<boolean>) => {
      state.isPaymentModalVisible = action.payload;
    },
  },
});

export const actions = authSlice.actions;

export const authStore = (state: RootState) => state.auth;

export default authSlice.reducer;
