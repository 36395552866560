import { Button, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ includeFilter?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;

  ${({ includeFilter }) =>
    includeFilter &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    `}
`;

export const Title = styled(Typography)`
  flex: 1;
`;

export const ActionsContainer = styled.div<{ minWidth: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  min-width: ${({ minWidth }) => minWidth}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
    min-width: auto;

    & > div:first-child {
      width: 100%;
    }
  }
`;

export const IconButton = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
  min-width: auto;
`;
