import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'documentListSaga',
  {
    uploadError: 'Ocurrió un error al subir el documento. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
