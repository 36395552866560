import { Autocomplete, CircularProgress, Icon, InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useSearch } from './useSearch';
import { useTranslation } from 'react-i18next';
import './i18n';
import { OptionContainer, OptionContent, OptionText } from './styles';
import { IconByResource } from 'src/utils/resources';
import { ResourceType } from 'src/types/global';

const Search = () => {
  const { t } = useTranslation('headerSearch');
  const { inputRef, localLoading, finder, open, value, setOpen, handleChange, handleResource } = useSearch();
  const resources = t('resourceType', { returnObjects: true }) as Record<ResourceType, string>;

  return (
    <Autocomplete
      id="finder"
      sx={{ width: '100%', maxWidth: 300 }}
      open={open && finder.resources.length > 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={option => option.title}
      options={finder.resources}
      // loading={finder.loading}
      inputValue={value}
      noOptionsText={t('noOptionsText')}
      renderInput={params => (
        <TextField
          {...params}
          ref={inputRef}
          placeholder={t('placeholder') || ''}
          value={value}
          variant="standard"
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {finder.loading || localLoading ? <CircularProgress color="inherit" size={16} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, resource) => (
        <OptionContainer {...props} onClick={() => handleResource(resource)}>
          <Icon color="primary" baseClassName="material-icons-outlined" fontSize="small">
            {IconByResource[resource.resourceType]}
          </Icon>
          <OptionContent>
            <OptionText variant="body2">{resources[resource.resourceType]}</OptionText>
            <OptionText>{resource.title}</OptionText>
          </OptionContent>
        </OptionContainer>
      )}
    />
  );
};

export default Search;
