import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { getMyContents as getMyContentsApi } from 'src/services/contents';
import { findResource as findResourceApi } from 'src/services/system';
import { globalActions } from 'src/reducers/global/global';
import { ContentType } from 'src/types/contents';
import { PayloadAction } from '@reduxjs/toolkit';
import { Resource } from 'src/types/global';

function* getMyContents(): Generator<
  CallEffect<AxiosResponse<ContentType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ContentType[]>
> {
  try {
    const { status, data } = yield call(getMyContentsApi);
    if (status >= 200 && status < 300) {
      yield put(globalActions.getMyContentsSuccess(data));
    } else {
      yield put(globalActions.getMyContentsError());
    }
  } catch (e) {
    yield put(globalActions.getMyContentsError());
  }
}

function* findResource({
  payload,
}: PayloadAction<string>): Generator<
  CallEffect<AxiosResponse<Resource[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<Resource[]>
> {
  try {
    const { status, data } = yield call(findResourceApi, payload);
    if (status >= 200 && status < 300) {
      yield put(globalActions.findResourceSuccess(data));
    } else {
      yield put(globalActions.findResourceError());
    }
  } catch (e) {
    yield put(globalActions.findResourceError());
  }
}

const globalSaga: ForkEffect<never>[] = [
  takeLatest(globalActions.getMyContents, getMyContents),
  takeLatest(globalActions.findResource, findResource),
];

export default globalSaga;
