import { ProfileType, ProfileApiResponseType, ProfileImageType } from 'src/types/profile';
import { get, put } from './api';

export const getProfile = () => {
  return get<ProfileApiResponseType>('/profile');
};

export const updateProfile = (profile: ProfileType) => {
  return put<ProfileApiResponseType>('/profile', profile, {
    headers: { 'content-type': 'application/json' },
  });
};

export const updateProfileImage = (profileImage: ProfileImageType) => {
  const formData: FormData = new FormData();
  formData.append('image', profileImage);
  return put<ProfileApiResponseType>('/profile/image', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};
