import React, { forwardRef } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragHandleOutlined';
import { DragIconButton, Header, Wrapper, Actions, AccordionHeader, HeaderContent, AccordionContent } from './styles';
import { ContainerProps } from './types';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined, ExpandMoreOutlined } from '@mui/icons-material';

export const Container = forwardRef<HTMLDivElement, ContainerProps>((allProps: ContainerProps, ref) => {
  const {
    item,
    children,
    columns = 1,
    handleProps,
    horizontal,
    hover,
    label,
    placeholder,
    style,
    scrollable,
    unstyled,
    shadow,
    collapsable,
    onClick,
    renderFooter,
    onRemove,
    onEdit,
    overrideHeader,
    ...props
  } = allProps;

  const handleClick = (func: (item: any) => void) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    func(item);
  };

  const renderHeader = () => (
    <Header collapsable={collapsable}>
      <HeaderContent>
        {overrideHeader ? overrideHeader(item) : <Typography variant="h5">{label || ''}</Typography>}
      </HeaderContent>
      <Actions>
        {onEdit ? (
          <IconButton color="default" onClick={handleClick(onEdit)}>
            {<EditOutlined fontSize="small" />}
          </IconButton>
        ) : undefined}
        {onRemove ? (
          <IconButton color="error" onClick={handleClick(onRemove)}>
            {<DeleteOutlineOutlined fontSize="small" />}
          </IconButton>
        ) : undefined}
      </Actions>
      <DragIconButton {...handleProps} color="default">
        <DragIndicatorIcon />
      </DragIconButton>
    </Header>
  );

  return (
    <Wrapper
      {...props}
      ref={ref as React.RefObject<HTMLDivElement>}
      style={{ ...style, '--columns': columns } as React.CSSProperties}
      horizontal={horizontal}
      hover={hover}
      scrollable={scrollable}
      hasPlaceholder={placeholder}
      unstyled={unstyled}
      shadow={shadow}
      collapsable={collapsable}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}>
      {label ? (
        collapsable ? (
          <AccordionHeader expandIcon={<ExpandMoreOutlined />}>{renderHeader()}</AccordionHeader>
        ) : (
          renderHeader()
        )
      ) : null}
      <AccordionContent>
        {placeholder ? children : <ul>{children}</ul>}
        {renderFooter && renderFooter(item)}
      </AccordionContent>
    </Wrapper>
  );
});
