import { useNavigate } from 'react-router-dom';
export const useNavigatePrevRoute = () => {
  const navigate = useNavigate();

  const goBack = (fallbackRoute: string) => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(fallbackRoute, { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  return { goBack };
};
