import { PaymentMethod } from '@stripe/stripe-js';

export const ROLE_TYPES = ['admin', 'asoc'] as const;
export type RoleType = (typeof ROLE_TYPES)[number];
export enum RoleEnum {
  ASSOCIATED = 1,
  ADMIN = 2,
}

export type AuthStoreType = {
  user: UserType | null;
  isRegisterModalVisible: boolean;
  isPaymentModalVisible: boolean;
  token: string | null;
  credentials: LoginRequestType | null;
  products: StripeProduct[];
};

export type UserType = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  role: RoleEnum;
};

export type JWTPayloadType = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  iat: number;
  exp: number;
  role: RoleType;
};

export type LoginRequestType = {
  email: string;
  password: string;
};

export type LoginResponseType = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
};

export type RegisterRequestType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  dni: string;
  phone: string;
  address: string;
  postalCode: string;
  city: string;
  province: string;
};

export type RegisterResponseType = {
  id: number;
};

export type StripeProduct = {
  name: string;
  priceId: string;
  price: number;
  currency: string;
};

export type StripeSubscriptionRequest = {
  email: string;
  paymentMethod: PaymentMethod;
  priceId: string;
};

export type StripeSubscritionType = {
  clientSecret: string;
  subscriptionId: string;
};

export type ForgotPasswordRequestType = {
  email: string;
};

export type ForgotPasswordResponseType = {
  email: string;
};

export type ChangePasswordRequestType = {
  key: string;
  password: string;
  confirmPassword: string;
};

export type ChangePasswordResponseType = {
  message: string;
};
