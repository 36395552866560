import { useEffect, useRef, useState } from 'react';
import { Navbar, Header, ConfirmDialog } from 'src/components';
import { PageContainer } from './styles';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { globalActions, globalStore } from 'src/reducers/global/global';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions } from 'src/reducers/profile/profile';
import { useCurrentPath } from 'src/hooks/useCurrentPath';
import { getValue, removeItem } from 'src/utils/localStorage';
import { useNavigatePrevRoute } from 'src/hooks/useNavigatePrevRoute';
import { ACCESS_DENIED } from 'src/utils/constants';

const PrivateLayout = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const dispatch = useDispatch();
  const userRefId = useRef<number | null>(null);
  const { user } = useSelector(authStore);
  const { alert } = useSelector(globalStore);
  const currentPath = useCurrentPath();
  const { goBack } = useNavigatePrevRoute();

  const handleNavbar = (open: boolean) => () => setOpenNavbar(open);

  useEffect(() => {
    if (user && user.id !== userRefId.current) {
      userRefId.current = user.id;
      dispatch(globalActions.getMyContents());
      if (currentPath !== ROUTES.PROFILE) dispatch(actions.getProfileRequest());
    }
  }, [dispatch, user, currentPath]);

  const accessDenied = getValue(ACCESS_DENIED);
  if (accessDenied) {
    goBack(ROUTES.ADMIN);
    removeItem(ACCESS_DENIED);
  }

  if (!user) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Header onMenuClicked={handleNavbar(true)} open={openNavbar} />
      <Navbar open={openNavbar} onClose={handleNavbar(false)} />
      <PageContainer component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <Outlet />
        </div>
      </PageContainer>
      <ConfirmDialog
        open={alert.show}
        title={alert.title}
        message={alert.message}
        cancelLabel={alert.cancelLabel}
        confirmLabel={alert.confirmLabel}
        onCancel={alert.onCancel}
        onConfirm={alert.onConfirm}
      />
    </Box>
  );
};

export default PrivateLayout;
