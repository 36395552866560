import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useProfile } from './useProfile';
import { AccountContainer, ProfileImage } from './styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const defaultImage =
  'https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg';

const Profile = () => {
  const { t } = useTranslation('profile');
  const theme = useTheme();
  const { anchorElem, profile, handleProfile, handleContextMenu, handleClose, handleLogout, handleSettingsClick } =
    useProfile();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <AccountContainer onClick={handleContextMenu}>
        <ProfileImage src={profile.image || defaultImage} alt="profile" />
      </AccountContainer>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElem}
        keepMounted
        open={!!anchorElem}
        onClose={handleClose}
        PaperProps={{ style: { width: 175, right: 0 } }}
        MenuListProps={{ style: { padding: 0 } }}>
        <MenuItem sx={{ padding: '10px 16px' }} onClick={handleProfile}>
          {t('profile')}
        </MenuItem>
        {isSmDown && (
          <MenuItem sx={{ padding: '10px 16px' }} onClick={handleSettingsClick}>
            {t('settings')}
          </MenuItem>
        )}
        <MenuItem sx={{ padding: '10px 16px' }} onClick={handleLogout}>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
