import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/documentDetail/documentDetail';
import { getDocument as getDocumentApi, exportDocument as exportDocumentApi } from 'src/services/documents';
import { DocumentDetailType, ExportDocumentRequestType } from 'src/types/documents';
import { downloadBlob } from 'src/utils/file';
import { errorController } from '../utils/errorController';
import i18next from 'i18next';
import './i18n';
import { globalActions } from 'src/reducers/global/global';

function* getDocument({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<DocumentDetailType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<DocumentDetailType>
> {
  try {
    const { status, data } = yield call(getDocumentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getDocumentSuccess(data));
    } else {
      yield put(actions.getDocumentError());
    }
  } catch (e) {
    yield put(actions.getDocumentError());
  }
}

function* exportDocument({
  payload,
}: PayloadAction<ExportDocumentRequestType>): Generator<
  CallEffect<AxiosResponse<BlobPart> | File | void> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<BlobPart>
> {
  try {
    const { status, data } = yield call(exportDocumentApi, payload);
    if (status >= 200 && status < 300 && data) {
      yield call(downloadBlob, `${payload.title}.docx`, data);
      yield put(actions.exportDocumentSuccess());
      yield put(globalActions.showSuccessSnackbar(i18next.t('documentDetailSaga:downloadSuccessfully')));
    } else {
      yield put(actions.exportDocumentError());
      yield errorController(i18next.t('documentDetailSaga:downloadError'), data);
    }
  } catch (e) {
    yield put(actions.exportDocumentError());
    yield errorController(i18next.t('documentDetailSaga:downloadError'), e);
  }
}

const documentDetailSaga: ForkEffect<never>[] = [
  takeLatest(actions.getDocumentRequest, getDocument),
  takeLatest(actions.exportDocumentRequest, exportDocument),
];

export default documentDetailSaga;
