import JsPDF from 'jspdf';

export const getBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const getFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const downloadFile = (file: File) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadBlob = (filename: string, blob: BlobPart, type = 'application/octet-stream') => {
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new File([blob], filename, { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

export const htmlStringToPdf = async (htmlString: string, filename: string) => {
  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'hidden';
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  document.body.appendChild(iframe);

  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

  if (iframeDoc) {
    const doc = new JsPDF({
      orientation: 'portrait',
      format: 'a4',
      unit: 'pt',
    });

    iframeDoc.body.style.width = '540px';
    iframeDoc.body.style.height = '100%';
    iframeDoc.body.style.padding = '0 8px 0 0';
    iframeDoc.body.style.margin = '0px';
    iframeDoc.body.style.boxSizing = 'border-box';
    iframeDoc.body.style.backgroundColor = 'white';
    iframeDoc.body.style.overflow = 'hidden';

    const container = document.createElement('div');
    container.style.fontSize = '10pt';
    container.style.width = '100%';
    container.style.textAlign = 'left';
    container.innerHTML = htmlString;
    iframeDoc.body.appendChild(container);

    const file: JsPDF = await new Promise(resolve => {
      doc.html(iframeDoc.body, {
        margin: 32,
        width: 600,
        autoPaging: 'text',
        async callback(doc) {
          resolve(doc);
        },
      });
    });

    file.save(filename);
  }

  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
};
