import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'courseModuleDetailSaga',
  {
    completedError: 'Ocurrió un error al marcar la unidad como terminada. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
