import { Typography } from '@mui/material';
import styled from 'styled-components';

export const OptionContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 10px 16px !important;
  min-height: auto;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.default};
`;

export const OptionContent = styled.div`
  flex: 1;
`;

export const OptionText = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
