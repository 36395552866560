import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/courseModuleDetail/courseModuleDetail';
import {
  getCourseModule as getCourseModuleApi,
  getCourseModulesProgress as getCourseModulesProgressApi,
  markModuleAsCompleted as markModuleAsCompletedApi,
} from 'src/services/courses';
import { CourseModuleType, GetCourseModuleRequest } from 'src/types/courses';
import './i18n';
import { errorController } from '../utils/errorController';
import i18next from 'i18next';

function* getCourseModule({
  payload,
}: PayloadAction<GetCourseModuleRequest>): Generator<
  CallEffect<AxiosResponse<CourseModuleType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseModuleType>
> {
  try {
    const { status, data } = yield call(getCourseModuleApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getCourseModuleSuccess(data));
    } else {
      yield put(actions.getCourseModuleError());
    }
  } catch (e) {
    yield put(actions.getCourseModuleError());
  }
}

function* getCourseModulesProgress({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<CourseModuleType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseModuleType[]>
> {
  try {
    const { status, data } = yield call(getCourseModulesProgressApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getCourseModulesProgressSuccess(data));
    } else {
      yield put(actions.getCourseModulesProgressError());
    }
  } catch (e) {
    yield put(actions.getCourseModulesProgressError());
  }
}

function* markModuleAsCompleted({
  payload,
}: PayloadAction<GetCourseModuleRequest>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(markModuleAsCompletedApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.markModuleAsCompletedSuccess(payload.id));
    } else {
      yield put(actions.markModuleAsCompletedError());
      yield errorController(i18next.t('courseModuleDetailSaga:completedError'), data);
    }
  } catch (e) {
    yield put(actions.markModuleAsCompletedError());
    yield errorController(i18next.t('courseModuleDetailSaga:completedError'), e);
  }
}

const courseModuleDetailSaga: ForkEffect<never>[] = [
  takeLatest(actions.getCourseModuleRequest, getCourseModule),
  takeLatest(actions.getCourseModulesProgressRequest, getCourseModulesProgress),
  takeLatest(actions.markModuleAsCompletedRequest, markModuleAsCompleted),
];

export default courseModuleDetailSaga;
