import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  CourseAccessStatus,
  CourseDetailStoreType,
  CourseDetailType,
  CourseModuleType,
  MyCourseType,
} from 'src/types/courses';

const initialState: CourseDetailStoreType = {
  course: null,
  accessStatus: CourseAccessStatus.Loading,
  modulesProgress: [],
};

const sliceName = 'courseDetail';

const courseDetailSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getCourseRequest: (state: CourseDetailStoreType, action: PayloadAction<number>) => {
      state.accessStatus = CourseAccessStatus.Loading;
    },
    getCourseSuccess: (state: CourseDetailStoreType, action: PayloadAction<CourseDetailType>) => {
      state.accessStatus = CourseAccessStatus.Allowed;
      state.course = action.payload;
    },
    getCourseError: (state: CourseDetailStoreType) => {
      state.accessStatus = CourseAccessStatus.Denied;
      state.course = null;
    },
    getCourseModulesProgressRequest: (state: CourseDetailStoreType, action: PayloadAction<number>) => {},
    getCourseModulesProgressSuccess: (state: CourseDetailStoreType, action: PayloadAction<CourseModuleType[]>) => {
      state.modulesProgress = action.payload;
    },
    getCourseModulesProgressError: (state: CourseDetailStoreType) => {
      state.modulesProgress = [];
    },
    enrollInCourseRequest: (state: CourseDetailStoreType, action: PayloadAction<number>) => {},
    enrollInCourseSuccess: (state: CourseDetailStoreType, action: PayloadAction<MyCourseType>) => {
      if (state.course) state.course.isEnrolled = true;
    },
    enrollInCourseError: (state: CourseDetailStoreType) => {},
  },
});

export const actions = courseDetailSlice.actions;

export const courseDetailStore = (state: RootState) => state.courseDetail;

export default courseDetailSlice.reducer;
