import { IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { MenuOutlined as MenuIcon, SettingsOutlined as SettingsIcon } from '@mui/icons-material';
import './components/Profile/i18n';
import { HeaderProps } from './types';
import Logo from '../Logo/Logo';
import Search from './components/Search/Search';
import User from './components/Profile/Profile';
import { ActionsContainer, AppBar, AppBarMobile, SearchContainer } from './styles';
import { useHeader } from './useHeader';
import { useTheme } from 'styled-components';

const Header = ({ open, onMenuClicked }: HeaderProps) => {
  const { isAdmin, handleSettingsClick } = useHeader();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const renderAppBarContent = () => (
    <Toolbar>
      <IconButton
        size="large"
        color="default"
        aria-label="menu"
        onClick={onMenuClicked}
        sx={isSmDown ? {} : { ...(open && { display: 'none' }) }}>
        <MenuIcon />
      </IconButton>
      <SearchContainer>
        <Logo version={isMdDown && open ? 'reduced' : 'auto'} />
        <Search />
      </SearchContainer>
      <ActionsContainer>
        {isAdmin && !isSmDown && (
          <IconButton size="large" color="default" aria-label="settings" onClick={handleSettingsClick}>
            <SettingsIcon />
          </IconButton>
        )}
        <User />
      </ActionsContainer>
    </Toolbar>
  );

  if (isSmDown) {
    return <AppBarMobile position="fixed">{renderAppBarContent()}</AppBarMobile>;
  }

  return (
    <AppBar open={open} position="fixed">
      {renderAppBarContent()}
    </AppBar>
  );
};

export default Header;
