import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  height: 180px;
  width: 100%;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div`
  padding: 16px;

  & > p {
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }
`;

export const CourseDescription = styled(Typography)`
  height: 72px;
  margin-top: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;
