import {
  CourseCategoryType,
  CourseDetailType,
  CourseModuleType,
  CourseType,
  CreateCourseModuleRequest,
  CreateCourseRequest,
  CreateModuleComponentRequest,
  DeleteCourseModuleRequest,
  DeleteModuleComponentRequest,
  GetCourseModuleRequest,
  ModuleComponentType,
  MyCourseType,
  UpdateCourseModuleOrderRequest,
  UpdateCourseModuleRequest,
  UpdateCourseRequest,
  UpdateModuleComponentOrderRequest,
  UpdateModuleComponentRequest,
} from 'src/types/courses';
import { get, post, put, remove, patch } from './api';
import { transformCourseToFormData, transformModuleComponentToFormData } from 'src/utils/courses';

export const getCourse = (id: number) => {
  return get<CourseDetailType>(`/courses/${id}`);
};

export const getCourses = () => {
  return get<CourseType[]>('/courses');
};

export const getMyCourses = () => {
  return get<MyCourseType[]>('/courses/me');
};

export const enrollInCourse = (id: number) => {
  return put<MyCourseType>(`/courses/${id}/register`, {});
};

export const getCourseCategories = () => {
  return get<CourseCategoryType[]>('/courses/categories');
};

export const createCourse = (request: CreateCourseRequest) => {
  const formData: FormData = transformCourseToFormData(request);
  return post<CourseType>('/courses', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const updateCourse = (request: UpdateCourseRequest) => {
  const { id, ...params } = request;
  const formData: FormData = transformCourseToFormData(params);
  return put<CourseType>(`/courses/${id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const getCourseModule = (request: GetCourseModuleRequest) => {
  const { courseId, id } = request;
  return get<CourseModuleType>(`/courses/${courseId}/modules/${id}`);
};

export const createCourseModule = (request: CreateCourseModuleRequest) => {
  const { courseId, title } = request;
  return post<CourseModuleType>(`/courses/${courseId}/modules`, { title });
};

export const updateCourseModule = (request: UpdateCourseModuleRequest) => {
  const { courseId, id, title } = request;
  return put<CourseModuleType>(`/courses/${courseId}/modules/${id}`, { title });
};

export const deleteCourseModule = (request: DeleteCourseModuleRequest) => {
  const { courseId, id } = request;
  return remove(`/courses/${courseId}/modules/${id}`);
};

export const updateCourseModuleOrder = (request: UpdateCourseModuleOrderRequest) => {
  const { id, courseId, ...params } = request;
  return patch(`/courses/${courseId}/modules/${id}`, params);
};

export const createModuleComponent = (request: CreateModuleComponentRequest) => {
  const { courseId, moduleId } = request;
  const formData: FormData = transformModuleComponentToFormData(request);
  return post<ModuleComponentType>(`/courses/${courseId}/modules/${moduleId}/components`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const updateModuleComponent = (request: UpdateModuleComponentRequest) => {
  const { courseId, moduleId, id } = request;
  const formData: FormData = transformModuleComponentToFormData(request);
  return put<ModuleComponentType>(`/courses/${courseId}/modules/${moduleId}/components/${id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteModuleComponent = (request: DeleteModuleComponentRequest) => {
  const { courseId, moduleId, id } = request;
  return remove(`/courses/${courseId}/modules/${moduleId}/components/${id}`);
};

export const updateModuleComponentOrder = (request: UpdateModuleComponentOrderRequest) => {
  const { id, courseId, ...params } = request;
  return patch(`/courses/${courseId}/modules/${params.moduleId}/components/${id}`, params);
};

export const getCourseModulesProgress = (courseId: number) => {
  return get<CourseModuleType[]>(`/courses/${courseId}/modules/me`);
};

export const markModuleAsCompleted = (params: GetCourseModuleRequest) => {
  const { id, courseId } = params;
  return put(`/courses/${courseId}/modules/${id}/completed`, {});
};
