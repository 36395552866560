import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ContentDetailStoreType,
  ContentDetailType,
  MediaViewerContentType,
  ViewerContentType,
} from 'src/types/contents';

const initialState: ContentDetailStoreType = {
  content: null,
  viewer: null,
  mediaViewer: null,
};

const sliceName = 'contentDetail';

const contentDetailSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getContentRequest: (state: ContentDetailStoreType, action: PayloadAction<number>) => {},
    getContentSuccess: (state: ContentDetailStoreType, action: PayloadAction<ContentDetailType>) => {
      state.content = action.payload;
    },
    getContentError: (state: ContentDetailStoreType) => {
      state.content = null;
    },
    openViewer: (state: ContentDetailStoreType, action: PayloadAction<ViewerContentType>) => {
      state.viewer = {
        open: true,
        ...action.payload,
      };
    },
    closeViewer: (state: ContentDetailStoreType) => {
      state.viewer = null;
    },
    openMediaViewer: (state: ContentDetailStoreType, action: PayloadAction<MediaViewerContentType>) => {
      state.mediaViewer = {
        open: true,
        ...action.payload,
      };
    },
    closeMediaViewer: (state: ContentDetailStoreType) => {
      state.mediaViewer = null;
    },
  },
});

export const actions = contentDetailSlice.actions;

export const contentDetailStore = (state: RootState) => state.contentDetail;

export default contentDetailSlice.reducer;
