import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStore } from 'src/reducers/auth/auth';
import ROUTES from 'src/routes/routes';
import { Background, Container } from './styles';
import BackgroundImage from 'src/assets/images/login_background.webp';

const PublicLayout = () => {
  const { user } = useSelector(authStore);

  if (user) {
    return <Navigate to={ROUTES.ADMIN} replace />;
  }

  return (
    <Container>
      <Background alt="login-background" src={BackgroundImage} />
      <Outlet />
    </Container>
  );
};

export default PublicLayout;
