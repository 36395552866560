import { Button, Typography } from '@mui/material';
import {
  Card,
  Image,
  ButtonsContainer,
  CardContent,
  ImageContainer,
  Hint,
  ProgressIndicator,
  DateContainer,
  Content,
} from './styles';
import { MyCourseCardProps } from './types';
import { useTranslation } from 'react-i18next';
import './i18n';
import { capitalizeFirstLetter } from 'src/utils/strings';
import { ArrowForwardOutlined, CheckOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

const MyCourseCard = ({ myCourse, onClick }: MyCourseCardProps) => {
  const { t } = useTranslation('myCourseCard');

  const handleClick = () => onClick(myCourse);
  const date = myCourse.completed ? myCourse.endDate : myCourse.startDate;

  return (
    <Card>
      <ImageContainer>
        <Image src={myCourse.image} alt={myCourse.title} />
      </ImageContainer>
      <CardContent>
        <Content>
          <Typography variant="body2">{capitalizeFirstLetter(myCourse.category)}</Typography>
          <Typography variant="h6" fontWeight={600}>
            {myCourse.title}
          </Typography>
          <DateContainer>
            {date && (
              <>
                <Typography variant="body2">{myCourse.completed ? t('endDate') : t('startDate')}</Typography>
                <Typography variant="body2" fontWeight={600}>
                  {dayjs(date).format('DD/MM/YYYY')}
                </Typography>
              </>
            )}
          </DateContainer>
          <Hint>
            {myCourse.completed
              ? t('completed')
              : myCourse.totalModulesCompleted === 0
              ? t('motivation')
              : t('progress', { percentage: myCourse.percentageCompleted })}
          </Hint>
          <ProgressIndicator percentage={myCourse.percentageCompleted} />
        </Content>
        <ButtonsContainer>
          <Button
            sx={{ gap: 1 }}
            variant="contained"
            onClick={handleClick}
            color={myCourse.completed ? 'success' : 'primary'}>
            {myCourse.completed ? t('finished') : myCourse.totalModulesCompleted === 0 ? t('start') : t('continue')}
            {myCourse.completed ? <CheckOutlined /> : <ArrowForwardOutlined />}
          </Button>
        </ButtonsContainer>
      </CardContent>
    </Card>
  );
};

export default MyCourseCard;
