import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'headerSearch',
  {
    placeholder: 'Buscar...',
    resourceType: {
      document: 'Documento',
      course: 'Curso',
      content: 'Contenido',
      events: 'Evento',
      news: 'Noticia',
    },
    noOptionsText: 'Sin opciones',
  },
  true,
  false,
);

export default i18n;
