import { useState, useEffect } from 'react';

const useResponsive = (breakpoint = 576) => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(0);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth <= breakpoint) setIsMobile(true);
    else setIsMobile(false);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { width, isMobile };
};

export default useResponsive;
