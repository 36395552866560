import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'courseDetailSaga',
  {
    enrollError: 'Ocurrió un error al apuntarse el curso. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
