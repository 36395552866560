import { ComponentType } from './contents';

export const COURSE_LEVELS = ['basic', 'medium', 'advanced', 'expert'] as const;
export type CourseLevelsType = (typeof COURSE_LEVELS)[number];
export type CourseComponentType = ComponentType;

export enum CourseEditorStep {
  Course = 0,
  Modules,
}

export enum CourseListFilter {
  All = 0,
  MyCourses,
}

export enum CourseAccessStatus {
  Loading = 0,
  Denied,
  Allowed,
}

export type CourseCategoryType = string;

export type CourseListStoreType = {
  courses: CourseType[];
  myCourses: MyCourseType[];
  filter: CourseListFilter;
  categories: CourseCategoryType[];
  selectedLevels: CourseLevelsType[];
  selectedCategory: CourseCategoryType | null;
};

export type CourseDetailStoreType = {
  accessStatus: CourseAccessStatus;
  course: CourseDetailType | null;
  modulesProgress: CourseModuleType[];
};

export type CourseModuleDetailStoreType = {
  module: CourseModuleType | null;
  modulesProgress: CourseModuleType[];
};

export type CourseEditorStoreType = {
  step: CourseEditorStep;
  course: CourseDetailType | null;
  modulesBackup: CourseModuleType[];
  currentModule: CourseModuleType | null;
  isDeleteModuleDialogVisible: boolean;
  isModuleModalVisible: boolean;
  currentComponent: ModuleComponentType | null;
  isDeleteComponentDialogVisible: boolean;
  isComponentModalVisible: boolean;
  categories: CourseCategoryType[];
};

export interface CourseType {
  id: number;
  title: string;
  category: CourseCategoryType;
  level: CourseLevelsType;
  description: string;
  startDate: string;
  endDate: string;
  image: string;
  video: string;
  orderedModules: boolean;
}

export interface MyCourseType extends CourseType {
  completed: boolean;
  totalModules: number;
  totalModulesCompleted: number;
  percentageCompleted: number;
}

export interface CourseDetailType extends CourseType {
  students: number;
  isEnrolled: boolean;
  modules: CourseModuleType[];
}

export interface CreateCourseRequest extends Omit<CourseType, 'image' | 'video' | 'id'> {
  image?: File;
  video?: File;
}

export interface UpdateCourseRequest extends CreateCourseRequest {
  id: number;
}

export interface CourseModuleType {
  id: number;
  title: string;
  order: number;
  completed?: boolean;
  components: ModuleComponentType[];
}

export interface ModuleComponentType {
  id: number;
  title: string;
  type: CourseComponentType;
  textContent?: string;
  file?: string;
  link?: string;
  order: number;
  moduleId?: number;
}

export interface GetCourseModuleRequest {
  courseId: number;
  id: number;
}

export interface CreateCourseModuleRequest {
  title: string;
  courseId: number;
}

export interface UpdateCourseModuleRequest extends CreateCourseModuleRequest {
  id: number;
}

export interface DeleteCourseModuleRequest extends Omit<UpdateCourseModuleRequest, 'title'> {}

export interface UpdateCourseModuleOrderRequest {
  id: number;
  order: number;
  courseId: number;
}
export interface CreateModuleComponentRequest extends Omit<ModuleComponentType, 'id' | 'file' | 'order'> {
  file?: File;
  courseId: number;
  moduleId: number;
}

export interface UpdateModuleComponentRequest extends CreateModuleComponentRequest {
  id: number;
}

export interface DeleteModuleComponentRequest {
  id: number;
  courseId: number;
  moduleId: number;
}

export interface UpdateModuleComponentOrderRequest {
  id: number;
  order: number;
  courseId: number;
  moduleId: number;
}
