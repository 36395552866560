import { NewsDetailsType, ListNewsDetailsType } from 'src/types/newsList';
import { get, post, put, remove } from './api';

const createFormData = (news: NewsDetailsType): FormData => {
  const formData: FormData = new FormData();

  formData.append('title', news.title);
  news.link && formData.append('link', news.link);
  news.image && formData.append('image', news.image);

  return formData;
};

export const getNews = async () => {
  return get<ListNewsDetailsType[]>('/news');
};

export const getNewsById = async (id: ListNewsDetailsType['id']) => {
  return get<ListNewsDetailsType>(`/news/${id}`);
};

export const createNews = (news: NewsDetailsType) => {
  const formData = createFormData(news);
  return post<NewsDetailsType>('/news', formData, { headers: { 'content-type': 'multipart/form-data' } });
};

export const updateNews = (news: NewsDetailsType) => {
  const formData = createFormData(news);
  return put<NewsDetailsType>(`/news/${news.id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteNews = (newsId: NewsDetailsType['id']) => {
  return remove<NewsDetailsType>(`/news/${newsId}`);
};
