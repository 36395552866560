import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ContentDetailType,
  ContentEditorStoreType,
  ContentHeaderType,
  CreateComponentRequest,
  CreateContentHeaderRequest,
  DeleteComponentRequest,
  DeleteContentHeaderRequest,
  HeaderComponentType,
  UpdateComponentRequest,
  UpdateContentHeaderOrderRequest,
  UpdateContentHeaderRequest,
  UpdateHeaderComponentOrderRequest,
} from 'src/types/contents';
import { getComponentById } from 'src/utils/contents';

const initialState: ContentEditorStoreType = {
  content: null,
  headersBackup: [],
  currentHeader: null,
  isDeleteHeaderDialogVisible: false,
  isHeaderModalVisible: false,
  currentComponent: null,
  isDeleteComponentDialogVisible: false,
  isComponentModalVisible: false,
};

const sliceName = 'contentEditor';

const contentEditorSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getContentRequest: (state: ContentEditorStoreType, action: PayloadAction<number>) => {},
    getContentSuccess: (state: ContentEditorStoreType, action: PayloadAction<ContentDetailType>) => {
      state.content = action.payload;
    },
    getContentError: (state: ContentEditorStoreType) => {
      state.content = null;
    },
    createContentHeaderRequest: (
      state: ContentEditorStoreType,
      action: PayloadAction<CreateContentHeaderRequest>,
    ) => {},
    createContentHeaderSuccess: (state: ContentEditorStoreType, action: PayloadAction<ContentHeaderType>) => {
      if (state.content) state.content.headers = [...(state.content.headers || []), action.payload];
      state.isHeaderModalVisible = false;
    },
    createContentHeaderError: (state: ContentEditorStoreType) => {},
    updateContentHeaderRequest: (
      state: ContentEditorStoreType,
      action: PayloadAction<UpdateContentHeaderRequest>,
    ) => {},
    updateContentHeaderSuccess: (state: ContentEditorStoreType, action: PayloadAction<ContentHeaderType>) => {
      if (state.content) {
        const headerIdx = state.content.headers.findIndex(header => header.id === action.payload.id);
        if (headerIdx >= 0) state.content.headers[headerIdx] = action.payload;
      }
      state.isHeaderModalVisible = false;
      state.currentHeader = null;
    },
    updateContentHeaderError: (state: ContentEditorStoreType) => {},
    deleteContentHeaderRequest: (
      state: ContentEditorStoreType,
      action: PayloadAction<DeleteContentHeaderRequest>,
    ) => {},
    deleteContentHeaderSuccess: (state: ContentEditorStoreType, action: PayloadAction<DeleteContentHeaderRequest>) => {
      if (state.content) {
        state.content.headers = state.content.headers.filter(header => header.id !== action.payload.id);
      }
      state.isDeleteHeaderDialogVisible = false;
      state.currentHeader = null;
    },
    deleteContentHeaderError: (state: ContentEditorStoreType) => {},
    updateContentHeaderOrder: (
      state: ContentEditorStoreType,
      action: PayloadAction<UpdateContentHeaderOrderRequest>,
    ) => {},
    updateContentHeaderOrderSuccess: (state: ContentEditorStoreType) => {},
    updateContentHeaderOrderError: (state: ContentEditorStoreType) => {},
    createComponentRequest: (state: ContentEditorStoreType, action: PayloadAction<CreateComponentRequest>) => {},
    createComponentSuccess: (state: ContentEditorStoreType, action: PayloadAction<HeaderComponentType>) => {
      if (state.content && action.payload.headerId) {
        const headerIdx = state.content.headers.findIndex(header => header.id === action.payload.headerId);
        if (headerIdx >= 0)
          state.content.headers[headerIdx].components = [
            ...(state.content.headers[headerIdx].components || []),
            action.payload,
          ];
      }
      state.isComponentModalVisible = false;
    },
    createComponentError: (state: ContentEditorStoreType) => {},
    updateComponentRequest: (state: ContentEditorStoreType, action: PayloadAction<UpdateComponentRequest>) => {},
    updateComponentSuccess: (state: ContentEditorStoreType, action: PayloadAction<HeaderComponentType>) => {
      if (state.content && action.payload.headerId) {
        const headerIdx = state.content.headers.findIndex(header => header.id === action.payload.headerId);
        if (headerIdx >= 0) {
          const componentIdx = state.content.headers[headerIdx].components.findIndex(
            component => component.id === action.payload.id,
          );
          if (componentIdx >= 0) {
            state.content.headers[headerIdx].components[componentIdx] = action.payload;
          }
        }
      }
      state.isComponentModalVisible = false;
      state.currentComponent = null;
      state.currentHeader = null;
    },
    updateComponentError: (state: ContentEditorStoreType) => {},
    deleteComponentRequest: (state: ContentEditorStoreType, action: PayloadAction<DeleteComponentRequest>) => {},
    deleteComponentSuccess: (state: ContentEditorStoreType, action: PayloadAction<DeleteComponentRequest>) => {
      if (state.content && action.payload.headerId) {
        const headerIdx = state.content.headers.findIndex(header => header.id === action.payload.headerId);
        if (headerIdx >= 0) {
          state.content.headers[headerIdx].components = state.content.headers[headerIdx].components.filter(
            component => component.id !== action.payload.id,
          );
        }
      }
      state.isDeleteComponentDialogVisible = false;
      state.currentComponent = null;
      state.currentHeader = null;
    },
    deleteComponentError: (state: ContentEditorStoreType) => {},
    updateHeaderComponentOrder: (
      state: ContentEditorStoreType,
      action: PayloadAction<UpdateHeaderComponentOrderRequest>,
    ) => {
      const { headerId, id, order } = action.payload;
      const component = getComponentById(state.content, id);
      if (component && state.content) {
        state.headersBackup = JSON.parse(JSON.stringify(state.content.headers));

        const headers = [...state.content.headers].reduce<ContentHeaderType[]>((acc, curr) => {
          const components = curr.components;
          const componentIdx = curr.components.findIndex(c => c.id === id);
          if (componentIdx >= 0) components.splice(componentIdx, 1);
          if (curr.id === headerId) components.splice(order, 0, component);
          return [...acc, { ...curr, components }];
        }, []);

        state.content.headers = headers;
      }
    },
    updateHeaderComponentOrderSuccess: (state: ContentEditorStoreType) => {
      state.headersBackup = [];
    },
    updateHeaderComponentOrderError: (state: ContentEditorStoreType) => {
      if (state.content) state.content.headers = state.headersBackup;
      state.headersBackup = [];
    },
    toggleDeleteHeaderDialogVisibility: (state: ContentEditorStoreType, action: PayloadAction<boolean>) => {
      state.isDeleteHeaderDialogVisible = action.payload;
    },
    toggleDeleteComponentDialogVisibility: (state: ContentEditorStoreType, action: PayloadAction<boolean>) => {
      state.isDeleteComponentDialogVisible = action.payload;
    },
    toggleHeaderModalVisibility: (state: ContentEditorStoreType, action: PayloadAction<boolean>) => {
      state.isHeaderModalVisible = action.payload;
    },
    toggleComponentModalVisibility: (state: ContentEditorStoreType, action: PayloadAction<boolean>) => {
      state.isComponentModalVisible = action.payload;
    },
    setCurrentHeader: (state: ContentEditorStoreType, action: PayloadAction<ContentHeaderType | null>) => {
      state.currentHeader = action.payload;
    },
    setCurrentComponent: (state: ContentEditorStoreType, action: PayloadAction<HeaderComponentType | null>) => {
      state.currentComponent = action.payload;
    },
  },
});

export const actions = contentEditorSlice.actions;

export const contentEditorStore = (state: RootState) => state.contentEditor;

export default contentEditorSlice.reducer;

export const selectComponentsByHeaderId = createSelector(
  (state: RootState) => state.contentEditor,
  ({ content }) =>
    content?.headers?.reduce<{ [k: string]: HeaderComponentType[] }>(
      (acc, curr) => ({
        ...acc,
        [`header-${curr.id}`]: curr.components?.map(c => ({ ...c, headerId: curr.id })) || [],
      }),
      {},
    ),
);

export const selectHeadersById = createSelector(
  (state: RootState) => state.contentEditor,
  ({ content }) =>
    content?.headers?.reduce<{ [k: string]: ContentHeaderType }>(
      (acc, curr) => ({ ...acc, [`header-${curr.id}`]: curr }),
      {},
    ),
);
