import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/eventList/eventList';
import {
  getEvents as getEventsApi,
  createEvent as createEventApi,
  updateEvent as updateEventApi,
  deleteEvent as deleteEventApi,
} from 'src/services/events';
import './i18n';
import i18next from 'i18next';
import { EventDetailsType, ListEventDetailsType } from 'src/types/eventList';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';

function* getEvents(): Generator<
  CallEffect<AxiosResponse<ListEventDetailsType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ListEventDetailsType[]>
> {
  try {
    const { status, data } = yield call(getEventsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getEventsSuccess(data));
    } else {
      yield put(actions.getEventsError());
    }
  } catch (e) {
    yield put(actions.getEventsError());
  }
}

function* createEvent({
  payload,
}: PayloadAction<EventDetailsType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createEventApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createEventSuccess(data));
      yield put(actions.setOpen(false));
      yield put(globalActions.showSuccessSnackbar(i18next.t('eventsSaga:createSuccessfully')));
    } else {
      yield put(actions.createEventError());
      yield errorController(i18next.t('eventsSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createEventError());
    yield errorController(i18next.t('eventsSaga:createError'), e);
  }
}

function* updateEvent({
  payload,
}: PayloadAction<EventDetailsType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateEventApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateEventSuccess(data));
      yield put(actions.setOpen(false));
      yield put(globalActions.showSuccessSnackbar(i18next.t('eventsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateEventError());
      yield errorController(i18next.t('eventsSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateEventError());
    yield errorController(i18next.t('eventsSaga:updateError'), e);
  }
}

function* deleteEvent({
  payload,
}: PayloadAction<EventDetailsType['id']>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteEventApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteEventSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('eventsSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteEventError());
      yield errorController(i18next.t('eventsSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteEventError());
    yield errorController(i18next.t('eventsSaga:deleteError'), e);
  }
}

const eventsSaga: ForkEffect<never>[] = [
  takeLatest(actions.getEventsRequest, getEvents),
  takeLatest(actions.createEventRequest, createEvent),
  takeLatest(actions.updateEventRequest, updateEvent),
  takeLatest(actions.deleteEventRequest, deleteEvent),
];

export default eventsSaga;
