import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from './components/Toolbar';
import { EditorContainer, ErrorMessage } from './styles';
import { RichTextEditorProps } from './types';

const RichTextEditor = ({ placeholder, value, error, onChange }: RichTextEditorProps) => {
  return (
    <EditorContainer error={!!error}>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
      />
      {error && (
        <ErrorMessage variant="body2" color="red">
          {error}
        </ErrorMessage>
      )}
    </EditorContainer>
  );
};

export default RichTextEditor;
