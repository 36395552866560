import styled, { keyframes } from 'styled-components';

const IndeterminateAnim = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    lefT: 100%;
    right: -90%;
  }
`;

const IndeterminateShortAnim = keyframes`
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    lefT: 107%;
    right: -8%;
  }
`;

export const LoaderContainer = styled.div`
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1500;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

export const LoaderBar = styled.div`
  height: 2px;
  position: fixed;
  top: 55px;
  width: 100%;
`;

export const Indeterminate = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:before {
    animation: ${IndeterminateAnim} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    background-color: inherit;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }

  &:after {
    animation: ${IndeterminateShortAnim} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
    background-color: inherit;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }
`;
