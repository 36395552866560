import { MouseEvent } from 'react';
import { Close } from '@mui/icons-material';
import { Select, Box, FormControl, InputLabel, MenuItem, FormHelperText, Checkbox } from '@mui/material';
import { InputSelectProps, ValueType } from './types';
import { iconStyles, boxStyles, InputText } from './styles';

export const InputSelect = ({
  sx,
  sxForm,
  value,
  label,
  labelId,
  name,
  options,
  disabled,
  multiple,
  error,
  showClearIcon = true,
  includeError = true,
  onChange,
  onClear,
}: InputSelectProps) => {
  const getValue = (value: ValueType | ValueType[]) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      const opts = options.filter(o => value.includes(o.id?.toString() || ''));
      return opts.map(opt => opt.name).join(', ') || '';
    } else {
      const option = options.find(o => o.id?.toString() === value?.toString());
      return option?.name || '';
    }
  };

  const handleClear = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (onClear) onClear();
  };

  return (
    <FormControl variant="standard" sx={sxForm}>
      <InputLabel id={labelId} error={!!error}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        sx={sx}
        multiple={multiple}
        variant="standard"
        value={value}
        name={name}
        disabled={disabled}
        error={!!error}
        renderValue={value => (
          <Box sx={boxStyles}>
            <InputText>{getValue(value)}</InputText>
            {value && !disabled && options.length > 0 && showClearIcon && (
              <Close color="action" sx={iconStyles} onMouseDown={handleClear} />
            )}
          </Box>
        )}
        onChange={e => onChange(e.target.value)}>
        {options.map(opt => (
          <MenuItem
            key={opt?.id?.toString()}
            disabled={opt.disabled}
            value={opt?.id?.toString()}
            sx={{ py: multiple ? 0 : 1 }}>
            {multiple && <Checkbox checked={(value as ValueType[]).indexOf(opt?.id || '') >= 0} />}
            {opt.name}
          </MenuItem>
        ))}
      </Select>
      {includeError && <FormHelperText error>{error || ' '}</FormHelperText>}
    </FormControl>
  );
};
