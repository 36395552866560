import { RoleEnum } from 'src/types/auth';
import { MenuItemType } from './types';
import ROUTES from 'src/routes/routes';

const MENU: MenuItemType[] = [
  {
    key: 'home',
    icon: 'home',
    path: ROUTES.ADMIN,
    roles: [RoleEnum.ASSOCIATED, RoleEnum.ADMIN],
  },
  {
    key: 'school',
    icon: 'school',
    path: ROUTES.COURSES,
    roles: [RoleEnum.ASSOCIATED, RoleEnum.ADMIN],
  },
  {
    key: 'events',
    icon: 'event',
    path: ROUTES.EVENTS,
    roles: [RoleEnum.ADMIN],
  },
  {
    key: 'news',
    icon: 'feed',
    path: ROUTES.NEWS,
    roles: [RoleEnum.ADMIN],
  },
  {
    key: 'documents',
    icon: 'description',
    path: ROUTES.DOCUMENTS,
    roles: [RoleEnum.ADMIN, RoleEnum.ASSOCIATED],
  },
  {
    key: 'clients',
    icon: 'people',
    path: ROUTES.CLIENTS,
    roles: [RoleEnum.ADMIN],
  },
  {
    key: 'content-manager',
    icon: 'info',
    path: ROUTES.CONTENT_MANAGER,
    roles: [RoleEnum.ADMIN],
  },
];

export default MENU;
