import styled from 'styled-components';
import { SCREEN_MAX_WIDTH } from 'src/utils/constants';
import { Box } from '@mui/material';

export const PageContainer = styled(Box)`
  padding: 50px;
  margin-top: var(--header-height, 100);
  height: calc(100vh - var(--header-height, 100));
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.background.default};

  & > div {
    width: 100%;
    max-width: ${SCREEN_MAX_WIDTH}px;
    margin: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey[400]};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.grey[500]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    padding: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 32px 16px;
  }
`;
