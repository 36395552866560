import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const DragIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

const ScrollableStyles = css`
  ul {
    overflow-y: auto;
  }
`;

const PlaceholderStyles = css`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);

  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
  }
`;

const HoverStyles = css`
  background-color: ${({ theme }) => theme.palette.divider};
`;

const HorizontalStyles = css`
  width: 100%;

  ul {
    grid-auto-flow: column;
  }
`;

const UnstyledStyles = css`
  overflow: visible;
  background-color: transparent !important;
  border: none !important;
`;

const ShadowStyles = css`
  box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
`;

export const Wrapper = styled(Accordion)<{
  horizontal?: boolean;
  hover?: boolean;
  scrollable?: boolean;
  hasPlaceholder?: boolean;
  unstyled?: boolean;
  shadow?: boolean;
  collapsable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  border-radius: 8px !important;
  min-height: ${({ collapsable }) => (collapsable ? 'auto' : '64px')};
  transition: background-color 350ms ease;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1em;
  box-shadow: none;

  margin-top: 16px !important;
  &.Mui-expanded {
    margin-top: 16px;
    margin-bottom: 0;
  }

  ul {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ${({ scrollable }) => scrollable && ScrollableStyles};
  ${({ hasPlaceholder }) => hasPlaceholder && PlaceholderStyles};
  ${({ hover }) => hover && HoverStyles};
  ${({ unstyled }) => unstyled && UnstyledStyles};
  ${({ horizontal }) => horizontal && HorizontalStyles};
  ${({ shadow }) => shadow && ShadowStyles};

  &:focus-visible {
    border-color: transparent;
    box-shadow:
      0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Actions = styled.div`
  display: flex;
`;

export const Header = styled.div<{ collapsable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ collapsable }) => (collapsable ? '8px 0' : '8px 32px')};
  min-height: 64px;
`;

export const HeaderContent = styled.div`
  flex: 1;
`;

export const AccordionHeader = styled(AccordionSummary)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  min-height: 64px;
  height: 64px;
  padding: 0 32px;
  margin: 0;
  gap: 8px;

  &.Mui-expanded {
    min-height: 64px;
    height: 64px;
    padding: 0 32px;
  }

  & > div {
    margin: 0 !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0 16px;

    &.Mui-expanded {
      padding: 0 16px;
    }
  }
`;

export const AccordionContent = styled(AccordionDetails)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 16px 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 16px;
  }
`;
