import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'contentsSaga',
  {
    createSuccessfully: 'El contenido fue creado exitosamente',
    updateSuccessfully: 'El contenido fue editado exitosamente',
    deleteSuccessfully: 'El contenido fue eliminado exitosamente',
    createError: 'Ocurrió un error al crear el contenido. Revise los parámetros y vuelva a intentarlo',
    updateError: 'Ocurrió un error al editar el contenido. Revise los parámetros y vuelva a intentarlo',
    updateOrderError: 'Ocurrió un error al ordenar los contenido. Vuelva a intentarlo en unos minutos',
    deleteError: 'Ocurrió un error al eliminar el contenido. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
