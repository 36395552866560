import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'newsSaga',
  {
    createSuccessfully: 'La noticia fue creada exitosamente',
    updateSuccessfully: 'La noticia fue editada exitosamente',
    deleteSuccessfully: 'La noticia fue eliminada exitosamente',
    createError: 'Ocurrió un error al crear la noticia. Revise los parámetros y vuelva a intentarlo',
    updateError: 'Ocurrió un error al editar la noticia. Revise los parámetros y vuelva a intentarlo',
    deleteError: 'Ocurrió un error al eliminar la noticia. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
