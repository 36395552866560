import dayjs from 'dayjs';
import { CourseFormType } from 'src/pages/CourseEditor/components/GeneralEditor/schema';
import {
  CourseCategoryType,
  CourseDetailType,
  CourseLevelsType,
  CourseType,
  CreateCourseRequest,
  CreateModuleComponentRequest,
  ModuleComponentType,
  MyCourseType,
  UpdateCourseRequest,
  UpdateModuleComponentRequest,
} from 'src/types/courses';

export const filterCoursesByLevelAndCategory = (
  courses: CourseType[] | MyCourseType[],
  levels: CourseLevelsType[],
  category: CourseCategoryType | null,
) => {
  return courses.filter(
    c => (!category || c.category === category) && (levels.length === 0 || levels.includes(c.level)),
  );
};

export const transformCourseToFormData = (course: CreateCourseRequest | UpdateCourseRequest) => {
  const formData: FormData = new FormData();
  formData.append('title', course.title);
  formData.append('category', course.category);
  formData.append('level', course.level);
  formData.append('description', course.description);
  formData.append('startDate', course.startDate);
  formData.append('endDate', course.endDate);
  formData.append('orderedModules', course.orderedModules ? '1' : '0');
  if (course.image) formData.append('image', course.image);
  if (course.video) formData.append('video', course.video);
  return formData;
};

export const transformModuleComponentToFormData = (
  component: CreateModuleComponentRequest | UpdateModuleComponentRequest,
) => {
  const formData: FormData = new FormData();
  formData.append('title', component.title);
  formData.append('type', component.type);
  if (component.link) formData.append('link', component.link);
  if (component.textContent) formData.append('textContent', component.textContent);
  if (component.file != null) formData.append('file', component.file);
  return formData;
};

export const getComponentById = (course: CourseDetailType | null, componentId: number) => {
  if (!course) return null;
  return course.modules
    .reduce<ModuleComponentType[]>((acc, curr) => [...acc, ...curr.components], [])
    .find(c => c.id === componentId);
};

export const mapFormToApiCreateRequest = (form: CourseFormType): CreateCourseRequest => {
  return {
    ...form,
    level: form.level as CourseLevelsType,
    startDate: form.startDate ? dayjs(form.startDate).format('YYYY-MM-DD') : '',
    endDate: form.endDate ? dayjs(form.endDate).format('YYYY-MM-DD') : '',
    orderedModules: !form.unorderedModules,
  };
};

export const mapFormToApiUpdateRequest = (id: number, form: CourseFormType): UpdateCourseRequest => {
  return {
    ...form,
    id,
    level: form.level as CourseLevelsType,
    startDate: form.startDate ? dayjs(form.startDate).format('YYYY-MM-DD') : '',
    endDate: form.endDate ? dayjs(form.endDate).format('YYYY-MM-DD') : '',
    orderedModules: !form.unorderedModules,
  };
};
