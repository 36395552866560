const ROUTES = {
  INDEX: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  ADMIN: '/admin',
  CONTENT_MANAGER: '/contents',
  CONTENT_DETAIL: '/contents/:id/details',
  CONTENTS: '/contents/:id/editors',
  EVENTS: '/events',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  NEWS: '/news',
  CLIENTS: '/clients',
  CLIENT_DETAIL: '/clients/:id/',
  COURSES: '/courses',
  COURSE_EDITOR: '/courses/:id/editor',
  COURSE_DETAIL: '/courses/:id/details',
  COURSE_MODULE_DETAIL: '/courses/:courseId/modules/:id/details',
  DOCUMENTS: '/documents',
  DOCUMENT_DETAIL: '/documents/:id/:mode/',
};

export default ROUTES;
