import { useContext } from 'react';
import { LogoContainer, LogoImg } from './styles';
import LogoOneClik from 'src/assets/logo.png';
import LogoOneClikReduced from 'src/assets/logo_small.png';
import { ThemeContext } from 'src/contexts/ThemeContext/ThemeContext';
import { LogoProps, LogoVersion } from './types';
import useResponsive from 'src/hooks/useResponsive';

const Logo = ({ size = 'small', version = 'full', className }: LogoProps) => {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useResponsive();

  const logo: { [k in LogoVersion]: string } = {
    reduced: theme === 'dark' ? LogoOneClikReduced : LogoOneClikReduced,
    full: theme === 'dark' ? LogoOneClik : LogoOneClik,
    auto: isMobile
      ? theme === 'dark'
        ? LogoOneClikReduced
        : LogoOneClikReduced
      : theme === 'dark'
      ? LogoOneClik
      : LogoOneClik,
  };

  return (
    <LogoContainer href={'/'} size={size} className={['logo', className].join(' ')}>
      <LogoImg src={logo[version]} alt="logo" />
    </LogoContainer>
  );
};

export default Logo;
