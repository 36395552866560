import { useSortable } from '@dnd-kit/sortable';
import { SortableItemProps } from './types';
import { Item } from '../Item/Item';
import { useEffect, useState } from 'react';

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

const SortableItem = ({
  disabled,
  id,
  index,
  handle,
  item,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  overrideItem,
  onEdit,
  onRemove,
}: SortableItemProps) => {
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      onEdit={onEdit}
      onRemove={onRemove}
      item={item}
      overrideItem={overrideItem}
    />
  );
};

export default SortableItem;
