import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'documentDetailSaga',
  {
    downloadSuccessfully: 'El documento se exportó exitosamente',
    downloadError: 'Ocurrió un error al descargar el documento. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
