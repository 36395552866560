import styled from 'styled-components';
import { LogoSize } from './types';

export const LogoContainer = styled.a<{ size?: LogoSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: ${({ size }) => (size === 'small' ? 60 : 80)}px;
  min-width: ${({ size }) => (size === 'small' ? 60 : 80)}px;
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
