import {
  DocumentByCategoryType,
  DocumentCategoryType,
  DocumentDetailType,
  ExportDocumentRequestType,
  UploadDocumentRequestType,
} from 'src/types/documents';
import { get, post, put } from './api';
import { transformDocumentToFormData } from 'src/utils/documents';

export const getDocument = (id: number) => {
  return get<DocumentDetailType>(`/documents/${id}`);
};

export const getDocuments = () => {
  return get<DocumentByCategoryType[]>('/documents/me');
};

export const getDocumentCategories = () => {
  return get<DocumentCategoryType[]>(`/documents/categories`);
};

export const uploadDocument = (params: UploadDocumentRequestType) => {
  const formData = transformDocumentToFormData(params);
  return post<DocumentDetailType>('/documents', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const exportDocument = (request: ExportDocumentRequestType) => {
  const { id, variables } = request;
  return put<BlobPart>(`/documents/${id}/export`, variables, {
    headers: { 'Content-Disposition': 'attachment', 'content-type': 'application/json', accept: 'application/json' },
    responseType: 'blob',
  });
};
