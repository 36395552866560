import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/profile/profile';
import {
  getProfile as getProfileApi,
  updateProfile as updateProfileApi,
  updateProfileImage as updateProfileImageApi,
} from 'src/services/profile';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { ProfileApiResponseType, ProfileImageType, ProfileType } from 'src/types/profile';
import { globalActions } from 'src/reducers/global/global';

function* getProfile(): Generator<
  CallEffect<AxiosResponse<ProfileApiResponseType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ProfileApiResponseType>
> {
  try {
    const { status, data } = yield call(getProfileApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getProfileSuccess(data));
    } else {
      yield put(actions.getProfileError());
    }
  } catch (e) {
    yield put(actions.getProfileError());
  }
}

function* updateProfile({
  payload,
}: PayloadAction<ProfileType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateProfileApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateProfileSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('profileSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateProfileError());
      yield errorController(i18next.t('profileSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateProfileError());
    yield errorController(i18next.t('profileSaga:updateError'), e);
  }
}

function* updateProfileImage({
  payload,
}: PayloadAction<ProfileImageType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateProfileImageApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateProfileImageSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('profileSaga:updateImageSuccessfully')));
    } else {
      yield put(actions.updateProfileImageError());
      yield errorController(i18next.t('profileSaga:updateImageError'), data);
    }
  } catch (e) {
    yield put(actions.updateProfileImageError());
    yield errorController(i18next.t('profileSaga:updateImageError'), e);
  }
}

const profileSaga: ForkEffect<never>[] = [
  takeLatest(actions.getProfileRequest, getProfile),
  takeLatest(actions.updateProfileRequest, updateProfile),
  takeLatest(actions.updateProfileImageRequest, updateProfileImage),
];

export default profileSaga;
