import { IconButton } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';

const Pop = keyframes` 
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }`;

const FadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

export const DragIconButton = styled(IconButton)`
  margin-left: 16px;
  color: ${({ theme }) => theme.palette.grey[400]};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-left: 0;
  }
`;

const FadeInStyles = css`
  animation: ${FadeIn} 500ms ease;
`;

const DragOverlayStyles = css`
  --scale: 1;
  --box-shadow: var(--box-shadow);
  --box-shadow-picked-up: var(--box-shadow-border), -1px 0 15px 0 rgba(34, 33, 81, 0.01),
    0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  z-index: 999;
`;

const ContentDragOverlayStyles = css`
  cursor: inherit;
  animation: ${Pop} 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  transform: scale(var(--scale));
  box-shadow: var(--box-shadow-picked-up);
  opacity: 1;
`;

const DisabledStyles = css`
  color: ${({ theme }) => theme.palette.grey[500]};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  &:focus {
    box-shadow:
      0 0px 4px 1px rgba(0, 0, 0, 0.1),
      var(--box-shadow);
  }
  cursor: not-allowed;
`;

const DraggingStyles = css`
  opacity: var(--dragging-opacity, 0.3);
  z-index: 0;

  &:focus {
    box-shadow: var(--box-shadow);
  }
`;

const WithoutHandleStyles = css`
  touch-action: manipulation;
  cursor: grab;
`;

export const Wrapper = styled.li<{ fadeIn?: boolean; dragOverlay?: boolean }>`
  display: flex;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.default};
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  overflow: hidden;

  ${({ fadeIn }) => fadeIn && FadeInStyles}
  ${({ dragOverlay }) => dragOverlay && DragOverlayStyles}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div<{
  dragging?: boolean;
  withHandle?: boolean;
  dragOverlay?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  outline: none;
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;

  color: var(--text-color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow:
      0 0px 4px 1px var(--focused-outline-color),
      var(--box-shadow);
  }

  ${({ withHandle }) => !withHandle && WithoutHandleStyles}
  ${({ dragging, dragOverlay }) => dragging && !dragOverlay && DraggingStyles}
  ${({ disabled }) => disabled && DisabledStyles}
  ${({ dragOverlay }) => dragOverlay && ContentDragOverlayStyles}

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 8px;
  }
`;

export const ItemContent = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
`;
