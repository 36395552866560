import { TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Background = styled.img`
  position: fixed;
  left: 30%;
  height: 100%;
  width: 70%;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    left: 0;
    width: 100%;
  }
`;

export const LoginCard = styled.form`
  width: 370px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  position: absolute;
  z-index: 1;
  left: 30%;
  transform: translateX(-50%);

  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-left: 5px solid ${({ theme }) => theme.palette.primary.main};
  gap: 24px;
  box-shadow: 0 30px 20px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 300px;
    padding: 24px;
    left: 50%;
  }
`;

export const Input = styled(TextField)`
  align-self: stretch;
`

export const ForgottenPassword = styled(Typography)`
  cursor: pointer;
`

export const CreateAccountText = styled(Typography)`
  cursor: pointer;
`