import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientDetailStoreType,
  ClientApiResponseType,
  ClientDocumentApiResponseType,
  ClientContentApiResponseType,
  ClientCourseApiResponseType,
  ClientDocumentUpdateAccess,
  ClientContentUpdateAccess,
  ClientCourseUpdateAccess,
} from 'src/types/clients';
import { RootState } from '../store';

const initialState: ClientDetailStoreType = {
  clientDetail: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    dni: '',
    phone: '',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    image: '',
  },
  documents: [],
  contents: [],
  courses: [],
};

export const clientDetailSlice = createSlice({
  name: 'clientDetail',
  initialState,
  reducers: {
    getClientDetailRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientApiResponseType['id']>) => {},
    getClientDetailSuccess: (state: ClientDetailStoreType, action: PayloadAction<ClientApiResponseType>) => {
      state.clientDetail = action.payload;
    },
    getClientDetailError: (state: ClientDetailStoreType) => {
      state.clientDetail = initialState.clientDetail;
    },
    getClientDocumentsRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientApiResponseType['id']>) => {},
    getClientDocumentsSuccess: (
      state: ClientDetailStoreType,
      action: PayloadAction<ClientDocumentApiResponseType[]>,
    ) => {
      state.documents = action.payload;
    },
    getClientDocumentsError: (state: ClientDetailStoreType) => {
      state.documents = initialState.documents;
    },
    getClientContentsRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientApiResponseType['id']>) => {},
    getClientContentsSuccess: (state: ClientDetailStoreType, action: PayloadAction<ClientContentApiResponseType[]>) => {
      state.contents = action.payload;
    },
    getClientContentsError: (state: ClientDetailStoreType) => {
      state.contents = initialState.contents;
    },
    getClientCoursesRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientApiResponseType['id']>) => {},
    getClientCoursesSuccess: (state: ClientDetailStoreType, action: PayloadAction<ClientCourseApiResponseType[]>) => {
      state.courses = action.payload;
    },
    getClientCoursesError: (state: ClientDetailStoreType) => {
      state.courses = initialState.courses;
    },
    patchClientDocumentRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientDocumentUpdateAccess>) => {},
    patchClientDocumentSuccess: (
      state: ClientDetailStoreType,
      action: PayloadAction<ClientDocumentApiResponseType>,
    ) => {
      const documentIdx = state.documents.findIndex(document => document.id === action.payload.id);
      state.documents[documentIdx] = { ...state.documents[documentIdx], ...action.payload };
    },
    patchClientDocumentError: (state: ClientDetailStoreType) => {},
    patchClientContentRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientContentUpdateAccess>) => {},
    patchClientContentSuccess: (state: ClientDetailStoreType, action: PayloadAction<ClientContentApiResponseType>) => {
      const contentIdx = state.contents.findIndex(content => content.id === action.payload.id);
      state.contents[contentIdx] = { ...state.contents[contentIdx], ...action.payload };
    },
    patchClientContentError: (state: ClientDetailStoreType) => {},
    patchClientCourseRequest: (state: ClientDetailStoreType, action: PayloadAction<ClientCourseUpdateAccess>) => {},
    patchClientCourseSuccess: (state: ClientDetailStoreType, action: PayloadAction<ClientCourseApiResponseType>) => {
      const courseIdx = state.courses.findIndex(course => course.id === action.payload.id);
      state.courses[courseIdx] = { ...state.courses[courseIdx], ...action.payload };
    },
    patchClientCourseError: (state: ClientDetailStoreType) => {},
    clearAllResources: (state: ClientDetailStoreType) => {
      state.contents = [];
      state.courses = [];
      state.documents = [];
    },
  },
});

export const actions = clientDetailSlice.actions;

export const clientDetailStore = (state: RootState) => state.clientDetail;

export default clientDetailSlice.reducer;
