import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/clientList/clientList';
import { getClients as getClientsApi } from 'src/services/clients';
import { ClientApiResponseType } from 'src/types/clients';

function* getClients(): Generator<
  CallEffect<AxiosResponse<ClientApiResponseType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ClientApiResponseType[]>
> {
  try {
    const { status, data } = yield call(getClientsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getClientsSuccess(data));
    } else {
      yield put(actions.getClientsError());
    }
  } catch (e) {
    yield put(actions.getClientsError());
  }
}

const clientListSaga: ForkEffect<never>[] = [takeLatest(actions.getClientsRequest, getClients)];

export default clientListSaga;
