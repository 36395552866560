import i18n from 'src/i18n/i18n';

i18n.addResourceBundle(
  'es',
  'eventsSaga',
  {
    createSuccessfully: 'El evento fue creado exitosamente',
    updateSuccessfully: 'El evento fue editado exitosamente',
    deleteSuccessfully: 'El evento fue eliminado exitosamente',
    createError: 'Ocurrió un error al crear el evento. Revise los parámetros y vuelva a intentarlo',
    updateError: 'Ocurrió un error al editar el evento. Revise los parámetros y vuelva a intentarlo',
    deleteError: 'Ocurrió un error al eliminar el evento. Vuelva a intentarlo en unos minutos',
  },
  true,
  false,
);

export default i18n;
