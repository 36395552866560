import { Typography } from '@mui/material';
import styled from 'styled-components';

export const EditorContainer = styled.div<{ error?: boolean }>`
  & span,
  & .ql-editor.ql-blank::before {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
  }

  & .ql-toolbar {
    border-color: ${({ theme, error }) => (error ? theme.palette.error.main : theme.palette.grey[300])};
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-bottom: 0;
    padding: 8px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  & .ql-toolbar .ql-formats {
    margin: 2px 0;
  }

  &&& .quill,
  &&& .ql-container {
    border-color: ${({ theme, error }) => (error ? theme.palette.error.main : theme.palette.grey[300])};
    border-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & .ql-editor {
    min-height: 180px;
  }

  & .ql-container {
    border-top: 0;
  }

  & span.ql-picker {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: 4px;
    height: 30px;
    padding: 3px 0;
  }

  & span.ql-picker:first-child {
    margin-right: 8px;
  }

  &&& span.ql-picker-label:hover {
    color: ${({ theme }) => theme.palette.primary.main};

    & > svg .ql-stroke {
      stroke: ${({ theme }) => theme.palette.primary.main};
    }

    & > svg .ql-fill {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .ql-picker.ql-expanded .ql-picker-label {
    border: none;
  }

  & .ql-picker.ql-expanded span.ql-picker-options {
    border: none;
  }

  & .ql-picker.ql-expanded span.ql-picker-options .ql-picker-item:hover,
  & .ql-picker.ql-expanded span.ql-picker-options .ql-picker-item.ql-selected {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .ql-picker .ql-picker-label.ql-active,
  & .ql-toolbar button:hover,
  & .ql-toolbar button.ql-active {
    color: ${({ theme }) => theme.palette.primary.main};

    & > svg .ql-stroke {
      stroke: ${({ theme }) => theme.palette.primary.main};
    }

    & > svg .ql-fill {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
    color: ${({ theme }) => theme.palette.grey[600]};

    & > svg .ql-stroke {
      stroke: ${({ theme }) => theme.palette.grey[600]};
    }

    & > svg .ql-fill {
      fill: ${({ theme }) => theme.palette.grey[600]};
    }
  }

  & .ql-toolbar .ql-picker.ql-font,
  & .ql-toolbar .ql-picker.ql-header {
    width: 112px;
  }
`;

export const ErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  height: 18px;
  margin-top: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;
