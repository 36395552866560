import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  ContentsListStoreType,
  ContentType,
  CreateContentRequest,
  UpdateContentOrderRequest,
  UpdateContentRequest,
} from 'src/types/contents';
import {
  deleteContentRecursively,
  flatten,
  insertChildToParent,
  recursivelyFilter,
  updateContentRecursively,
} from 'src/utils/contents';

const initialState: ContentsListStoreType = {
  contents: [],
  currentContent: null,
  searchValue: '',
  isDeleteDialogVisible: false,
  isContentModalVisible: false,
};

const sliceName = 'contentsList';

const contentsListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getContentsRequest: (state: ContentsListStoreType) => {},
    getContentsSuccess: (state: ContentsListStoreType, action: PayloadAction<ContentType[]>) => {
      state.contents = action.payload;
    },
    getContentsError: (state: ContentsListStoreType) => {
      state.contents = [];
    },
    createContentRequest: (state: ContentsListStoreType, action: PayloadAction<CreateContentRequest>) => {},
    createContentSuccess: (state: ContentsListStoreType, action: PayloadAction<ContentType>) => {
      const { parentId } = action.payload;
      state.isContentModalVisible = false;
      state.contents = parentId
        ? insertChildToParent(action.payload, state.contents)
        : [...state.contents, action.payload];
    },
    createContentError: (state: ContentsListStoreType) => {},
    updateContentRequest: (state: ContentsListStoreType, action: PayloadAction<UpdateContentRequest>) => {},
    updateContentSuccess: (state: ContentsListStoreType, action: PayloadAction<ContentType>) => {
      state.currentContent = null;
      state.isContentModalVisible = false;
      state.contents = updateContentRecursively(action.payload, state.contents);
    },
    updateContentError: (state: ContentsListStoreType) => {},
    updateContentOrder: (state: ContentsListStoreType, action: PayloadAction<UpdateContentOrderRequest>) => {},
    updateContentOrderSuccess: (state: ContentsListStoreType) => {},
    updateContentOrderError: (state: ContentsListStoreType) => {},
    deleteContentRequest: (state: ContentsListStoreType, action: PayloadAction<number>) => {},
    deleteContentSuccess: (state: ContentsListStoreType, action: PayloadAction<number>) => {
      state.isDeleteDialogVisible = false;
      state.currentContent = null;
      state.contents = deleteContentRecursively(action.payload, state.contents);
    },
    deleteContentError: (state: ContentsListStoreType) => {},
    toggleDeleteDialogVisibility: (state: ContentsListStoreType, action: PayloadAction<boolean>) => {
      state.isDeleteDialogVisible = action.payload;
    },
    toggleContentModalVisibility: (state: ContentsListStoreType, action: PayloadAction<boolean>) => {
      state.isContentModalVisible = action.payload;
    },
    setCurrentContent: (state: ContentsListStoreType, action: PayloadAction<ContentType | null>) => {
      state.currentContent = action.payload;
    },
    onSearch: (state: ContentsListStoreType, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const actions = contentsListSlice.actions;

export const contentsListStore = (state: RootState) => state.contentsList;

export default contentsListSlice.reducer;

export const selectContentsFiltered = createSelector(
  (state: RootState) => state.contentsList,
  content => recursivelyFilter(content.contents, content.searchValue),
);

export const selectContentsFlattened = createSelector(
  (state: RootState) => state.contentsList,
  content => flatten(content.contents),
);
