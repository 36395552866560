import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest, all, AllEffect } from 'redux-saga/effects';
import { actions } from 'src/reducers/courseList/courseList';
import {
  getCourseCategories,
  getCourses as getCoursesApi,
  getMyCourses as getMyCoursesApi,
  enrollInCourse as enrollInCourseApi,
} from 'src/services/courses';
import './i18n';
import { CourseCategoryType, CourseType, MyCourseType } from 'src/types/courses';
import { errorController } from '../utils/errorController';
import i18next from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';

function* getCourses(): Generator<
  | AllEffect<CallEffect<AxiosResponse<CourseType[]>> | CallEffect<AxiosResponse<CourseCategoryType[]>>>
  | PutEffect<{ type: string }>,
  void,
  AxiosResponse<CourseType[]>
> {
  try {
    const [courses, categories]: any = yield all([call(getCoursesApi), call(getCourseCategories)]);
    if (courses.status >= 200 && courses.status < 300) {
      yield put(actions.getCoursesSuccess(courses.data));
    } else {
      yield put(actions.getCoursesError());
    }

    if (categories.status >= 200 && categories.status < 300) {
      yield put(actions.setCategories(categories.data));
    } else {
      yield put(actions.setCategories([]));
    }
  } catch (e) {
    yield put(actions.getCoursesError());
    yield put(actions.setCategories([]));
  }
}

function* getMyCourses(): Generator<
  CallEffect<AxiosResponse<MyCourseType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<MyCourseType[]>
> {
  try {
    const { status, data } = yield call(getMyCoursesApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getMyCoursesSuccess(data));
    } else {
      yield put(actions.getMyCoursesError());
    }
  } catch (e) {
    yield put(actions.getMyCoursesError());
  }
}

function* enrollInCourse({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<MyCourseType>> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse<MyCourseType>
> {
  try {
    const { status, data } = yield call(enrollInCourseApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.enrollInCourseSuccess(payload));
    } else {
      yield put(actions.enrollInCourseError());
      yield errorController(i18next.t('coursesSaga:enrollError'), data);
    }
  } catch (e) {
    yield put(actions.enrollInCourseError());
    yield errorController(i18next.t('coursesSaga:enrollError'), e);
  }
}

const courseListSaga: ForkEffect<never>[] = [
  takeLatest(actions.getCoursesRequest, getCourses),
  takeLatest(actions.getMyCoursesRequest, getMyCourses),
  takeLatest(actions.enrollInCourseRequest, enrollInCourse),
];

export default courseListSaga;
