import { EventDetailsType, ListEventDetailsType } from 'src/types/eventList';
import { get, post, put, remove } from './api';
import { dayJsToApiDatetime } from 'src/utils/date';

export const getEvent = (id: EventDetailsType['id']) => {
  return get<ListEventDetailsType>(`/events/${id}`);
};

export const getEvents = () => {
  return get<ListEventDetailsType[]>('/events');
};

export const createEvent = (event: EventDetailsType) => {
  const formData: FormData = new FormData();
  formData.append('title', event.title);
  formData.append('description', event.description);
  formData.append('link', event.link);
  formData.append('datetime', dayJsToApiDatetime(event.datetime));
  event.image && formData.append('image', event.image);
  return post<EventDetailsType>('/events', formData, { headers: { 'content-type': 'multipart/form-data' } });
};

export const updateEvent = (event: EventDetailsType) => {
  const formData: FormData = new FormData();
  formData.append('title', event.title);
  formData.append('description', event.description);
  formData.append('link', event.link);
  formData.append('datetime', dayJsToApiDatetime(event.datetime));
  event.image && formData.append('image', event.image);
  return put<EventDetailsType>(`/events/${event.id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const deleteEvent = (eventId: EventDetailsType['id']) => {
  return remove<EventDetailsType>(`/events/${eventId}`);
};
