import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/documentList/documentList';
import {
  getDocuments as getDocumentsApi,
  getDocumentCategories as getDocumentCategoriesApi,
  uploadDocument as uploadDocumentApi,
} from 'src/services/documents';
import {
  DocumentByCategoryType,
  DocumentCategoryType,
  DocumentDetailType,
  UploadDocumentRequestType,
} from 'src/types/documents';
import { errorController } from '../utils/errorController';
import i18next from 'i18next';
import './i18n';

function* getDocuments(): Generator<
  CallEffect<AxiosResponse<DocumentByCategoryType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<DocumentByCategoryType[]>
> {
  try {
    const { status, data } = yield call(getDocumentsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getDocumentsSuccess(data));
    } else {
      yield put(actions.getDocumentsError());
    }
  } catch (e) {
    yield put(actions.getDocumentsError());
  }
}

function* getDocumentCategories(): Generator<
  CallEffect<AxiosResponse<DocumentCategoryType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<DocumentCategoryType[]>
> {
  try {
    const { status, data } = yield call(getDocumentCategoriesApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getDocumentCategoriesSuccess(data));
    } else {
      yield put(actions.getDocumentCategoriesError());
    }
  } catch (e) {
    yield put(actions.getDocumentCategoriesError());
  }
}

function* uploadDocument({
  payload,
}: PayloadAction<UploadDocumentRequestType>): Generator<
  | CallEffect<AxiosResponse<DocumentDetailType> | File | void>
  | PutEffect<{ type: string }>
  | ReturnType<typeof errorController>,
  void,
  AxiosResponse<DocumentDetailType>
> {
  try {
    const { status, data } = yield call(uploadDocumentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.uploadDocumentSuccess(data));
    } else {
      yield put(actions.uploadDocumentError());
      yield errorController(i18next.t('documentListSaga:uploadError'), data);
    }
  } catch (e) {
    yield put(actions.uploadDocumentError());
    yield errorController(i18next.t('documentListSaga:uploadError'), e);
  }
}

const documentListSaga: ForkEffect<never>[] = [
  takeLatest(actions.getDocumentsRequest, getDocuments),
  takeLatest(actions.uploadDocumentRequest, uploadDocument),
  takeLatest(actions.getDocumentCategoriesRequest, getDocumentCategories),
];

export default documentListSaga;
