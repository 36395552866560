import { FlattenedItem, TreeItem } from 'src/components/SortableTreeView/types';
import { ContentFormType } from 'src/pages/ContentsList/components/ContentModal/schema';
import { ContentItemMetadata } from 'src/pages/ContentsList/types';
import {
  ContentType,
  FlattenedContentType,
  ComponentType,
  CreateContentRequest,
  UpdateContentRequest,
  CreateComponentRequest,
  UpdateComponentRequest,
  ContentDetailType,
  HeaderComponentType,
} from 'src/types/contents';

export const IconByType: { [k in ComponentType]: string } = {
  file: 'description',
  link: 'link',
  img: 'image',
  txt: 'notes',
  vid: 'slideshow',
};

const filter = (content: ContentType, searchValue: string) =>
  content.title.toLowerCase().includes(searchValue.toLowerCase());

export const recursivelyFilter = (contents: ContentType[], searchValue: string): ContentType[] => {
  return contents.filter(content => {
    const filtered = filter(content, searchValue);
    if (!content.children || content.children.length === 0 || filtered) return filtered;
    else if (content.children.length > 0) return recursivelyFilter(content.children, searchValue).length > 0;
    return false;
  });
};

export const flatten = (
  items: ContentType[] | undefined = [],
  parentId: number | null = null,
  depth = 0,
): FlattenedContentType[] => {
  return items.reduce<FlattenedContentType[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, name: item.title, parentId, order: index },
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
};

export const insertChildToParent = (newContent: ContentType, contents: ContentType[]): ContentType[] => {
  return contents.map(content => {
    if (newContent.parentId === content.id) {
      return { ...content, children: [...(content.children || []), newContent] };
    }
    if (content.children && content.children.length > 0) {
      return { ...content, children: insertChildToParent(newContent, content.children) };
    }

    return content;
  });
};

export const updateContentRecursively = (newContent: ContentType, contents: ContentType[]): ContentType[] => {
  return contents.map(content => {
    if (newContent.id === content.id) {
      return newContent;
    }
    if (content.children && content.children.length > 0) {
      return { ...content, children: updateContentRecursively(newContent, content.children) };
    }

    return content;
  });
};

export const deleteContentRecursively = (removedId: number, contents: ContentType[]): ContentType[] => {
  return contents
    .map(content => {
      if (removedId === content.id) {
        return null;
      }
      if (content.children && content.children.length > 0) {
        return { ...content, children: deleteContentRecursively(removedId, content.children) };
      }

      return content;
    })
    .filter(v => v !== null) as ContentType[];
};

export const mapFormToApiCreateRequest = (form: ContentFormType): CreateContentRequest => {
  return {
    title: form.title,
    parentId: form.parentId || null,
    mainCategory: form.mainCategory || false,
  };
};

export const mapFormToApiUpdateRequest = (id: number, form: ContentFormType): UpdateContentRequest => {
  return {
    id,
    title: form.title,
    mainCategory: form.mainCategory || false,
  };
};

export const mapTreeViewToContent = (item: FlattenedItem<ContentItemMetadata>): ContentType => {
  return {
    id: +item.id,
    children: item.children.map(mapTreeViewToContent),
    mainCategory: item.metadata?.mainCategory || false,
    title: item.metadata?.title || '',
    order: item.metadata?.order || 0,
    parentId: item.parentId ? +item.parentId : null,
  };
};

export const mapContentToTreeView = (content: ContentType): TreeItem<ContentItemMetadata> => {
  const { id, children, ...metadata } = content;
  return {
    id,
    children: (children || []).map(mapContentToTreeView),
    collapsed: true,
    metadata,
  };
};

export const transformHeaderComponentToFormData = (component: CreateComponentRequest | UpdateComponentRequest) => {
  const formData: FormData = new FormData();
  formData.append('title', component.title);
  formData.append('type', component.type);
  formData.append('columnSize', component.columnSize.toString());
  if (component.link) formData.append('link', component.link);
  if (component.textContent) formData.append('textContent', component.textContent);
  if (component.file != null) formData.append('file', component.file);
  return formData;
};

export const getComponentById = (content: ContentDetailType | null, componentId: number) => {
  if (!content) return null;
  return content.headers
    .reduce<HeaderComponentType[]>((acc, curr) => [...acc, ...curr.components], [])
    .find(c => c.id === componentId);
};
