import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/newsList/newsList';
import {
  getNews as getNewsApi,
  createNews as createNewsApi,
  updateNews as updateNewsApi,
  deleteNews as deleteNewsApi,
} from 'src/services/news';
import './i18n';
import i18next from 'i18next';
import { NewsDetailsType, ListNewsDetailsType } from 'src/types/newsList';
import { errorController } from '../utils/errorController';
import { globalActions } from 'src/reducers/global/global';

function* getNews(): Generator<
  CallEffect<AxiosResponse<ListNewsDetailsType[]>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ListNewsDetailsType[]>
> {
  try {
    const { status, data } = yield call(getNewsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getNewsSuccess(data));
    } else {
      yield put(actions.getNewsError());
    }
  } catch (e) {
    yield put(actions.getNewsError());
  }
}

function* createNews({
  payload,
}: PayloadAction<NewsDetailsType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(createNewsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.createNewsSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('newsSaga:createSuccessfully')));
      yield put(actions.setOpen(false));
    } else {
      yield put(actions.createNewsError());
      yield errorController(i18next.t('newsSaga:createError'), data);
    }
  } catch (e) {
    yield put(actions.createNewsError());
    yield errorController(i18next.t('newsSaga:createError'), e);
  }
}

function* updateNews({
  payload,
}: PayloadAction<NewsDetailsType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateNewsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateNewsSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('newsSaga:updateSuccessfully')));
      yield put(actions.setOpen(false));
    } else {
      yield put(actions.updateNewsError());
      yield errorController(i18next.t('newsSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateNewsError());
    yield errorController(i18next.t('newsSaga:updateError'), e);
  }
}

function* deleteNews({
  payload,
}: PayloadAction<NewsDetailsType['id']>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(deleteNewsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.deleteNewsSuccess(payload));
      yield put(globalActions.showSuccessSnackbar(i18next.t('newsSaga:deleteSuccessfully')));
    } else {
      yield put(actions.deleteNewsError());
      yield errorController(i18next.t('newsSaga:deleteError'), data);
    }
  } catch (e) {
    yield put(actions.deleteNewsError());
    yield errorController(i18next.t('newsSaga:deleteError'), e);
  }
}

const newsSaga: ForkEffect<never>[] = [
  takeLatest(actions.getNewsRequest, getNews),
  takeLatest(actions.createNewsRequest, createNews),
  takeLatest(actions.updateNewsRequest, updateNews),
  takeLatest(actions.deleteNewsRequest, deleteNews),
];

export default newsSaga;
