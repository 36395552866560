import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/settings/settings';
import { getSettings as getSettingsApi, updateSettings as updateSettingsApi } from 'src/services/settings';
import './i18n';
import i18next from 'i18next';
import { errorController } from '../utils/errorController';
import { SettingsApiResponseType, SettingsType } from 'src/types/settings';
import { globalActions } from 'src/reducers/global/global';

function* getSettings(): Generator<
  CallEffect<AxiosResponse<SettingsApiResponseType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<SettingsApiResponseType>
> {
  try {
    const { status, data } = yield call(getSettingsApi);
    if (status >= 200 && status < 300) {
      yield put(actions.getSettingsSuccess(data));
    } else {
      yield put(actions.getSettingsError());
    }
  } catch (e) {
    yield put(actions.getSettingsError());
  }
}

function* updateSettings({
  payload,
}: PayloadAction<SettingsType>): Generator<
  CallEffect<AxiosResponse> | PutEffect<{ type: string }> | ReturnType<typeof errorController>,
  void,
  AxiosResponse
> {
  try {
    const { status, data } = yield call(updateSettingsApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.updateSettingsSuccess(data));
      yield put(globalActions.showSuccessSnackbar(i18next.t('settingsSaga:updateSuccessfully')));
    } else {
      yield put(actions.updateSettingsError());
      yield errorController(i18next.t('settingsSaga:updateError'), data);
    }
  } catch (e) {
    yield put(actions.updateSettingsError());
    yield errorController(i18next.t('settingsSaga:updateError'), e);
  }
}

const settingsSaga: ForkEffect<never>[] = [
  takeLatest(actions.getSettingsRequest, getSettings),
  takeLatest(actions.updateSettingsRequest, updateSettings),
];

export default settingsSaga;
