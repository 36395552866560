import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, ForkEffect, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/contentDetail/contentDetail';
import { getContent as getContentApi } from 'src/services/contents';
import { ContentDetailType } from 'src/types/contents';

function* getContent({
  payload,
}: PayloadAction<number>): Generator<
  CallEffect<AxiosResponse<ContentDetailType>> | PutEffect<{ type: string }>,
  void,
  AxiosResponse<ContentDetailType>
> {
  try {
    const { status, data } = yield call(getContentApi, payload);
    if (status >= 200 && status < 300) {
      yield put(actions.getContentSuccess(data));
    } else {
      yield put(actions.getContentError());
    }
  } catch (e) {
    yield put(actions.getContentError());
  }
}

const contentDetailSaga: ForkEffect<never>[] = [takeLatest(actions.getContentRequest, getContent)];

export default contentDetailSaga;
