import styled, { css } from 'styled-components';
import MuiDrawer from '@mui/material/Drawer';
import { styled as muiStyled, Theme, CSSObject } from '@mui/material/styles';
import { DRAWER_WIDTH } from 'src/utils/constants';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const DEPTH_SPACING = 8;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const MobileDrawer = muiStyled(MuiDrawer)(() => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
  },
}));

export const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled.div`
  height: var(--header-height, 100);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    justify-content: flex-end;
  }
`;

export const ListItemOption = styled(ListItemButton)<{ depth?: number; active?: boolean }>`
  padding-left: ${({ depth }) => 20 + (depth || 0) * DEPTH_SPACING}px;
  position: relative;
  & span {
    color: ${({ theme }) => theme.typography.body2.color};
  }

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        height: 20px;
        width: 4px;
        border-radius: 4px;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.palette.primary.main};
      }

      & span {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    `}
`;

export const ItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

export const Text = styled(ListItemText)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  & span {
    text-overflow: ellipsis;
    white-space: pre-line;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const ListDivider = styled(Divider)`
  width: calc(100% - 40px);
  margin: 16px auto;
`;

export const CollapseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  & svg > path {
    fill: ${({ theme }) => theme.typography.body2.color};
  }
`;

export const NavList = styled(List)<{ component: string }>`
  padding: 0;
  margin: 0;
  max-height: calc(100vh - var(--header-height, 100) - 100px);
  overflow-y: auto;
`;
