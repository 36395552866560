import { Indeterminate, LoaderBar, LoaderContainer } from './styles';
import { GlobalLoaderProps } from './types';

const GlobalLoader = ({ loading }: GlobalLoaderProps) => {
  if (!loading) return null;

  return (
    <LoaderContainer>
      <LoaderBar>
        <Indeterminate />
      </LoaderBar>
    </LoaderContainer>
  );
};

export default GlobalLoader;
